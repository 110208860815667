import { map, pipe, prop, reject, sort } from 'ramda'
import React, { FC, useEffect, useState } from 'react'
import { humanReadableDuration } from '../../utils'
import { HydratedPort } from '../../interfaces/ports'
import { HydratedPortCall } from '../../interfaces/vessels'
import PortCallsTable from '../PortCallsTable'

const useData = ({ port }: { port: HydratedPort }) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    setData(
      pipe<Array<HydratedPortCall>, Array<HydratedPortCall>, Array<number>, Array<number>>(
        reject(prop('isOmitted')),
        map(prop('duration')),
        sort((a, b) => a - b)
      )(port.portCalls)
    )
  }, [port])
  return data
}

const Table: FC<{ port: HydratedPort }> = ({ port }) => {
  const data = useData({ port })
  if (!data) return null
  return (
    <div className="pa4">
      <h1>Duration Percentiles</h1>
      <div className="overflow-auto">
        <table className="f6 w-100 mw8 center" cellSpacing="0">
          <thead>
            <tr className="stripe-dark">
              <th className="fw6 tl pa3 bg-white tc">5th</th>
              <th className="fw6 tl pa3 bg-white tc">20th</th>
              <th className="fw6 tl pa3 bg-white tc">50th</th>
              <th className="fw6 tl pa3 bg-white tc">75th</th>
              <th className="fw6 tl pa3 bg-white tc">90th</th>
            </tr>
          </thead>
          <tbody className="lh-copy">
            <tr className="stripe-dark">
              {[0.05, 0.2, 0.5, 0.75, 0.9].map((percentile, idx) => (
                <td key={idx} className="pa3">
                  {humanReadableDuration(data[Math.ceil(percentile * data.length) - 1])}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      <PortCallsTable portCalls={port.portCalls} viewFrom="port" />
    </div>
  )
}

export default Table
