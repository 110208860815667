import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import React, { FC, useEffect, useState } from 'react'
import { humanReadableDuration } from '../../utils'
import { HydratedVessel } from '../../interfaces/vessels'
import { range } from 'ramda'

const useData = (vessel: HydratedVessel) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    setData(
      range(0, 31)
        .reverse()
        .map(day => ({ day, delay: vessel.avgPortCallDelays[day] }))
    )
  }, [vessel])
  return data
}

const Graph: FC<{ vessel: HydratedVessel }> = ({ vessel }) => {
  const data = useData(vessel)
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis width={200} tickFormatter={humanReadableDuration} />
        <Tooltip
          labelFormatter={d => `Calculated ${d} day${d === 1 ? '' : 's'} from arrival`}
          formatter={humanReadableDuration}
        />
        <Line type="monotone" dataKey="delay" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Graph
