import { HydratedPortCall, HydratedVessel } from '../../interfaces/vessels'
import { map, path, pipe, prop, reject, sort } from 'ramda'
import React, { FC, useEffect, useState } from 'react'
import { humanReadableDuration } from '../../utils'

type PercentileTableProps = { days: number; vessel: HydratedVessel }

const useData = ({ days, vessel }: PercentileTableProps) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    setData(
      pipe<Array<HydratedPortCall>, Array<HydratedPortCall>, Array<number>, Array<number>>(
        reject(prop('isOmitted')),
        map(path(['delaysPerDay', days])),
        sort((a, b) => a - b)
      )(vessel.portCalls)
    )
  }, [days, vessel])
  return data
}

const PercentileTable: FC<PercentileTableProps> = ({ days, vessel }) => {
  const data = useData({ days, vessel })
  if (!data) return null
  return (
    <>
      <h3>Calculated {days} days from arrival</h3>
      <table className="f6 w-100 mw8 center" cellSpacing="0">
        <thead>
          <tr className="stripe-dark">
            <th className="fw6 tl pa3 bg-white tc">5th</th>
            <th className="fw6 tl pa3 bg-white tc">50th</th>
            <th className="fw6 tl pa3 bg-white tc">80th</th>
          </tr>
        </thead>
        <tbody className="lh-copy">
          <tr className="stripe-dark">
            {[0.05, 0.5, 0.8].map((percentile, idx) => (
              <td key={idx} className="pa3">
                {humanReadableDuration(data[Math.ceil(percentile * data.length) - 1])}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default PercentileTable
