import { connect, ConnectedProps } from 'react-redux'
import React, { FC, useEffect } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { init } from './store/actions/thunks'
import Navbar from './components/Navbar'
import Port from './components/routes/Port'
import PortCall from './components/routes/PortCall'
import Ports from './components/routes/Ports'
import Vessel from './components/routes/Vessel'
import Vessels from './components/routes/Vessels'

const mapDispatchToProps = {
  init,
}

const connector = connect(null, mapDispatchToProps)

type AppProps = ConnectedProps<typeof connector>

const App: FC<AppProps> = ({ init }) => {
  useEffect(() => {
    init()
  })
  return (
    <Router>
      <Navbar />
      <div className="tc pt6">
        <Switch>
          <Route exact path="/">
            <Redirect to="/vessels" />
          </Route>
          <Route exact path="/vessels" component={Vessels} />
          <Route path="/vessels/:imo" component={Vessel} />
          <Route exact path="/ports" component={Ports} />
          <Route path="/ports/:id" component={Port} />
          <Route path="/portcalls/:id" component={PortCall} />
        </Switch>
      </div>
    </Router>
  )
}

export default connector(App)
