import { HydratedPortMap } from '../interfaces/ports'
import { HydratedVessel } from '../interfaces/vessels'

export default (vessels: Array<HydratedVessel>): HydratedPortMap => {
  const ports: HydratedPortMap = {}
  vessels.forEach(({ portCalls }) =>
    portCalls.forEach(portCall => {
      const {
        port: { id },
        port,
      } = portCall
      if (!ports[id]) {
        ports[id] = { ...port, portCalls: [] }
      }
      portCall.id = `${id}-${ports[id].portCalls.length}`
      ports[id].portCalls.push(portCall)
    })
  )
  for (const id in ports) {
    ports[id].portCalls = ports[id].portCalls.sort((a, b) => a.duration - b.duration)
  }

  return ports
}
