import React, { FC } from 'react'
import { PortList } from '../../interfaces/ports'

export const SORTINGS = {
  ALPHA: 'alphabetically',
  ARRIVALS: 'arrivals',
  LEAST_PORTCALLS: 'portcalls',
}

const buttonClasses = isPressed =>
  `mh2 f6 no-underline br-pill ba ph3 pv2 mb2 dib black ${
    isPressed ? 'bg-moon-gray' : 'bg-white pointer grow'
  }`

const Controls: FC<{
  sortData: (sortingParam: string, sortingFn: (pl: PortList) => PortList) => void
  sorting: string
}> = ({ sortData, sorting }) => {
  const onClickAlpha = () => {
    sortData(SORTINGS.ALPHA, portList => portList.sort((a, b) => (a.name < b.name ? -1 : 1)))
  }
  const onClickArrivals = () => {
    sortData(SORTINGS.ARRIVALS, portList =>
      portList.sort((a, b) => b.arrivalsAmount - a.arrivalsAmount)
    )
  }
  const onClickPortCalls = () => {
    sortData(SORTINGS.LEAST_PORTCALLS, portList =>
      portList.sort((a, b) => a.portCallsAmount - b.portCallsAmount)
    )
  }
  return (
    <div>
      <h1 className="f6 fw6 tracked">Sorting</h1>
      <button onClick={onClickAlpha} className={buttonClasses(sorting === SORTINGS.ALPHA)}>
        Alphabetically
      </button>
      <button onClick={onClickArrivals} className={buttonClasses(sorting === SORTINGS.ARRIVALS)}>
        Most Arrivals
      </button>
      <button
        onClick={onClickPortCalls}
        className={buttonClasses(sorting === SORTINGS.LEAST_PORTCALLS)}
      >
        Least Portcalls
      </button>
    </div>
  )
}

export default Controls
