import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9303807, name: 'ABIDJAN EXPRESS' },
  portCalls: [
    {
      arrival: '2019-01-12T03:42:00+00:00',
      departure: '2019-01-12T20:48:00+00:00',
      createdDate: '2018-10-23T00:00:59.510081+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-23T00:00:59.510081+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-23T00:00:59.510081+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T05:43:29.144602+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T05:43:29.423729+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T06:45:06.871492+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T06:45:07.166739+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T09:38:35.660013+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T09:38:35.946302+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T10:41:34.000306+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T10:41:34.268981+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T13:51:56.074579+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T13:51:56.376246+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T16:13:49.092701+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T16:13:49.378954+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T08:42:49.27658+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T08:42:49.607256+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T09:47:36.414383+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T09:47:36.699845+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T11:23:41.548815+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T11:23:41.837338+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-26T15:41:55.416777+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-26T15:41:55.715707+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-27T11:03:23.675536+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-27T11:03:23.980369+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-31T10:56:44.702362+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-31T10:56:45.051542+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T14:30:24.059987+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T14:30:24.367688+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T17:23:22.745773+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T08:39:14.449506+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T08:39:14.74537+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T09:44:34.137781+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T09:44:34.431514+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T08:44:15.944777+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T08:44:16.237681+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T09:47:46.932208+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T09:47:47.23472+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:43:49.678259+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:43:49.970111+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T03:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-12T04:03:13.325237+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T20:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T21:57:52.239365+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-14T05:18:00+00:00',
      departure: '2019-01-14T18:00:00+00:00',
      createdDate: '2018-10-23T00:01:00.043793+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-23T00:01:00.043793+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-23T00:01:00.043793+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T05:43:30.052244+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T05:43:30.329097+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T06:45:09.516341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T06:45:09.81455+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-27T11:03:24.594046+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-27T11:03:24.896762+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-31T10:56:45.782161+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-31T10:56:46.115278+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T14:30:25.027577+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T14:30:25.335468+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T08:39:15.400536+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T08:39:15.701961+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T09:44:35.106599+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T09:44:35.395021+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T09:47:47.85513+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T09:47:48.161811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:43:50.602104+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T05:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T06:23:50.846333+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T10:31:44.935674+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T18:51:12.328455+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-18T21:48:00+00:00',
      departure: '2019-01-19T22:54:00+00:00',
      createdDate: '2018-11-05T00:01:51.61368+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MACAS', name: 'Casablanca' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-05T00:01:51.61368+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-05T00:01:51.61368+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T05:43:30.945092+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T05:43:31.241028+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T06:45:12.32236+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T06:45:12.616701+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T20:40:02.658035+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T20:40:02.929189+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-27T11:03:25.489135+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-27T11:03:25.785535+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-31T10:56:46.911123+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-31T10:56:47.280623+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T14:30:26.01687+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T14:30:26.323641+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:37:14.136285+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:37:14.462983+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T08:39:16.311098+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T08:39:16.619038+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T09:44:36.107092+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T09:44:36.395297+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T09:47:48.810238+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T09:47:49.114355+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T13:46:45.827421+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T13:46:46.135677+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-12T04:03:14.277357+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T04:03:14.585916+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T12:39:05.09318+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T12:39:05.366442+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T00:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T08:50:38.854663+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T21:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T21:33:57.533796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T22:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T22:20:58.159544+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-22T02:10:00+00:00',
      departure: '2019-01-22T23:50:00+00:00',
      createdDate: '2018-11-05T00:01:53.878941+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-05T00:01:53.878941+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-05T00:01:53.878941+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T05:43:31.866739+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T05:43:32.146823+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T06:45:15.030364+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T06:45:15.322358+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T20:40:03.512011+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T20:40:03.782307+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-27T11:03:26.422606+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-27T11:03:26.727128+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-31T10:56:48.019223+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-31T10:56:48.433282+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T14:30:26.970133+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T14:30:27.276658+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:37:15.140334+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:37:15.464684+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T08:39:17.248185+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T08:39:17.539694+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T09:44:37.193952+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T09:44:37.497753+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T09:47:49.723695+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T09:47:50.026671+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T13:46:46.882827+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T13:46:47.193182+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-12T04:03:15.323585+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T04:03:15.626162+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T12:39:05.980503+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T12:39:06.253279+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T18:51:13.260711+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T18:51:13.569607+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T08:50:38.862767+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T08:50:38.862767+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T21:33:57.541535+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T21:33:57.541535+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T10:41:38.489996+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T10:41:38.489996+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T02:10:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T09:56:04.935985+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T09:56:04.935985+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T23:50:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T10:42:29.202243+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-29T19:45:00+00:00',
      departure: '2019-01-31T02:54:00+00:00',
      createdDate: '2018-11-05T00:01:56.273998+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'GHTEM', name: 'Tema' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-05T00:01:56.273998+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-05T00:01:56.273998+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T14:30:27.908237+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T14:30:28.216261+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T03:36:20.794636+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T03:36:21.122084+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T12:35:54.180369+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T12:35:54.477018+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T08:39:18.163135+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T08:39:18.459876+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T09:44:38.210914+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T09:44:38.514038+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T09:47:50.683093+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T09:47:50.989319+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T06:12:58.944207+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T06:12:59.255847+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T11:34:50.67637+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T11:34:50.987217+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T06:23:51.720745+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T06:23:52.010373+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T12:39:06.873736+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T12:39:07.158349+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T18:51:14.217307+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T18:51:14.525015+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T08:50:38.870457+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T08:50:38.870457+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T09:29:26.811259+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T09:29:26.811259+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T21:33:57.548723+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T21:33:57.548723+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T08:11:32.352909+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T08:11:32.352909+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T10:41:38.503447+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T10:41:38.503447+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:29:18.988836+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:29:18.988836+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T10:42:29.209609+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T10:42:29.209609+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T19:05:21.192973+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T19:05:21.192973+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T06:27:54.976687+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T22:20:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T06:27:54.976687+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T10:08:48.34043+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T20:00:42.312333+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T19:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T20:00:42.312333+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T19:45:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T08:25:26.804914+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T17:58:01.492796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T02:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T03:25:10.990945+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T02:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T08:16:30.242804+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-03T13:12:00+00:00',
      departure: '2019-02-05T11:54:00+00:00',
      createdDate: '2018-11-05T00:01:58.28919+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'CIABJ', name: 'Abidjan' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-05T00:01:58.28919+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-05T00:01:58.28919+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:33.419702+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:33.706697+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:29:20.761121+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:29:20.761121+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T10:08:50.184645+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T10:08:50.184645+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-02T12:32:34.453758+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-02T12:32:34.453758+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T05:42:48.497755+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T13:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T14:02:28.546546+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T15:07:57.532071+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T15:50:43.906049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T09:53:47.206655+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T11:15:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T13:16:13.737752+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T11:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T12:14:54.20465+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-15T21:18:00+00:00',
      departure: '2019-02-16T08:18:00+00:00',
      createdDate: '2018-11-23T00:38:54.104811+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-23T00:38:54.104811+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-23T00:38:54.104811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T20:40:04.378986+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T20:40:04.649158+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:37:16.104481+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:37:16.430094+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:34.365033+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:34.655661+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T08:35:53.97497+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T08:35:53.97497+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T10:08:50.196848+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T10:08:50.196848+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T07:44:19.431244+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T07:44:19.431244+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T09:53:47.221025+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T09:53:47.221025+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T13:56:42.319068+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T13:56:42.319068+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T10:07:33.954955+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T10:07:33.954955+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T21:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T21:00:29.550462+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T21:19:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T21:00:29.550462+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T08:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T07:40:49.010225+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-19T16:36:00+00:00',
      departure: '2019-02-20T09:30:00+00:00',
      createdDate: '2018-11-23T00:38:54.820987+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-23T00:38:54.820987+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-23T00:38:54.820987+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:35.288192+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:35.574864+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T08:35:55.664191+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T08:35:55.664191+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:09:57.955199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:09:57.955199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T07:44:19.444045+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T07:44:19.444045+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T13:56:42.3256+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T13:56:42.3256+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.939559+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.939559+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T10:07:33.963023+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T10:07:33.963023+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T08:22:49.18226+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T08:22:49.18226+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T08:55:40.715789+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T08:55:40.715789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T16:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-19T17:23:26.998207+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T19:05:41.664443+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T10:17:23.70678+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-21T13:12:00+00:00',
      departure: '2019-02-22T03:00:00+00:00',
      createdDate: '2018-11-23T00:38:55.771899+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-23T00:38:55.771899+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-23T00:38:55.771899+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:36.169141+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:36.462954+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.372419+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.372419+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.94775+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.94775+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T10:07:35.971463+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T10:07:35.971463+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T08:22:52.223954+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T08:22:52.223954+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T13:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T13:39:08.591254+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T03:25:52.292973+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-27T02:20:00+00:00',
      departure: '2019-02-28T01:30:00+00:00',
      createdDate: '2018-12-10T00:52:10.122029+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MACAS', name: 'Casablanca' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-10T00:52:10.122029+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-10T00:52:10.122029+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T20:40:05.232671+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T20:40:05.505511+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:37:17.107442+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:37:17.456378+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:37.074675+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:37.364136+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.396166+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.396166+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.956788+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.956788+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T10:07:35.98502+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T10:07:35.98502+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T08:22:52.267932+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T08:22:52.267932+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T10:51:45.122257+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T10:51:45.122257+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T13:39:08.620366+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-25T09:36:27.966462+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T01:37:08.792873+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T02:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T01:37:08.792873+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T10:05:53.978572+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T01:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T01:09:51.182725+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T09:59:41.018329+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T02:20:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T09:59:41.018329+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-28T18:46:00+00:00',
      departure: '2019-03-01T14:00:00+00:00',
      createdDate: '2018-12-10T00:52:11.059222+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-10T00:52:11.059222+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-10T00:52:11.059222+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T20:40:06.075353+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T20:40:06.345109+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:37:18.097364+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:37:18.401545+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:37.951707+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:38.236881+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.403149+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.403149+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.970537+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T09:34:14.970537+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T10:07:35.992515+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T10:07:35.992515+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T08:22:52.29192+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T08:22:52.29192+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T10:51:45.174334+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T10:51:45.174334+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-25T09:36:30.72341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-25T09:36:30.72341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T01:37:08.803291+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T01:37:08.803291+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T10:05:53.987364+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T10:05:53.987364+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T08:43:04.361694+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T14:17:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T07:24:23.90948+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T18:15:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T07:24:23.90948+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T10:33:28.126636+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T18:46:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T10:33:28.126636+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-11T06:00:00+00:00',
      departure: '2019-03-12T14:54:00+00:00',
      createdDate: '2018-12-10T00:52:11.752626+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'GHTEM', name: 'Tema' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-10T00:52:11.752626+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-10T00:52:11.752626+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:38.818041+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:39.103197+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T12:20:33.031883+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T12:20:33.031883+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.409649+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T13:56:45.409649+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T09:21:00.799661+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T09:21:00.799661+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-03T07:40:33.920642+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-03T07:40:33.920642+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T11:43:58.780199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T11:43:58.780199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T09:38:22.36743+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T09:38:22.36743+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T12:08:58.512995+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T17:54:11.684133+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T17:54:11.684133+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-10T03:10:56.848358+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-10T03:10:56.848358+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T06:26:55.4491+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T06:26:55.4491+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T07:30:55.64306+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T14:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T15:17:13.031658+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-13T15:12:00+00:00',
      departure: '2019-03-15T09:06:00+00:00',
      createdDate: '2018-12-10T00:52:12.681241+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'CIABJ', name: 'Abidjan' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-10T00:52:12.681241+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-10T00:52:12.681241+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:39.682369+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:39.967967+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T06:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-03T07:40:34.461383+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T11:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-03T07:40:34.461383+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T11:44:01.237899+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T11:44:01.237899+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T09:38:26.885276+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T09:38:26.885276+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T10:54:15.079505+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T10:54:15.079505+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T17:54:14.099986+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T17:54:14.099986+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-10T03:10:59.201161+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-10T03:10:59.201161+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T06:26:57.86085+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T06:26:57.86085+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T14:44:52.164591+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T14:44:52.164591+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T15:17:15.425445+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T15:17:15.425445+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T16:23:40.80516+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T16:23:40.80516+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T14:06:09.84686+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T14:06:09.84686+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T15:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T15:42:59.585214+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T15:42:59.585214+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T18:25:55.04249+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-14T07:41:16.049419+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-14T12:40:02.925151+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T02:43:14.991398+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T09:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T09:39:18.860712+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-22T07:42:00+00:00',
      departure: '2019-03-22T20:06:00+00:00',
      createdDate: '2018-12-30T00:09:54.654904+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-30T00:09:54.654904+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-30T00:09:54.654904+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:37:19.034508+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:37:19.342776+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:40.546054+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-21T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:40.832074+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-24T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T09:38:29.560593+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T09:38:29.560593+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T10:54:18.082336+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T10:54:18.082336+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T12:09:01.996197+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T12:09:01.996197+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-21T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T17:54:14.162052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T17:54:14.162052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-15T02:43:15.007406+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T02:43:15.007406+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T10:52:11.242336+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T10:52:11.242336+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T15:11:39.208745+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T15:11:39.208745+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-21T10:09:47.65907+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-21T10:09:47.65907+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T07:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T07:06:24.588613+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T07:06:24.588613+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T20:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T19:41:13.453763+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-26T03:54:00+00:00',
      departure: '2019-03-27T04:48:00+00:00',
      createdDate: '2018-12-30T00:09:55.426152+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-30T00:09:55.426152+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-30T00:09:55.426152+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:41.430752+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:41.716197+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T12:42:34.793333+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T12:42:34.793333+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T09:38:32.189641+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T09:38:32.189641+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T10:54:18.131274+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T10:54:18.131274+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T12:09:03.074194+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T12:09:03.074194+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T17:54:14.17313+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T17:54:14.17313+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T02:43:15.020662+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-15T02:43:15.020662+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T10:52:11.251738+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T12:28:35.549769+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T15:11:39.225317+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T15:11:39.225317+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T07:06:24.599449+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T07:06:24.599449+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T09:23:15.321801+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T09:23:15.321801+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T03:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T04:08:53.588039+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T11:56:26.278946+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T04:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T05:21:45.1944+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-28T07:00:00+00:00',
      departure: '2019-03-29T14:06:00+00:00',
      createdDate: '2018-12-30T00:09:56.636825+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-20T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-30T00:09:56.636825+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-21T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-30T00:09:56.636825+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-13T04:08:42.290493+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T04:08:42.583611+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T09:38:37.184165+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T09:38:37.184165+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T10:54:20.487569+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T10:54:20.487569+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T12:09:08.61403+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T12:09:08.61403+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T17:54:16.543003+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T17:54:16.543003+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T02:43:17.499619+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-15T02:43:17.499619+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T10:52:13.753786+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T10:52:13.753786+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T09:23:18.048417+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T09:23:18.048417+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T09:41:39.270692+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T09:41:39.270692+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T07:46:42.178756+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T14:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T14:47:27.012444+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-02T22:24:00+00:00',
      departure: '2019-04-03T14:00:00+00:00',
      createdDate: '2019-01-15T00:11:46.980856+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MACAS', name: 'Casablanca' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T00:11:46.980856+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T00:11:46.980856+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T09:38:41.1788+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T09:38:41.1788+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T10:54:21.051841+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T10:54:21.051841+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T12:09:09.73061+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T12:09:09.73061+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T17:54:16.590677+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T17:54:16.590677+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-15T02:43:17.575473+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T02:43:17.575473+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T10:52:13.795042+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T10:52:13.795042+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T09:23:18.082182+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T09:23:18.082182+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T09:41:39.378034+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T09:41:39.378034+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T14:47:27.412695+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T14:47:27.412695+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T08:38:45.487404+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T08:38:45.487404+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T22:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-02T22:59:56.203392+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-03T14:31:34.622105+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-08T06:25:00+00:00',
      departure: '2019-04-09T04:10:00+00:00',
      createdDate: '2019-01-15T00:11:47.88278+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T00:11:47.88278+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T00:11:47.88278+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T09:38:43.338038+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T09:38:43.338038+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T10:54:21.608987+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T10:54:21.608987+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T12:09:10.826714+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T12:09:10.826714+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T17:54:16.600199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T17:54:16.600199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-15T02:43:17.599397+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T02:43:17.599397+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T10:52:13.829101+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T10:52:13.829101+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T09:23:18.102057+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T09:23:18.102057+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T09:41:39.41844+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T09:41:39.41844+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T14:47:27.819167+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T14:47:27.819167+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T08:38:45.495112+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T08:38:45.495112+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-02T22:59:56.209616+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-02T22:59:56.209616+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T11:30:55.469882+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T11:30:55.469882+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T12:20:45.855895+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T12:20:45.855895+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T06:25:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T06:48:14.763464+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T06:48:14.763464+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T04:10:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T15:33:49.425106+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-17T18:00:00+00:00',
      departure: '2019-04-19T02:35:00+00:00',
      createdDate: '2019-01-15T00:11:48.586991+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'GHTEM', name: 'Tema' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T00:11:48.586991+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T00:11:48.586991+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T00:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T11:30:55.475925+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T11:30:55.475925+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T12:35:11.162917+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T12:35:11.162917+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-08T15:04:40.624776+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-08T15:04:40.624776+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T06:48:14.80084+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T06:48:14.80084+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T17:09:45.000218+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T17:09:45.000218+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T06:48:50.115059+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T06:48:50.115059+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T12:15:39.81688+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T12:15:39.81688+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T06:53:36.582991+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T16:47:53.115997+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T16:47:53.115997+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T18:21:12.029157+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T18:21:12.029157+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T17:26:20.000028+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T17:26:20.000028+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T08:37:57.890646+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-17T10:46:22.346047+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T10:46:22.346047+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-17T15:00:33.943609+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T15:00:33.943609+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T16:37:16.76362+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T17:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T18:09:42.894278+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T07:17:43.311315+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T13:15:55.285922+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T16:29:34.508574+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T02:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T07:13:36.371703+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-20T21:12:00+00:00',
      departure: '2019-04-22T03:06:00+00:00',
      createdDate: '2019-01-15T00:11:49.523826+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'CIABJ', name: 'Abidjan' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T00:11:49.523826+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T00:11:49.523826+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T11:30:55.523025+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T11:30:55.523025+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T12:35:11.17102+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T12:35:11.17102+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-07T08:21:36.086032+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-07T08:21:36.086032+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-08T15:04:40.632699+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T06:48:17.85586+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T17:09:45.101304+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T17:09:45.101304+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T06:48:50.19721+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T06:48:50.19721+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T12:15:39.977042+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T12:15:39.977042+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T13:15:58.805145+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T13:15:58.805145+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-19T12:59:40.712648+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T12:59:40.712648+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T21:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T21:57:03.617423+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-22T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T23:59:26.957843+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-22T03:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-22T03:34:16.131886+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-04T04:42:00+00:00',
      departure: '2019-05-04T22:06:00+00:00',
      createdDate: '2019-02-04T00:04:39.314508+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:04:39.314508+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-25T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:04:39.314508+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-26T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T15:33:49.431521+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T15:33:49.431521+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T17:09:45.119343+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T17:09:45.119343+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T06:48:50.214574+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T06:48:50.214574+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-26T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T08:55:36.663499+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T08:55:36.663499+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T12:15:39.998038+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T12:15:39.998038+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T09:02:07.604384+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T09:02:07.604384+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T16:47:53.216727+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T16:47:53.216727+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T11:49:28.731403+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-17T11:49:28.731403+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-17T15:00:37.887246+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T15:00:37.887246+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T13:15:58.855173+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T13:15:58.855173+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-19T12:59:40.732278+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T12:59:40.732278+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T21:57:03.638107+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T21:57:03.638107+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T23:59:26.991135+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T23:59:26.991135+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-23T08:28:03.475924+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-23T08:28:03.475924+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-05T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.613834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-04T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.613834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-03T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.023842+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-04T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.023842+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-04T04:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-04T05:00:13.934731+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-05T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-04T05:00:13.934731+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-04T22:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-04T22:30:03.963523+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-07T06:00:00+00:00',
      departure: '2019-05-07T18:00:00+00:00',
      createdDate: '2019-02-22T00:25:32.455274+00:00',
      isOmitted: true,
      service: 'West Africa Express 1',
      port: { id: 'MACAS', name: 'Casablanca' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-07T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.455274+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.455274+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-04T14:16:08.389005+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-04T15:34:39.305923+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-04T03:36:19.099668+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-08T07:00:00+00:00',
      departure: '2019-05-09T01:00:00+00:00',
      createdDate: '2019-02-22T00:25:32.460062+00:00',
      isOmitted: true,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-08T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.460062+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.460062+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-04T14:16:10.790152+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-04T15:34:41.721735+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-04T03:36:19.104322+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-09T07:48:00+00:00',
      departure: '2019-05-10T08:36:00+00:00',
      createdDate: '2019-02-04T00:04:39.336527+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:04:39.336527+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:04:39.336527+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T03:36:19.087823+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T03:36:19.087823+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T09:02:07.685937+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T09:02:07.685937+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T16:47:53.252391+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T16:47:53.252391+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-17T11:49:28.82528+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T11:49:28.82528+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-17T15:00:38.344428+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T15:00:38.344428+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.627985+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-08T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.627985+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T12:15:20.452479+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-08T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T12:15:20.452479+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.022752+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.022752+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-08T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T08:58:17.600579+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T08:58:17.600579+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.03372+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-08T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.03372+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T09:39:28.178855+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T09:39:28.178855+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T07:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-09T08:35:57.808879+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T08:27:13.817564+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T08:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T08:57:59.567966+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-12T22:12:00+00:00',
      departure: '2019-05-13T12:30:00+00:00',
      createdDate: '2019-02-04T00:04:39.352575+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:04:39.352575+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:04:39.352575+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T03:36:19.094341+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-08T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T03:36:19.094341+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-10T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.664542+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-11T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.664542+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.028634+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-08T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.028634+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-11T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T08:58:18.022462+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-10T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T08:58:18.022462+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-10T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.153134+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.153134+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T08:37:30.404836+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-10T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T08:37:30.404836+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T09:09:01.492966+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-11T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T09:09:01.492966+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-11T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-09T08:35:57.875702+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-11T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-09T08:35:57.875702+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-09T09:38:51.167795+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-11T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-09T09:38:51.167795+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T22:13:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-12T22:33:30.495246+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T22:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-12T22:33:30.495246+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T10:16:52.275586+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T13:20:57.014712+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-18T02:06:00+00:00',
      departure: '2019-05-19T01:42:00+00:00',
      createdDate: '2019-04-04T04:07:11.66587+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MACAS', name: 'Casablanca' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-14T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T04:07:11.66587+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T04:07:11.66587+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.699529+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.699529+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-14T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.034413+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.034413+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T08:58:18.439261+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T08:58:18.439261+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.179387+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.179387+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T09:09:01.961598+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T09:09:01.961598+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-09T09:38:51.2043+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-09T09:38:51.2043+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T08:27:13.89884+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-10T08:27:13.89884+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-12T22:33:30.528711+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-12T22:33:30.528711+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T10:16:52.302815+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T10:16:52.302815+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T13:20:57.034748+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T13:20:57.034748+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-14T07:52:54.918536+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T07:52:54.918536+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T02:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-18T01:41:26.344807+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-18T01:41:26.344807+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T01:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-19T01:00:37.02229+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-18T12:00:00+00:00',
      departure: '2019-05-19T14:00:00+00:00',
      createdDate: '2019-02-22T00:25:32.464818+00:00',
      isOmitted: true,
      service: 'West Africa Express 1',
      port: { id: 'GHTEM', name: 'Tema' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.464818+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.464818+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-04T03:36:19.109321+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-20T20:36:00+00:00',
      departure: '2019-05-21T17:30:00+00:00',
      createdDate: '2019-04-04T04:07:11.673328+00:00',
      isOmitted: false,
      service: 'West Africa Express 1',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T04:07:11.673328+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T04:07:11.673328+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.708846+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T11:43:24.708846+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.04012+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T12:47:25.04012+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T08:58:21.932606+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T08:58:21.932606+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.187266+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T10:31:42.187266+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T09:09:02.379516+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T09:09:02.379516+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-09T09:38:51.215913+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-09T09:38:51.215913+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-10T08:27:13.920942+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T08:27:13.920942+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-12T22:33:30.572775+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-19T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-12T22:33:30.572775+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T10:16:52.312438+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T10:16:52.312438+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-19T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T13:20:57.042558+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T13:20:57.042558+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-14T07:52:54.933372+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T07:52:54.933372+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-18T01:41:26.351573+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-18T01:41:26.351573+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-19T01:00:37.038548+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-19T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-19T01:00:37.038548+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-21T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-20T08:42:37.696172+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-21T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-20T08:42:37.696172+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T20:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-20T20:06:48.268893+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-21T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-21T17:02:16.414001+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-22T06:00:00+00:00',
      departure: '2019-05-23T11:00:00+00:00',
      createdDate: '2019-02-22T00:25:32.47049+00:00',
      isOmitted: true,
      service: 'West Africa Express 1',
      port: { id: 'CIABJ', name: 'Abidjan' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-22T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.47049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-23T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T00:25:32.47049+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-04T03:36:22.016895+00:00',
        },
      ],
    },
  ],
}
export default vessel
