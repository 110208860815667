import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9335173, name: 'COSCO BOSTON' },
  portCalls: [
    {
      arrival: '2018-12-28T23:12:00+00:00',
      departure: '2019-01-05T07:03:00+00:00',
      createdDate: '2019-01-16T09:04:49.048105+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USLAX', name: 'Los Angeles' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2018-12-28T23:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.048105+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T07:03:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.048105+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-06T11:42:00+00:00',
      departure: '2019-01-06T23:54:00+00:00',
      createdDate: '2019-01-16T09:04:49.056163+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USOK3', name: 'Oakland' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T11:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.056163+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T23:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.056163+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-18T11:30:00+00:00',
      departure: '2019-01-19T07:42:00+00:00',
      createdDate: '2019-01-16T09:04:49.062855+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.062855+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.062855+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.59162+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.59162+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.584351+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T07:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T07:52:01.160224+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-19T23:00:00+00:00',
      departure: '2019-01-20T11:06:00+00:00',
      createdDate: '2019-01-16T09:04:49.10316+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPNGO', name: 'Nagoya, Aichi' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.10316+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.10316+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-17T05:04:06.117869+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.597781+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.597781+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-01-17T07:10:52.597781+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.596829+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.596829+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.774367+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.774367+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T11:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T11:21:08.499583+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-21T00:05:00+00:00',
      departure: '2019-01-21T15:49:00+00:00',
      createdDate: '2019-01-16T09:04:49.072086+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.072086+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.072086+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.059235+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T22:05:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.059235+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.604736+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.604736+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.000882+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.000882+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.610179+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.610179+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.780324+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.780324+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T00:05:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.850655+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.850655+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T15:49:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T00:08:43.86015+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-21T23:00:00+00:00',
      departure: '2019-01-22T08:00:00+00:00',
      createdDate: '2019-01-16T09:04:49.11432+00:00',
      isOmitted: true,
      service: 'Pacific South Loop 1',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.11432+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.11432+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-17T05:04:06.132564+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-23T06:00:00+00:00',
      departure: '2019-01-23T10:40:00+00:00',
      createdDate: '2019-01-16T09:04:49.127322+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPSDJ', name: 'Sendai, Miyagi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.127322+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.127322+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.071585+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.071585+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.613741+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.613741+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.008076+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.008076+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.622728+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.622728+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.785678+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.785678+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.857402+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.857402+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.226103+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T10:40:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.226103+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-03T13:48:00+00:00',
      departure: '2019-02-06T03:24:00+00:00',
      createdDate: '2019-01-16T09:04:49.132592+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USLAX', name: 'Los Angeles' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.132592+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.132592+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.083312+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.083312+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.620845+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.620845+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.016898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.016898+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.638534+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.638534+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.7928+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.7928+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.863592+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.863592+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T00:08:43.867228+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.236052+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.236052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.24184+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.24184+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T13:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T14:12:58.000311+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:16:25.049543+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T03:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-06T03:46:07.744075+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-07T10:12:00+00:00',
      departure: '2019-02-08T10:42:00+00:00',
      createdDate: '2019-01-16T09:04:49.137796+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USOK3', name: 'Oakland' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.137796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.137796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.097411+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T05:35:52.097411+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.637793+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:10:52.637793+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.023608+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T07:42:52.023608+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.653231+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T12:02:16.653231+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.798413+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T23:32:55.798413+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.869313+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:22:09.869313+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T00:08:43.874028+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T00:08:43.874028+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.244325+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.244325+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.249465+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.249465+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:16:25.056898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:16:25.056898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T10:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T12:43:10.175228+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T10:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T13:34:33.538142+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-17T23:00:00+00:00',
      departure: '2019-02-18T08:00:00+00:00',
      createdDate: '2019-01-16T09:04:49.153184+00:00',
      isOmitted: true,
      service: 'Pacific South Loop 1',
      port: { id: 'JPNGO', name: 'Nagoya, Aichi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.153184+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.153184+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-17T14:04:19.157611+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-21T00:12:00+00:00',
      departure: '2019-02-21T10:12:00+00:00',
      createdDate: '2019-01-16T09:04:49.147617+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-16T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.147617+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.147617+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.251317+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.251317+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.255796+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.255796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:16:25.064107+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:16:25.064107+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T12:43:10.192888+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T12:43:10.192888+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T13:34:33.545939+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T13:34:33.545939+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.833663+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.833663+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T06:06:45.373179+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T06:06:45.373179+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T00:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T00:30:07.182122+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T00:30:07.182122+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T10:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T11:03:12.615565+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-21T16:30:00+00:00',
      departure: '2019-02-22T10:30:00+00:00',
      createdDate: '2019-01-16T09:04:49.158132+00:00',
      isOmitted: true,
      service: 'Pacific South Loop 1',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.158132+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.158132+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.258289+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.258289+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.273029+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T13:30:05.273029+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:16:25.071321+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:16:25.071321+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T12:43:10.206049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T12:43:10.206049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T13:34:33.553664+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T13:34:33.553664+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.938835+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.938835+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.846459+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.846459+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-20T01:41:29.961392+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-21T23:42:00+00:00',
      departure: '2019-02-22T12:48:00+00:00',
      createdDate: '2019-01-16T09:04:49.164539+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPNGO', name: 'Nagoya, Aichi' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.164539+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.164539+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T00:50:15.509267+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T00:50:15.509267+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.945158+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.945158+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.214729+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.214729+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.969693+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.969693+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T23:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T00:37:03.153194+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T00:37:03.153194+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T12:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:36:27.067883+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-23T03:06:00+00:00',
      departure: '2019-02-23T17:06:00+00:00',
      createdDate: '2019-02-20T01:41:29.977872+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.977872+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.977872+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T03:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T03:34:44.391445+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T17:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T18:50:19.323097+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-24T23:06:00+00:00',
      departure: '2019-02-25T08:12:00+00:00',
      createdDate: '2019-01-16T09:04:49.169333+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.169333+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.169333+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T00:50:15.525685+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T00:50:15.525685+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.95084+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.95084+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.229132+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.229132+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.860053+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.860053+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.983694+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.983694+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T00:30:07.197148+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T00:30:07.197148+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T18:50:19.869835+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T18:50:19.869835+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T23:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T23:22:43.481754+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T23:22:43.481754+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T08:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-25T08:42:09.332127+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-26T03:30:00+00:00',
      departure: '2019-02-26T11:10:00+00:00',
      createdDate: '2019-01-16T09:04:49.174371+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPSDJ', name: 'Sendai, Miyagi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.174371+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.174371+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T00:50:15.536608+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T00:50:15.536608+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.956876+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.956876+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.249951+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.249951+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.87017+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.87017+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.991501+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.991501+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T06:06:45.383744+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T06:06:45.383744+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T01:12:21.884073+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T11:10:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T01:12:21.884073+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-09T13:42:00+00:00',
      departure: '2019-03-12T01:12:00+00:00',
      createdDate: '2019-01-16T09:04:49.178872+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USLAX', name: 'Los Angeles' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.178872+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.178872+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.962604+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.962604+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.260765+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.260765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.884034+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.884034+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T03:34:44.399728+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T03:34:44.399728+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T18:50:20.410248+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T18:50:20.410248+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T23:22:43.489337+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T23:22:43.489337+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T13:59:21.688224+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T13:59:21.688224+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-05T12:40:07.667713+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-05T12:40:07.667713+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.927931+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T13:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.927931+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.359347+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T01:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T01:41:58.260162+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-13T20:36:00+00:00',
      departure: '2019-03-15T00:42:00+00:00',
      createdDate: '2019-01-16T09:04:49.184047+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USOK3', name: 'Oakland' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.184047+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.184047+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.967853+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:22:32.967853+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.271307+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T01:55:02.271307+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.891307+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T02:26:58.891307+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.999069+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T01:41:29.999069+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T00:30:07.210769+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T00:30:07.210769+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T03:34:44.406946+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T03:34:44.406946+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T23:22:43.498015+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T23:22:43.498015+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T13:59:21.723735+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T13:59:21.723735+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-05T12:40:08.217971+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.940886+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.940886+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.381287+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.381287+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T20:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.016285+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-14T12:18:03.88145+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T00:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T02:53:23.967719+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-27T09:22:00+00:00',
      departure: '2019-03-27T20:22:00+00:00',
      createdDate: '2019-01-16T09:04:49.188622+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.188622+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-24T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.188622+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-24T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.94901+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.94901+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.401115+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.401115+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T03:24:50.623115+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T03:24:50.623115+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.029353+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.029353+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:40.020306+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:40.020306+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T01:09:53.662055+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T01:09:53.662055+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T00:16:04.022287+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T00:16:04.022287+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T09:53:05.641342+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T09:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T09:53:05.641342+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T20:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T21:17:02.052465+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T20:22:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T01:15:07.472999+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T09:22:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T01:15:07.472999+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-27T23:00:00+00:00',
      departure: '2019-03-28T08:00:00+00:00',
      createdDate: '2019-01-16T09:04:49.212068+00:00',
      isOmitted: true,
      service: 'Pacific South Loop 1',
      port: { id: 'JPNGO', name: 'Nagoya, Aichi' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.212068+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.212068+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.957618+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.957618+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.409739+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.409739+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T03:24:51.027858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T03:24:51.027858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.038619+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.038619+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:40.420627+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:40.420627+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-20T06:53:31.069502+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-28T22:30:00+00:00',
      departure: '2019-03-29T11:18:00+00:00',
      createdDate: '2019-01-16T09:04:49.223478+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.223478+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.223478+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.965862+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T09:57:36.965862+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.421264+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T14:27:38.421264+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T03:24:51.42612+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T03:24:51.42612+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.047533+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.047533+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:40.838223+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:40.838223+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T01:09:54.049892+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T01:09:54.049892+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T21:17:02.075251+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T21:17:02.075251+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.04913+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T11:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.402835+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-30T01:54:00+00:00',
      departure: '2019-03-30T16:48:00+00:00',
      createdDate: '2019-01-24T00:43:35.265134+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPNGO', name: 'Nagoya, Aichi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.265134+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.265134+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T03:24:51.834834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T03:24:51.834834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.055537+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.055537+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:41.24201+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:41.24201+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T01:09:54.438385+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T01:09:54.438385+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T21:17:02.088686+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T21:17:02.088686+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T01:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-30T02:29:41.387827+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-30T02:29:41.387827+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T16:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-30T17:13:18.13576+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-01T15:31:00+00:00',
      departure: '2019-04-02T02:21:00+00:00',
      createdDate: '2019-01-24T00:43:35.270128+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.270128+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.270128+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T03:24:52.236818+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T03:24:52.236818+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.063349+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.063349+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:41.661663+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:41.661663+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T01:09:54.847868+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T01:09:54.847868+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.055717+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.055717+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.409406+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.409406+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.824366+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.824366+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T17:04:20.266425+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T16:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T17:04:20.266425+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T15:31:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-02T00:30:07.940227+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-02T00:30:07.940227+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T02:21:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-02T04:48:52.432762+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-02T20:50:00+00:00',
      departure: '2019-04-03T04:05:00+00:00',
      createdDate: '2019-01-16T09:04:49.232021+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPSDJ', name: 'Sendai, Miyagi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.232021+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T09:04:49.232021+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T03:24:52.651735+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T03:24:52.651735+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.071666+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-13T22:57:44.071666+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:42.067414+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:42.067414+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T01:09:55.281829+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T01:09:55.281829+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.061827+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.061827+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.415618+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.415618+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.833049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.833049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T04:05:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T06:34:07.116255+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T20:50:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T06:34:07.116255+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-14T13:54:00+00:00',
      departure: '2019-04-16T10:18:00+00:00',
      createdDate: '2019-01-24T00:43:35.274536+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USLAX', name: 'Los Angeles' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.274536+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.274536+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:42.470417+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:42.470417+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T01:09:55.698777+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T01:09:55.698777+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.067508+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.067508+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.421654+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.421654+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.838994+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.838994+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-03T13:06:11.75031+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-03T13:06:11.75031+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T06:34:07.122567+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T06:34:07.122567+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T12:43:30.335374+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T12:43:30.335374+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T13:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.105811+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.105811+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T10:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.583197+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-17T10:48:00+00:00',
      departure: '2019-04-19T13:12:00+00:00',
      createdDate: '2019-01-24T00:43:35.279134+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'USOK3', name: 'Oakland' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.279134+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T00:43:35.279134+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T04:48:42.880945+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T04:48:42.880945+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T01:09:56.11455+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T01:09:56.11455+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.073585+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T23:07:08.073585+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.428249+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T11:46:13.428249+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.844839+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-31T11:40:25.844839+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-03T13:06:11.75677+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-03T13:06:11.75677+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T06:34:07.128589+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T06:34:07.128589+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T12:43:30.764985+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T12:43:30.764985+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.114264+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.114264+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.591204+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.591204+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T10:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T13:33:24.670482+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T21:58:29.754448+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T13:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T15:45:44.914774+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-30T23:18:00+00:00',
      departure: '2019-05-01T05:42:00+00:00',
      createdDate: '2019-02-08T00:13:50.493052+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.493052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.493052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T12:43:31.192931+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T12:43:31.192931+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.122693+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.122693+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.598364+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.598364+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T21:58:29.76516+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-30T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T21:58:29.76516+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-30T23:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T23:49:38.140451+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T05:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-01T06:10:43.874034+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-01T22:42:00+00:00',
      departure: '2019-05-02T05:06:00+00:00',
      createdDate: '2019-02-08T00:13:50.497969+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPNGO', name: 'Nagoya, Aichi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.497969+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.497969+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T12:43:31.61007+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T12:43:31.61007+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.132725+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-30T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.132725+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.607142+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.607142+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T21:58:29.774219+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T21:58:29.774219+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T23:49:38.147269+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T23:49:38.147269+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T22:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-01T22:58:33.205327+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T05:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T05:39:40.61861+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-02T22:48:00+00:00',
      departure: '2019-05-03T06:42:00+00:00',
      createdDate: '2019-02-08T00:13:50.502106+00:00',
      isOmitted: false,
      service: 'Pacific South Loop 1',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.502106+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.502106+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T12:43:32.019994+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-30T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T12:43:32.019994+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.142697+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T14:02:55.142697+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.614684+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-30T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T10:41:02.614684+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T21:58:29.78271+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T21:58:29.78271+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T23:49:38.153872+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-03T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T23:49:38.153872+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T22:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T23:13:20.02764+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T06:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T07:20:23.714367+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-05T03:00:00+00:00',
      departure: '2019-05-05T09:00:00+00:00',
      createdDate: '2019-02-08T00:13:50.506032+00:00',
      isOmitted: true,
      service: 'Pacific South Loop 1',
      port: { id: 'JPSDJ', name: 'Sendai, Miyagi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-05T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.506032+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-05T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T00:13:50.506032+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-28T13:12:34.12654+00:00',
        },
      ],
    },
  ],
}
export default vessel