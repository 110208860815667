import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9387425, name: 'EMPIRE' },
  portCalls: [
    {
      arrival: '2019-01-01T22:54:00+00:00',
      departure: '2019-01-03T00:45:00+00:00',
      createdDate: '2018-12-01T00:15:09.401221+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-01T00:15:09.401221+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-01T00:15:09.401221+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T08:56:31.528975+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T22:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-01T23:04:53.792985+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T00:45:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T09:10:04.017513+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-02T16:00:00+00:00',
      departure: '2019-01-02T22:00:00+00:00',
      createdDate: '2018-12-01T00:15:10.425442+00:00',
      isOmitted: true,
      service: 'Baltic Sea Express',
      port: { id: 'DEBRV', name: 'Bremerhaven' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-01T00:15:10.425442+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-02T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-01T00:15:10.425442+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-12T12:44:55.360346+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-17T07:26:18.483186+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-28T08:56:32.153965+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-05T10:18:00+00:00',
      departure: '2019-01-06T01:00:00+00:00',
      createdDate: '2018-12-06T00:06:57.527554+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T00:06:57.527554+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T00:06:57.527554+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T08:56:32.811046+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T08:56:33.160005+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T10:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T14:22:04.089353+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T14:22:04.417966+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-07T08:00:00+00:00',
      departure: '2019-01-07T18:00:00+00:00',
      createdDate: '2018-12-06T00:06:58.493522+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T00:06:58.493522+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T00:06:58.493522+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-08T15:48:00+00:00',
      departure: '2019-01-09T20:05:00+00:00',
      createdDate: '2018-12-06T00:06:59.393729+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T00:06:59.393729+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T00:06:59.393729+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T15:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-09T06:17:02.363677+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T20:05:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T06:48:50.585599+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-11T00:24:00+00:00',
      departure: '2019-01-11T09:00:00+00:00',
      createdDate: '2018-12-06T00:07:00.335855+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T00:07:00.335855+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T00:07:00.335855+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T00:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T10:13:08.970545+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T10:13:09.266723+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-14T15:36:00+00:00',
      departure: '2019-01-15T05:18:00+00:00',
      createdDate: '2018-12-15T00:14:24.229916+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-15T00:14:24.229916+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-15T00:14:24.229916+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T08:56:33.817277+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T11:16:01.043493+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T15:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T16:03:55.547408+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T05:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T06:22:47.313265+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-18T11:18:00+00:00',
      departure: '2019-01-19T08:35:00+00:00',
      createdDate: '2018-12-20T00:12:56.931383+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:12:56.931383+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:12:56.931383+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T11:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-19T09:30:44.074721+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T08:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T09:30:44.074721+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-21T07:12:00+00:00',
      departure: '2019-01-21T19:30:00+00:00',
      createdDate: '2018-12-20T00:12:59.634073+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:12:59.634073+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:12:59.634073+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T07:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T08:02:46.429746+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T08:02:46.429746+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-22T18:20:00+00:00',
      departure: '2019-01-23T21:10:00+00:00',
      createdDate: '2018-12-20T00:13:02.302796+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:13:02.302796+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:13:02.302796+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T18:20:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T05:39:22.130467+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T21:10:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T06:09:30.198902+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-25T05:10:00+00:00',
      departure: '2019-01-26T00:30:00+00:00',
      createdDate: '2018-12-20T00:13:05.124023+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:13:05.124023+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:13:05.124023+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T05:10:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T16:54:55.244556+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T16:54:55.244556+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-28T20:42:00+00:00',
      departure: '2019-01-29T15:24:00+00:00',
      createdDate: '2018-12-29T00:46:37.880385+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-29T00:46:37.880385+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-29T00:46:37.880385+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T11:16:01.678241+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T20:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T21:15:14.745639+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T15:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T15:45:23.582947+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-02T07:24:00+00:00',
      departure: '2019-02-02T19:00:00+00:00',
      createdDate: '2019-01-04T00:50:35.167184+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-04T00:50:35.167184+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-04T00:50:35.167184+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T07:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T09:49:54.472884+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T09:49:54.472884+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-04T04:42:00+00:00',
      departure: '2019-02-04T15:36:00+00:00',
      createdDate: '2019-01-04T00:50:36.133905+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-04T00:50:36.133905+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-04T00:50:36.133905+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T04:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T07:50:24.10805+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T15:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T07:50:24.10805+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-05T16:54:00+00:00',
      departure: '2019-02-07T21:40:00+00:00',
      createdDate: '2019-01-04T00:50:37.061494+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-04T00:50:37.061494+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-04T00:50:37.061494+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T16:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-06T06:31:53.990376+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T21:40:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T06:18:36.332153+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-09T05:12:00+00:00',
      departure: '2019-02-10T01:06:00+00:00',
      createdDate: '2019-01-04T00:50:37.992179+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-04T00:50:37.992179+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-04T00:50:37.992179+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T05:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T07:21:12.303663+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T01:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T07:21:12.303663+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-11T22:54:00+00:00',
      departure: '2019-02-12T10:06:00+00:00',
      createdDate: '2019-01-12T00:09:10.68162+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T00:09:10.68162+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-12T00:09:10.68162+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:37:36.892363+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T22:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T23:10:19.236905+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T10:53:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T12:33:49.153452+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T10:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T17:59:41.204942+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-15T10:00:00+00:00',
      departure: '2019-02-15T20:00:00+00:00',
      createdDate: '2019-01-16T00:10:04.281548+00:00',
      isOmitted: true,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:10:04.281548+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:10:04.281548+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-30T11:56:29.837439+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-18T08:00:00+00:00',
      departure: '2019-02-18T18:00:00+00:00',
      createdDate: '2019-01-16T00:10:05.325951+00:00',
      isOmitted: true,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:10:05.325951+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:10:05.325951+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-30T11:56:29.844424+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-20T05:00:00+00:00',
      departure: '2019-02-20T21:00:00+00:00',
      createdDate: '2019-01-16T00:10:06.32149+00:00',
      isOmitted: true,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:10:06.32149+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:10:06.32149+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-30T11:56:29.850827+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-22T06:00:00+00:00',
      departure: '2019-02-22T20:00:00+00:00',
      createdDate: '2019-01-16T00:10:07.44927+00:00',
      isOmitted: true,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:10:07.44927+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:10:07.44927+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-30T11:56:29.856486+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-24T05:12:00+00:00',
      departure: '2019-02-25T22:42:00+00:00',
      createdDate: '2019-01-26T00:10:59.343749+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-26T00:10:59.343749+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-26T00:10:59.343749+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T12:12:59.840339+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T12:12:59.840339+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T05:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T05:45:36.148989+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T22:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-25T23:15:00.038651+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-28T08:24:00+00:00',
      departure: '2019-03-01T03:20:00+00:00',
      createdDate: '2019-01-30T00:00:44.853334+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.853334+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.853334+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T12:12:59.848098+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T08:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T10:16:05.102291+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T03:20:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T10:16:05.102291+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-04T05:42:00+00:00',
      departure: '2019-03-04T16:00:00+00:00',
      createdDate: '2019-01-30T00:00:44.858321+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.858321+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.858321+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T12:12:59.85568+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T05:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-05T08:21:37.282792+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-05T08:21:37.282792+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-05T17:30:00+00:00',
      departure: '2019-03-07T00:10:00+00:00',
      createdDate: '2019-01-30T00:00:44.875291+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.875291+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.875291+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T05:54:12.781311+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T00:10:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T06:50:12.713975+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-08T06:30:00+00:00',
      departure: '2019-03-09T02:18:00+00:00',
      createdDate: '2019-01-30T00:00:44.886307+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.886307+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T00:00:44.886307+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T07:44:57.327072+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T02:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T07:44:57.327072+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-11T06:00:00+00:00',
      departure: '2019-03-12T04:30:00+00:00',
      createdDate: '2019-01-30T11:56:29.863098+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.863098+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.863098+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T12:12:59.862024+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T07:48:23.602431+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T07:48:23.602431+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-13T09:25:54.534505+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-14T21:30:00+00:00',
      departure: '2019-03-15T06:45:00+00:00',
      createdDate: '2019-01-30T11:56:29.868075+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.868075+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.868075+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T12:12:59.86858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T12:12:59.86858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T07:48:23.609591+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T07:48:23.609591+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T06:45:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T07:20:12.628285+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-15T07:20:12.628285+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-18T04:48:00+00:00',
      departure: '2019-03-18T13:00:00+00:00',
      createdDate: '2019-01-30T11:56:29.872553+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.872553+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.872553+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T04:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T14:57:13.530132+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T14:57:13.530132+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-19T16:20:00+00:00',
      departure: '2019-03-20T20:35:00+00:00',
      createdDate: '2019-01-30T11:56:29.876901+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-20T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.876901+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-20T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.876901+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-19T16:20:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T05:52:33.695831+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-20T20:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-21T06:00:05.974116+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-22T03:00:00+00:00',
      departure: '2019-03-22T19:20:00+00:00',
      createdDate: '2019-01-30T11:56:29.881172+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.881172+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T11:56:29.881172+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T07:30:50.877738+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T19:20:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T07:30:50.877738+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-26T01:36:00+00:00',
      departure: '2019-03-26T22:12:00+00:00',
      createdDate: '2019-02-09T00:14:22.879928+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-09T00:14:22.879928+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-09T00:14:22.879928+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T07:48:23.616279+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T16:18:22.30665+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T14:17:46.20493+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T14:17:46.20493+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T15:02:09.964834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T01:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T02:16:17.825742+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T22:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T22:44:07.17562+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-29T09:00:00+00:00',
      departure: '2019-03-29T23:54:00+00:00',
      createdDate: '2019-02-13T00:29:18.13308+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.13308+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.13308+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.441136+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.441136+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T10:45:09.039356+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T10:45:09.039356+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T23:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T06:06:43.107285+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-30T19:30:00+00:00',
      departure: '2019-03-31T09:30:00+00:00',
      createdDate: '2019-03-20T14:20:19.448151+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LVRIX', name: 'Riga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.448151+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.448151+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T07:11:29.617959+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T07:11:29.617959+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-01T04:42:00+00:00',
      departure: '2019-04-01T14:00:00+00:00',
      createdDate: '2019-02-13T00:29:18.139028+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.139028+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.139028+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.454049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.454049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T04:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T09:57:50.426999+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-02T06:26:10.583015+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-02T22:54:00+00:00',
      departure: '2019-04-04T00:48:00+00:00',
      createdDate: '2019-02-13T00:29:18.143536+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.143536+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.143536+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T10:47:30.45364+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T20:59:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T10:47:30.45364+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.460041+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T22:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-03T05:42:57.103126+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T00:50:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T05:23:27.018003+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T00:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-04T06:56:36.848306+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-05T02:30:00+00:00',
      departure: '2019-04-05T13:54:00+00:00',
      createdDate: '2019-02-13T00:29:18.148155+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.148155+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T00:29:18.148155+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T14:52:47.097562+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T14:52:47.097562+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-09T08:38:40.306652+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T13:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T08:38:40.306652+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-08T21:06:00+00:00',
      departure: '2019-04-09T19:06:00+00:00',
      createdDate: '2019-03-09T00:26:48.910014+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-10T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-09T00:26:48.910014+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-09T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-09T00:26:48.910014+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.466298+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T14:20:19.466298+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-08T06:41:46.503286+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-08T13:07:15.840444+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-08T13:07:15.840444+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T21:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-08T21:49:20.180515+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T07:33:05.682159+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T08:05:49.116263+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T14:41:06.475475+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T19:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T19:33:47.788323+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-11T20:00:00+00:00',
      departure: '2019-04-12T16:00:00+00:00',
      createdDate: '2019-03-12T00:09:36.774434+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.774434+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.774434+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-12T06:07:08.016726+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-13T00:13:56.340386+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T15:17:05.941782+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T15:17:05.941782+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T06:17:46.074782+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T18:50:09.70863+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-15T03:30:00+00:00',
      departure: '2019-04-15T13:48:00+00:00',
      createdDate: '2019-03-12T00:09:36.787775+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.787775+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.787775+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-12T06:07:08.021371+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-13T00:13:57.114482+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T15:17:05.950798+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T15:17:05.950798+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T16:18:22.31731+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T16:18:22.31731+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-08T06:41:46.516435+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-08T06:41:46.516435+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T13:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T06:52:28.834168+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T06:52:28.834168+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-16T12:45:00+00:00',
      departure: '2019-04-17T15:35:00+00:00',
      createdDate: '2019-03-12T00:09:36.792228+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.792228+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T20:59:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.792228+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-12T06:07:08.024869+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-13T00:13:57.89082+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T15:17:05.961758+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T12:45:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T13:26:59.332472+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T15:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T05:55:30.604553+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-18T21:54:00+00:00',
      departure: '2019-04-19T13:15:00+00:00',
      createdDate: '2019-03-12T00:09:36.796508+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.796508+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T00:09:36.796508+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-12T06:07:08.028947+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-13T00:13:58.288241+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T15:17:05.969845+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T15:17:05.969845+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T21:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-19T13:44:12.926975+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T13:15:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T13:44:12.926975+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-23T13:48:00+00:00',
      departure: '2019-04-24T04:12:00+00:00',
      createdDate: '2019-03-23T00:06:38.550505+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-23T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-23T00:06:38.550505+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-23T00:06:38.550505+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-23T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-08T06:41:46.525061+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-22T05:53:50.198293+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-22T08:32:53.347191+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-22T08:32:53.347191+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-23T13:47:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-23T14:12:59.542714+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T13:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T05:37:37.734697+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T04:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T05:37:37.734697+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-26T21:00:00+00:00',
      departure: '2019-04-27T12:00:00+00:00',
      createdDate: '2019-03-27T00:19:02.964512+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T00:19:02.964512+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-26T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T00:19:02.964512+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-27T05:03:16.923715+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-28T00:15:06.684432+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-22T08:32:53.355853+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T07:03:07.982509+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T07:03:07.982509+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-29T06:12:00+00:00',
      departure: '2019-04-29T16:00:00+00:00',
      createdDate: '2019-03-27T00:19:03.368693+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T00:19:03.368693+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T00:19:03.368693+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-27T05:03:16.928179+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-28T00:15:06.689352+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T06:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T06:51:06.585044+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T06:51:06.585044+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-30T10:45:00+00:00',
      departure: '2019-05-01T16:35:00+00:00',
      createdDate: '2019-03-27T00:19:03.762615+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T00:19:03.762615+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T00:19:03.762615+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-27T05:03:16.931515+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-28T00:15:06.693633+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-30T10:45:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T11:56:48.725181+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T16:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T05:12:12.733818+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-02T22:00:00+00:00',
      departure: '2019-05-03T06:35:00+00:00',
      createdDate: '2019-03-27T00:19:04.154632+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-03T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-27T00:19:04.154632+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-27T00:19:04.154632+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-27T05:03:16.93443+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-28T00:15:06.697932+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-03T09:26:53.613481+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T06:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T09:26:53.613481+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-06T20:36:00+00:00',
      departure: '2019-05-07T13:00:00+00:00',
      createdDate: '2019-04-06T00:13:26.974351+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-06T00:13:26.974351+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-06T00:13:26.974351+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-22T05:53:50.206046+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T14:24:18.435191+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T12:00:32.626363+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T20:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T21:16:23.613562+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T13:35:45.858349+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-10T14:48:00+00:00',
      departure: '2019-05-11T09:05:00+00:00',
      createdDate: '2019-04-10T00:20:51.246261+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-10T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T00:20:51.246261+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T00:20:51.246261+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-10T14:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-10T15:51:00.841646+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-11T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T15:51:00.841646+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-11T09:05:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-11T10:25:11.076527+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-13T05:48:00+00:00',
      departure: '2019-05-13T15:54:00+00:00',
      createdDate: '2019-04-10T00:20:51.643447+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-13T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T00:20:51.643447+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T00:20:51.643447+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-13T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-03T12:00:32.635279+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-13T05:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T06:35:36.782187+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T15:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-14T06:35:36.782187+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-14T15:20:00+00:00',
      departure: '2019-05-15T16:00:00+00:00',
      createdDate: '2019-04-10T00:20:52.039874+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T00:20:52.039874+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T00:20:52.039874+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T15:20:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-15T04:58:38.335561+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T05:28:10.062551+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-16T22:48:00+00:00',
      departure: '2019-05-17T16:42:00+00:00',
      createdDate: '2019-04-10T00:20:52.438361+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T00:20:52.438361+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T00:20:52.438361+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T12:00:32.644297+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-03T12:00:32.644297+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T22:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-20T06:55:03.105418+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T16:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-20T06:55:03.105418+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-19T22:30:00+00:00',
      departure: '2019-05-20T10:35:00+00:00',
      createdDate: '2019-04-20T00:09:21.31434+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T00:09:21.31434+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-21T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T00:09:21.31434+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-21T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T12:00:32.657167+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T13:39:12.370565+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-19T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-16T13:39:12.370565+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-19T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-23T12:06:05.531773+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T10:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-23T12:06:05.531773+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-23T05:54:00+00:00',
      departure: '2019-05-23T17:55:00+00:00',
      createdDate: '2019-04-24T00:26:38.326708+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'PLGDY', name: 'Gdynia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T00:26:38.326708+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-24T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T00:26:38.326708+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-24T05:37:37.74318+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-25T00:18:14.227993+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-23T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-23T09:07:02.700898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-23T05:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-23T09:07:02.700898+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-23T17:55:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-24T07:27:22.768412+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-25T00:00:00+00:00',
      departure: '2019-05-25T11:45:00+00:00',
      createdDate: '2019-05-02T14:24:18.848106+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T14:24:18.848106+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-25T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T14:24:18.848106+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T23:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-25T00:06:38.216216+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-25T11:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-26T00:36:40.068109+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-27T06:49:42.755814+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-25T11:45:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-27T06:49:42.755814+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-27T05:54:00+00:00',
      departure: '2019-05-27T18:36:00+00:00',
      createdDate: '2019-04-24T00:26:38.720577+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'EEMUG', name: 'Muuga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-27T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T00:26:38.720577+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T00:26:38.720577+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-24T05:37:37.770707+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-25T00:18:14.255885+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-27T05:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-27T06:20:13.516225+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T18:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-27T19:09:12.680591+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T18:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-28T07:01:44.773639+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-28T19:05:00+00:00',
      departure: '2019-05-29T21:05:00+00:00',
      createdDate: '2019-04-24T00:26:39.112676+00:00',
      isOmitted: false,
      service: 'Baltic Sea Express',
      port: { id: 'FIRAU', name: 'Rauma (Raumo)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-29T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T00:26:39.112676+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-29T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T00:26:39.112676+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-24T05:37:37.779355+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-25T00:18:14.263972+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-28T19:05:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-29T04:54:03.563122+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-29T21:05:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-31T05:39:19.289855+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-31T05:00:00+00:00',
      departure: '2019-05-31T19:00:00+00:00',
      createdDate: '2019-04-24T00:26:39.506796+00:00',
      isOmitted: true,
      service: 'Baltic Sea Express',
      port: { id: 'LTKLJ', name: 'Klaipeda' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-31T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T00:26:39.506796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-31T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T00:26:39.506796+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-24T05:37:37.782718+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-25T00:18:14.267942+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-05-02T14:24:18.871631+00:00',
        },
      ],
    },
  ],
}
export default vessel