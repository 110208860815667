import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import React, { FC } from 'react'
import { PortList } from '../../interfaces/ports'

const tooltipFormatter = (value, name) => [
  value,
  name === 'omittedAmount' ? 'Omitted Portcalls' : 'Number of Arrivals',
]

const Graph: FC<{ portList: PortList }> = ({ portList }) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={portList}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip formatter={tooltipFormatter} />
      <Bar dataKey="arrivalsAmount" stackId="a" fill="#82ca9d" />
      <Bar dataKey="omittedAmount" stackId="a" fill="#8884d8" />
    </BarChart>
  </ResponsiveContainer>
)

export default Graph
