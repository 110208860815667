import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import React, { FC } from 'react'
import { humanReadableDuration } from '../../utils'
import { HydratedPort } from '../../interfaces/ports'

const Graph: FC<{ port: HydratedPort }> = ({ port }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={port.portCalls}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="id" />
        <YAxis width={200} tickFormatter={humanReadableDuration} />
        <Tooltip formatter={humanReadableDuration} />
        <Bar dataKey="duration" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default Graph
