import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9597549, name: 'APL MIAMI' },
  portCalls: [
    {
      arrival: '2019-01-01T11:30:00+00:00',
      departure: '2019-01-01T23:45:00+00:00',
      createdDate: '2018-09-03T14:09:34.05154+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'DJPOD', name: 'Djibouti' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-03T14:09:34.05154+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-03T14:09:34.05154+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T03:28:24.319152+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T07:44:24.00914+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T07:44:24.317365+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T23:45:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T06:46:30.49029+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-08T04:20:00+00:00',
      departure: '2019-01-10T01:00:00+00:00',
      createdDate: '2018-09-03T14:09:34.421638+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'PKBQM', name: 'Muhammad Bin Qasim/Karachi' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-03T14:09:34.421638+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-03T14:09:34.421638+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T04:20:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T05:23:32.762851+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T05:23:33.058789+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-11T16:45:00+00:00',
      departure: '2019-01-12T12:00:00+00:00',
      createdDate: '2018-09-03T14:09:34.782823+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'INNSA', name: 'Jawaharlal Nehru (Nhava Sheva)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-03T14:09:34.782823+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-03T14:09:34.782823+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T16:45:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-12T05:13:43.037669+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T17:07:39.158259+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-13T03:00:00+00:00',
      departure: '2019-01-13T20:20:00+00:00',
      createdDate: '2018-09-03T14:09:35.14942+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'INHZR', name: 'Hazira' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-03T14:09:35.14942+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-03T14:09:35.14942+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T08:33:00.32372+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T20:20:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T08:33:00.594782+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-15T09:48:00+00:00',
      departure: '2019-01-16T14:20:00+00:00',
      createdDate: '2018-09-03T14:09:35.509272+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'INMUN', name: 'Mundra' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-03T14:09:35.509272+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-03T14:09:35.509272+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T03:28:24.929929+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T09:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T15:46:35.383526+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T14:20:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T14:54:00.935562+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-22T18:00:00+00:00',
      departure: '2019-01-23T02:48:00+00:00',
      createdDate: '2018-09-26T00:39:34.310234+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'SAKAC', name: 'King Abdullah City' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:34.310234+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:34.310234+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T18:50:57.15104+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T02:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T03:22:23.107075+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-28T12:00:00+00:00',
      departure: '2019-01-29T13:00:00+00:00',
      createdDate: '2018-09-26T00:39:34.652496+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'ITGIT', name: 'Gioia Tauro' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:34.652496+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:34.652496+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T08:46:38.41202+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T08:46:38.761023+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-25T08:51:44.393073+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-30T17:00:00+00:00',
      departure: '2019-01-31T08:06:00+00:00',
      createdDate: '2019-01-25T08:51:44.40241+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'ESVLC', name: 'Valencia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T08:51:44.40241+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T08:51:44.40241+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T17:29:24.640918+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T08:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T08:51:38.804941+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-01T12:48:00+00:00',
      departure: '2019-02-02T09:42:00+00:00',
      createdDate: '2018-09-26T00:39:35.004072+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:35.004072+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:35.004072+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T20:45:48.066781+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T20:45:48.336811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T08:46:39.439865+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T08:46:39.788165+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T03:59:12.876433+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T03:59:13.20536+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:43:49.284411+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:43:49.612772+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T08:45:10.375562+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T08:45:10.668985+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T05:23:43.814333+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T05:23:44.089053+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T17:29:24.647331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T17:29:24.647331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T08:51:38.845148+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T08:51:38.845148+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T12:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T12:20:19.875404+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T12:20:19.875404+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T09:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-02T09:22:17.962441+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-05T03:00:00+00:00',
      departure: '2019-02-05T18:00:00+00:00',
      createdDate: '2018-09-26T00:39:35.347052+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:35.347052+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:35.347052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T17:29:24.65338+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T17:29:24.65338+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T08:51:38.908984+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T08:51:38.908984+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T12:20:19.897077+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T12:20:19.897077+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-02T09:22:18.502924+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-02T09:22:18.502924+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T10:29:44.774015+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-05T09:18:37.406289+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-05T23:54:00+00:00',
      departure: '2019-02-07T14:36:00+00:00',
      createdDate: '2018-09-26T00:39:35.704222+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:35.704222+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:35.704222+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T17:29:26.418199+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T17:29:26.418199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T08:51:40.725817+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T08:51:40.725817+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T10:29:47.936714+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T10:29:47.936714+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.599933+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:42:47.402873+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T23:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:42:47.402873+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T23:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-06T00:20:53.247848+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-06T08:32:37.562751+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T14:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T15:20:35.138121+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-08T06:12:00+00:00',
      departure: '2019-02-09T05:00:00+00:00',
      createdDate: '2018-09-26T00:39:36.052123+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:36.052123+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:36.052123+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.694567+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.694567+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.494469+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.494469+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T07:13:51.666728+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T07:13:51.666728+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T06:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T12:52:42.136907+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-10T05:12:00+00:00',
      departure: '2019-02-11T06:36:00+00:00',
      createdDate: '2019-02-05T09:18:39.866254+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.866254+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.866254+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.598018+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.598018+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T05:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-10T06:37:19.395849+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T06:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T07:09:36.016768+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-12T13:30:00+00:00',
      departure: '2019-02-13T06:06:00+00:00',
      createdDate: '2018-09-26T00:39:36.403069+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'GBLGP', name: 'London Gateway Port' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:36.403069+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:36.403069+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.895852+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.895852+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:30.906099+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:30.906099+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.622051+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.622051+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:16:07.983166+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T00:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:16:07.983166+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T07:30:01.672327+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T07:30:01.672327+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T14:31:24.063865+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T06:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T06:26:24.536784+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-13T21:12:00+00:00',
      departure: '2019-02-14T12:48:00+00:00',
      createdDate: '2018-09-26T00:39:36.743909+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'FRLEH', name: 'Le Havre' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-26T00:39:36.743909+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-26T00:39:36.743909+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.917803+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:39.917803+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:32.909932+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:32.909932+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.638087+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T14:42:49.638087+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T07:30:01.709601+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T07:30:01.709601+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T21:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T21:55:50.791574+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T09:52:26.050224+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T12:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T13:09:34.327733+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-23T22:06:00+00:00',
      departure: '2019-02-24T07:06:00+00:00',
      createdDate: '2018-10-24T00:59:25.658358+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'SAKAC', name: 'King Abdullah City' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-24T00:59:25.658358+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-24T00:59:25.658358+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:43.109338+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:43.109338+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.002578+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.002578+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T21:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-10T06:37:19.421535+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-10T06:37:19.421535+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T07:09:36.04882+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T07:09:36.04882+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T21:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T05:51:39.871832+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T05:51:39.871832+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T07:30:04.626774+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T07:30:04.626774+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T21:55:53.02314+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T21:55:53.02314+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T09:52:28.908539+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T09:52:28.908539+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T07:04:49.761758+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T21:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T07:04:49.761758+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T04:02:02.520814+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T04:02:02.520814+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:27:33.668062+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T13:27:33.668062+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T22:28:16.581143+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T22:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T22:28:16.581143+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T21:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T05:34:59.74535+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T07:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T07:44:12.393851+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-26T17:30:00+00:00',
      departure: '2019-02-27T07:55:00+00:00',
      createdDate: '2018-10-24T00:59:26.016181+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'DJPOD', name: 'Djibouti' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-24T00:59:26.016181+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-24T00:59:26.016181+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:43.681342+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:43.681342+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.031147+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.031147+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T05:51:39.901407+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T21:55:53.050169+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T21:55:53.050169+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T09:52:29.521937+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T09:52:29.521937+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T07:04:49.78264+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T05:56:45.789989+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T05:56:45.789989+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T07:55:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T08:20:46.120311+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-05T04:10:00+00:00',
      departure: '2019-03-07T01:35:00+00:00',
      createdDate: '2018-10-24T00:59:26.373064+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'PKBQM', name: 'Muhammad Bin Qasim/Karachi' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-24T00:59:26.373064+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-24T00:59:26.373064+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:44.224923+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:44.224923+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.042235+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.042235+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T07:04:49.800819+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T07:04:49.800819+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T04:10:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T06:37:57.246998+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T01:35:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T06:37:57.246998+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-08T12:48:00+00:00',
      departure: '2019-03-09T19:10:00+00:00',
      createdDate: '2018-10-24T00:59:26.730505+00:00',
      isOmitted: false,
      service: 'IOS2',
      port: { id: 'INNSA', name: 'Jawaharlal Nehru (Nhava Sheva)' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-24T00:59:26.730505+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-24T00:59:26.730505+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:44.760007+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:44.760007+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.050361+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.050361+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T05:51:39.927692+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T04:29:23.790703+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-05T06:21:34.683047+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-05T06:21:34.683047+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T06:01:23.418452+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T06:37:57.26572+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T06:37:57.26572+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T12:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-09T05:02:36.294626+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-09T05:02:36.294626+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-10T06:23:18.868341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T19:10:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-10T17:56:29.749195+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-10T12:30:00+00:00',
      departure: '2019-03-11T11:30:00+00:00',
      createdDate: '2018-10-24T00:59:27.089113+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'INHZR', name: 'Hazira' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-24T00:59:27.089113+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-24T00:59:27.089113+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:45.301694+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:45.301694+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.056886+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.056886+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T05:51:39.933847+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:25.793944+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-12T12:30:00+00:00',
      departure: '2019-03-13T15:30:00+00:00',
      createdDate: '2018-10-24T00:59:27.448663+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'INMUN', name: 'Mundra' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-24T00:59:27.448663+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-24T00:59:27.448663+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:18:45.312316+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:18:45.312316+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.063312+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T09:52:35.063312+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T05:51:39.939336+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:26.920741+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-19T19:00:00+00:00',
      departure: '2019-03-20T07:00:00+00:00',
      createdDate: '2018-11-21T00:52:50.035032+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'SAKAC', name: 'King Abdullah City' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-20T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:50.035032+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-19T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:50.035032+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-14T06:05:54.954253+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-02-14T06:38:16.356936+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:27.704399+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-25T12:00:00+00:00',
      departure: '2019-03-26T12:00:00+00:00',
      createdDate: '2018-11-21T00:52:50.751628+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'ITGIT', name: 'Gioia Tauro' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:50.751628+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:50.751628+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-14T06:05:54.968499+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-02-14T06:38:16.909362+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:28.329544+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-29T07:00:00+00:00',
      departure: '2019-03-29T15:00:00+00:00',
      createdDate: '2018-11-21T00:52:51.443186+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:51.443186+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:51.443186+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-19T20:45:48.927847+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-19T20:45:49.197278+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:43:50.249348+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:43:50.57766+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:28.932364+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-01T22:00:00+00:00',
      departure: '2019-04-02T12:00:00+00:00',
      createdDate: '2018-11-21T00:52:52.099555+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:52.099555+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:52.099555+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:28.962986+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-03T10:00:00+00:00',
      departure: '2019-04-04T20:00:00+00:00',
      createdDate: '2018-11-21T00:52:52.774933+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:52.774933+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:52.774933+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:31.222238+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-05T08:00:00+00:00',
      departure: '2019-04-06T15:00:00+00:00',
      createdDate: '2018-11-21T00:52:53.446206+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:53.446206+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:53.446206+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:31.281441+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-07T15:00:00+00:00',
      departure: '2019-04-08T15:00:00+00:00',
      createdDate: '2018-11-21T00:52:54.132909+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'GBLGP', name: 'London Gateway Port' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:54.132909+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:54.132909+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T09:33:31.32734+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-09T20:00:00+00:00',
      departure: '2019-04-10T12:00:00+00:00',
      createdDate: '2018-11-21T00:52:54.828058+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'FRLEH', name: 'Le Havre' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-10T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-21T00:52:54.828058+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-09T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-21T00:52:54.828058+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-20T01:00:00+00:00',
      departure: '2019-04-20T23:00:00+00:00',
      createdDate: '2018-12-20T00:02:35.284973+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'SAKAC', name: 'King Abdullah City' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:02:35.284973+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:02:35.284973+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-23T17:00:00+00:00',
      departure: '2019-04-24T17:00:00+00:00',
      createdDate: '2018-12-20T00:02:36.245111+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'DJPOD', name: 'Djibouti' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:02:36.245111+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:02:36.245111+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-30T04:00:00+00:00',
      departure: '2019-05-02T04:00:00+00:00',
      createdDate: '2018-12-20T00:02:37.007517+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'PKBQM', name: 'Muhammad Bin Qasim/Karachi' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:02:37.007517+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-30T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:02:37.007517+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-03T16:30:00+00:00',
      departure: '2019-05-04T20:30:00+00:00',
      createdDate: '2018-12-20T00:02:37.705282+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'INNSA', name: 'Jawaharlal Nehru (Nhava Sheva)' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-04T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:02:37.705282+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-03T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:02:37.705282+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-05T11:30:00+00:00',
      departure: '2019-05-06T11:30:00+00:00',
      createdDate: '2018-12-20T00:02:38.386344+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'INHZR', name: 'Hazira' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-06T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:02:38.386344+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-05T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:02:38.386344+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-07T11:30:00+00:00',
      departure: '2019-05-08T15:30:00+00:00',
      createdDate: '2018-12-20T00:02:39.096122+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'INMUN', name: 'Mundra' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-08T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-20T00:02:39.096122+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-07T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-20T00:02:39.096122+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-14T19:00:00+00:00',
      departure: '2019-05-15T07:00:00+00:00',
      createdDate: '2019-01-16T00:59:25.526926+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'SAKAC', name: 'King Abdullah City' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:59:25.526926+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:59:25.526926+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-14T06:38:17.450915+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-20T11:00:00+00:00',
      departure: '2019-05-21T11:00:00+00:00',
      createdDate: '2019-01-16T00:59:26.368028+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'ITGIT', name: 'Gioia Tauro' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-21T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:59:26.368028+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:59:26.368028+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-14T06:38:18.011218+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-24T07:00:00+00:00',
      departure: '2019-05-24T15:00:00+00:00',
      createdDate: '2019-01-16T00:59:27.123068+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'MAPTM', name: 'Tanger Med' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-24T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:59:27.123068+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:59:27.123068+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-27T22:00:00+00:00',
      departure: '2019-05-28T12:00:00+00:00',
      createdDate: '2019-01-16T00:59:27.903554+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-28T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:59:27.903554+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-27T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:59:27.903554+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-29T10:00:00+00:00',
      departure: '2019-05-30T20:00:00+00:00',
      createdDate: '2019-01-16T00:59:28.664964+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-30T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:59:28.664964+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-29T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:59:28.664964+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-31T08:00:00+00:00',
      departure: '2019-06-01T15:00:00+00:00',
      createdDate: '2019-01-16T00:59:29.466803+00:00',
      isOmitted: true,
      service: 'IOS2',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-06-01T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T00:59:29.466803+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-31T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T00:59:29.466803+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-17T07:32:17.946495+00:00',
        },
      ],
    },
  ],
}
export default vessel
