import { connect, ConnectedProps } from 'react-redux'
import React, { FC } from 'react'
import Graph from '../port/Graph'
import { Helmet } from 'react-helmet'
import { RootState } from '../../interfaces/store/reducers'
import Table from '../port/Table'

const mapStateToProps = (state: RootState, ownProps: { match: { params: { id: string } } }) => {
  const {
    match: {
      params: { id },
    },
  } = ownProps
  return {
    port: state?.ports?.map?.[id],
  }
}
const connector = connect(mapStateToProps)

type PortProps = ConnectedProps<typeof connector>

const Port: FC<PortProps> = ({ port }) => {
  if (!port) return null
  const { name } = port
  return (
    <>
      <Helmet>
        <title>{`Port: ${name}`}</title>
      </Helmet>
      <h1 className="f4 bold center">{`${name}`}</h1>
      <Graph port={port} />
      <Table port={port} />
    </>
  )
}

export default connector(Port)
