export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

export const leftZeroPadding = (num: number, size: number): string => {
  const s = '000000000000' + num
  return s.substr(s.length - size)
}

export default (miliseconds: number): string => {
  const days = leftZeroPadding(Math.floor(miliseconds / DAY), 2)
  miliseconds %= DAY
  const hours = leftZeroPadding(Math.floor(miliseconds / HOUR), 2)
  miliseconds %= HOUR
  const minutes = leftZeroPadding(Math.floor(miliseconds / MINUTE), 2)
  miliseconds %= MINUTE
  const seconds = leftZeroPadding(Math.floor(miliseconds / SECOND), 2)
  if (+days) {
    return `${days}D ${hours}H ${minutes}M ${seconds}S`
  } else if (+hours) {
    return `${hours}H ${minutes}M ${seconds}S`
  } else if (+minutes) {
    return `${minutes}M ${seconds}S`
  } else {
    return `${seconds}S`
  }
}
