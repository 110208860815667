import { assoc, mean } from 'ramda'
import { HydratedPortCall, HydratedVessel, Vessel } from '../interfaces/vessels'
import { hydratePortCall } from '.'

const calculateVesselAverageDelays = (
  vessel: Record<'portCalls', Array<HydratedPortCall>> & Vessel
): { [key: number]: number } => {
  const avgPortCallDelays: { [key: number]: number } = {}
  for (let day = 0; day < 31; day++) {
    avgPortCallDelays[day] = Math.floor(
      mean(
        vessel.portCalls.flatMap(({ delaysPerDay, isOmitted }) =>
          isOmitted ? [] : delaysPerDay?.[day] ?? []
        )
      )
    )
  }
  return avgPortCallDelays
}

export default (vessel: Vessel): HydratedVessel => {
  const vesselWithHydratedPortCalls = assoc(
    'portCalls',
    vessel.portCalls.map(hydratePortCall(vessel.vessel)),
    vessel
  )
  return assoc(
    'avgPortCallDelays',
    calculateVesselAverageDelays(vesselWithHydratedPortCalls),
    vesselWithHydratedPortCalls
  )
}
