import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9732319, name: 'AL MASHRAB' },
  portCalls: [
    {
      arrival: '2018-12-31T19:10:00+00:00',
      departure: '2019-01-01T20:00:00+00:00',
      createdDate: '2018-09-17T09:33:09.440682+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'CNNBO', name: 'Ningbo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:09.440682+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:09.440682+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T12:57:42.783486+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T12:57:43.082718+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-07T03:52:27.508065+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-07T03:52:27.808902+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-07T13:05:24.919625+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-29T14:48:49.630541+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-29T14:48:49.979454+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-30T13:29:10.335854+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-30T13:29:10.680796+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T19:10:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-01T01:14:58.770128+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-01T01:14:59.107317+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-05T05:30:00+00:00',
      departure: '2019-01-06T16:42:00+00:00',
      createdDate: '2018-09-17T09:33:10.019386+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:10.019386+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:10.019386+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T12:57:43.824504+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T12:57:44.124263+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-07T03:52:28.465302+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-07T03:52:28.754311+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T07:48:43.582081+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T07:48:43.95162+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-05T05:54:39.434998+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-05T05:54:39.776273+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T16:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T17:26:25.734867+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-24T14:00:00+00:00',
      departure: '2019-01-26T14:00:00+00:00',
      createdDate: '2018-08-06T12:47:56.19438+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T12:47:56.19438+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T12:47:56.19438+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:29.833604+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-27T17:00:00+00:00',
      departure: '2019-01-30T06:00:00+00:00',
      createdDate: '2018-08-06T12:47:56.798486+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T12:47:56.798486+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T12:47:56.798486+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:30.102891+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-31T13:00:00+00:00',
      departure: '2019-02-02T05:00:00+00:00',
      createdDate: '2018-08-06T12:47:57.403262+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T12:47:57.403262+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T12:47:57.403262+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:30.372156+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-01T15:06:00+00:00',
      departure: '2019-02-03T09:12:00+00:00',
      createdDate: '2018-09-17T09:33:10.593228+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:10.593228+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:10.593228+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T07:48:44.653346+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T07:48:45.002563+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-05T05:54:40.945817+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-05T05:54:41.356058+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T17:26:26.845829+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T17:26:27.235848+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T14:14:45.974871+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T14:14:46.274294+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T12:16:51.181329+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T12:16:51.181329+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T06:24:21.384998+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T06:24:21.384998+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T15:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:29:35.480683+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T09:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T09:36:55.935206+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-03T06:00:00+00:00',
      departure: '2019-02-04T17:00:00+00:00',
      createdDate: '2018-08-06T12:47:58.007593+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T12:47:58.007593+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T12:47:58.007593+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:30.641212+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-04T17:42:00+00:00',
      departure: '2019-02-06T06:42:00+00:00',
      createdDate: '2018-09-17T09:33:11.17359+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:11.17359+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:11.17359+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T14:14:46.954015+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T12:16:51.196813+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T06:24:21.391982+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T06:24:21.391982+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T22:52:16.0348+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T14:23:02.754142+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T17:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T18:32:16.295943+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T19:06:56.276731+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T06:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-06T06:51:33.722275+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-08T16:12:00+00:00',
      departure: '2019-02-10T05:00:00+00:00',
      createdDate: '2018-09-17T09:33:11.752454+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:11.752454+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:11.752454+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T06:24:21.398776+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-06T07:57:39.25273+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-06T13:58:16.58974+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-06T13:58:16.58974+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T16:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T16:40:39.572298+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-09T23:13:24.218063+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-10T05:25:30.603321+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-12T14:00:00+00:00',
      departure: '2019-02-14T03:06:00+00:00',
      createdDate: '2018-09-17T09:33:12.336264+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:12.336264+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:12.336264+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-06T13:58:16.626433+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T07:41:56.533119+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T07:41:56.533119+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T10:11:28.512066+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T10:11:28.512066+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T10:43:53.373954+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T10:43:53.373954+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T14:29:37.843205+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T03:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T03:22:42.71833+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-02T03:00:00+00:00',
      departure: '2019-03-03T04:00:00+00:00',
      createdDate: '2018-09-02T01:14:00.413567+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-02T01:14:00.413567+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-02T01:14:00.413567+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:30.910175+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-05T14:00:00+00:00',
      departure: '2019-03-06T14:00:00+00:00',
      createdDate: '2018-09-02T01:14:00.999992+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-02T01:14:00.999992+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-02T01:14:00.999992+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:31.183992+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-08T00:00:00+00:00',
      departure: '2019-03-09T15:01:00+00:00',
      createdDate: '2018-09-02T01:14:01.579417+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'KRPUS', name: 'Busan' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T15:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-02T01:14:01.579417+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-02T01:14:01.579417+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:31.453895+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-11T04:54:00+00:00',
      departure: '2019-03-12T00:06:00+00:00',
      createdDate: '2018-09-17T09:33:12.916654+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:12.916654+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:12.916654+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T10:11:29.64674+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T10:11:29.64674+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T03:26:59.855847+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T03:26:59.855847+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T12:51:56.296461+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T12:51:56.296461+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:00:59.818834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:00:59.818834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T04:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T05:23:26.838125+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T00:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T00:29:43.468383+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-11T23:00:00+00:00',
      departure: '2019-03-13T17:00:00+00:00',
      createdDate: '2018-09-02T01:14:02.168397+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'CNNBO', name: 'Ningbo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-02T01:14:02.168397+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-02T01:14:02.168397+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-09-17T08:22:31.724448+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-14T04:30:00+00:00',
      departure: '2019-03-15T04:30:00+00:00',
      createdDate: '2018-09-17T09:33:13.497628+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:13.497628+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:13.497628+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T10:11:29.704027+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T10:11:29.704027+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-20T03:44:00.759086+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-14T23:00:00+00:00',
      departure: '2019-03-16T05:24:00+00:00',
      createdDate: '2018-09-17T09:33:14.071773+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'KRPUS', name: 'Busan' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:14.071773+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T15:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:14.071773+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T10:11:29.71564+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T10:11:29.71564+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T03:26:59.923168+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T03:26:59.923168+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T15:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T23:53:57.300435+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-14T23:32:40.116955+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-14T23:32:40.116955+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T00:04:14.148477+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T05:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-16T05:58:56.346987+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-18T15:00:00+00:00',
      departure: '2019-03-19T12:00:00+00:00',
      createdDate: '2018-09-17T09:33:14.646974+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'CNNBO', name: 'Ningbo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-20T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-17T09:33:14.646974+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-17T09:33:14.646974+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-19T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T10:11:29.721546+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T03:26:59.955308+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-15T07:41:52.615671+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-15T07:41:52.615671+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-16T05:58:56.373619+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-20T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-16T05:58:56.373619+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-17T09:03:42.665468+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-17T09:03:42.665468+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T14:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T14:44:09.1128+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-20T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T14:44:09.1128+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T01:10:11.543925+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T01:10:11.543925+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T12:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T13:01:35.322687+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T11:45:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T01:31:45.899381+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T06:12:27.800897+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-21T17:36:00+00:00',
      departure: '2019-03-23T18:18:00+00:00',
      createdDate: '2018-12-18T06:30:43.087523+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:43.087523+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:43.087523+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-21T05:50:28.630831+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-21T05:50:28.630831+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T17:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-21T18:12:28.275331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T18:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-23T19:00:19.932576+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-18T14:30:00+00:00',
      departure: '2019-04-19T22:30:00+00:00',
      createdDate: '2018-12-18T06:30:43.954241+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:43.954241+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:43.954241+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T14:53:09.519707+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T22:55:10.956741+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-21T04:54:00+00:00',
      departure: '2019-04-24T20:48:00+00:00',
      createdDate: '2018-12-18T06:30:44.795954+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:44.795954+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-21T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:44.795954+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-21T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T14:05:46.714836+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T14:05:46.714836+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-21T04:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-21T05:09:42.289833+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-23T11:56:17.740208+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T20:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T21:29:17.179908+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-26T04:54:00+00:00',
      departure: '2019-04-27T04:18:00+00:00',
      createdDate: '2018-12-18T06:30:45.697781+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'BEANR', name: 'Antwerpen' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:45.697781+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:45.697781+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-23T11:56:18.140812+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-23T11:56:18.140812+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T04:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-26T05:12:36.510558+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T06:46:53.500089+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T08:51:27.653679+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T11:11:05.889394+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T04:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T08:11:02.353946+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-02T14:30:00+00:00',
      departure: '2019-05-04T07:36:00+00:00',
      createdDate: '2018-12-18T06:30:46.565413+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:46.565413+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:46.565413+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-26T05:12:36.561163+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-26T05:12:36.561163+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-27T06:46:53.54937+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T06:46:53.54937+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-27T08:51:27.739849+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T08:51:27.739849+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T11:11:05.942543+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T11:11:05.942543+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-28T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T08:11:02.407315+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T08:11:02.407315+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-04T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-01T09:44:05.243408+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-01T09:44:05.243408+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T15:15:37.095469+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-04T07:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-04T07:52:45.214149+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-28T14:00:00+00:00',
      departure: '2019-05-29T14:00:00+00:00',
      createdDate: '2018-12-18T06:30:48.727449+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-29T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:48.727449+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-28T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:48.727449+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T02:15:02.930853+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-29T04:00:00+00:00',
      departure: '2019-05-29T17:00:00+00:00',
      createdDate: '2018-12-18T06:30:47.408154+00:00',
      isOmitted: false,
      service: 'Far East Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:47.408154+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-26T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:47.408154+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-26T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-26T05:12:38.404239+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-26T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-26T05:12:38.404239+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-27T06:46:55.379904+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-26T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T06:46:55.379904+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-26T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T08:51:29.632813+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-26T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-27T08:51:29.632813+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T08:11:04.303393+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-26T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T08:11:04.303393+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-28T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T00:33:41.316563+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-27T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T00:33:41.316563+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-28T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-12T03:51:39.76338+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-27T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-12T03:51:39.76338+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-29T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-22T03:43:07.914921+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-30T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-22T03:43:07.914921+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-29T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-29T04:43:23.046835+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-29T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-29T04:43:23.046835+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-29T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-29T17:35:05.577208+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-31T00:00:00+00:00',
      departure: '2019-06-01T15:01:00+00:00',
      createdDate: '2018-12-18T06:30:49.593195+00:00',
      isOmitted: true,
      service: 'Far East Loop 4',
      port: { id: 'KRPUS', name: 'Busan' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-06-01T15:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T06:30:49.593195+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-31T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T06:30:49.593195+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T02:15:02.994654+00:00',
        },
      ],
    },
  ],
}

export default vessel
