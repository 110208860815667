import { Action, ActionWithPayload } from '../../interfaces/store/actions'

export function createAction<T extends string>(type: T): Action<T>
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload ? { type, payload } : { type }
}

export { PortsActions } from './ports'
export { VesselsActions } from './vessels'
