import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9769271, name: 'MOL TRIUMPH' },
  portCalls: [
    {
      arrival: '2018-12-31T00:00:00+00:00',
      departure: '2019-01-02T03:18:00+00:00',
      createdDate: '2018-11-06T15:39:48.993777+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNTNG', name: 'Tianjin Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2018-12-30T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T15:39:48.993777+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T15:39:48.993777+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-31T00:38:15.393223+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T03:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T04:00:08.38095+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-03T09:38:00+00:00',
      departure: '2019-01-04T15:41:00+00:00',
      createdDate: '2018-11-06T15:39:49.689261+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNQIN', name: 'Qingdao' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T15:39:49.689261+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T15:39:49.689261+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T01:49:25.472706+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T01:42:27.25877+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T09:38:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T09:48:11.007693+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T15:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-04T17:28:42.084847+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T15:41:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-05T02:08:31.181263+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-06T17:30:00+00:00',
      departure: '2019-01-07T21:00:00+00:00',
      createdDate: '2018-11-06T15:39:50.623721+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T15:39:50.623721+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T15:39:50.623721+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T01:08:28.848123+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T01:08:29.177219+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T17:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T18:22:40.814794+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T01:16:44.43647+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T21:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T22:11:39.239367+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T01:35:01.388761+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-08T12:30:00+00:00',
      departure: '2019-01-09T15:50:00+00:00',
      createdDate: '2018-11-06T16:31:36.665473+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNNBO', name: 'Ningbo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:36.665473+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:36.665473+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T01:08:29.834471+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T06:16:51.344127+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T06:16:51.621449+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T08:10:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T01:35:02.183286+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T10:42:17.486754+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T10:42:17.788935+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T11:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T12:23:49.830289+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T15:56:34.742904+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-09T01:27:41.380032+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T16:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-09T17:21:40.370823+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T15:50:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T01:00:41.92764+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-11T13:18:00+00:00',
      departure: '2019-01-12T03:24:00+00:00',
      createdDate: '2018-11-06T16:31:37.504767+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:37.504767+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:37.504767+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T06:26:06.505844+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T06:26:06.77818+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T06:11:14.849122+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T06:11:15.140194+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T13:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T13:48:13.166854+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T02:46:51.826716+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T03:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T03:51:36.494337+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-12T23:12:00+00:00',
      departure: '2019-01-13T06:00:00+00:00',
      createdDate: '2018-11-06T16:31:38.344181+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:38.344181+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:38.344181+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T06:26:07.424623+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T06:26:07.699613+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T23:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-12T23:51:09.86869+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-13T07:07:42.919902+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-16T20:00:00+00:00',
      departure: '2019-01-17T17:42:00+00:00',
      createdDate: '2018-11-06T16:31:39.193082+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:39.193082+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:39.193082+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-22T09:43:03.043716+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-22T09:43:03.336962+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T08:15:55.75603+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T08:15:56.042102+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T01:35:04.434616+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T17:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T18:06:17.8459+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-14T07:12:00+00:00',
      departure: '2019-02-16T23:36:00+00:00',
      createdDate: '2018-11-06T16:31:40.037536+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:40.037536+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:40.037536+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:30.94526+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:30.94526+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.789341+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.789341+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T07:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.234842+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T23:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.583076+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-18T21:48:00+00:00',
      departure: '2019-02-19T20:48:00+00:00',
      createdDate: '2018-11-06T16:31:40.874858+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'FRLEH', name: 'Le Havre' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:40.874858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:40.874858+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-16T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:07.323857+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:07.323857+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.797824+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.797824+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T14:39:50.363675+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T14:39:50.363675+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T07:43:11.454422+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T07:43:11.454422+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T21:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T22:23:49.279644+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T20:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T21:16:20.247447+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-21T04:42:00+00:00',
      departure: '2019-02-23T12:42:00+00:00',
      createdDate: '2018-11-06T16:31:41.715892+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:41.715892+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:41.715892+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:07.340106+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:07.340106+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.85572+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.85572+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T14:39:50.427878+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T14:39:50.427878+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T07:43:11.531834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T07:43:11.531834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T12:05:20.900249+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-19T12:05:20.900249+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T04:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T05:14:53.579787+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T12:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T19:07:38.110055+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-24T15:06:00+00:00',
      departure: '2019-02-28T14:42:00+00:00',
      createdDate: '2018-11-06T16:31:42.571761+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:42.571761+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:42.571761+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:07.3601+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:07.3601+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:30.968257+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:30.968257+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.419232+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.419232+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.876086+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:46.876086+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T14:39:50.441889+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T14:39:50.441889+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T07:43:11.559607+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T07:43:11.559607+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T15:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T15:47:08.635426+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-26T15:30:30.382195+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T10:38:19.537252+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T11:12:29.051021+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T14:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T16:08:07.138207+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-18T10:32:00+00:00',
      departure: '2019-03-19T12:25:00+00:00',
      createdDate: '2018-11-06T16:31:43.409889+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'AEJEA', name: 'Jebel Ali' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:43.409889+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:43.409889+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.823975+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.823975+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.000227+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.000227+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T07:04:44.983966+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T07:04:44.983966+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T05:32:36.74816+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T05:32:36.74816+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.426104+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.426104+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.239007+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.711898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.711898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:48.982858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:48.982858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.7288+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.7288+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.43567+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.43567+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.253498+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.253498+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T03:11:03.095266+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T03:11:03.095266+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T10:32:11.560117+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T10:32:11.560117+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-03T03:52:58.221731+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-03T03:52:58.221731+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T02:56:07.702658+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T02:56:07.702658+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T07:55:46.116517+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T07:55:46.116517+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T03:37:54.80952+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T03:37:54.80952+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T02:55:08.979202+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T02:55:08.979202+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-14T08:42:26.659543+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-14T08:42:26.659543+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T09:41:56.924217+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T09:41:56.924217+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T09:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T10:15:01.675619+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T10:32:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T13:28:46.827846+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T13:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T13:31:37.878098+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T12:25:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T07:10:17.22111+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-31T21:00:00+00:00',
      departure: '2019-04-02T03:00:00+00:00',
      createdDate: '2018-11-06T16:31:45.060405+00:00',
      isOmitted: true,
      service: 'Far East Loop 2',
      port: { id: 'CNTNG', name: 'Tianjin Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:45.060405+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:45.060405+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.926784+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.926784+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.059592+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.059592+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.426765+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.426765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.005967+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.005967+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T05:32:37.83568+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T05:32:37.83568+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.461045+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.461045+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:57:04.932964+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:57:04.932964+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.309796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.309796+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.743852+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.743852+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.268145+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.268145+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.744964+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.744964+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.45948+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.45948+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.339198+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.339198+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.795085+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.795085+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.698764+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.698764+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:51.952447+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:51.952447+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:40:15.631348+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:40:15.631348+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-26T03:49:22.666176+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-02T07:48:00+00:00',
      departure: '2019-04-03T08:54:00+00:00',
      createdDate: '2018-11-06T16:31:44.23629+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-21T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:44.23629+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-20T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:44.23629+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.882207+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.882207+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.043687+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.043687+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.38901+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.38901+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T07:04:44.993587+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T07:04:44.993587+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T05:32:37.293089+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T05:32:37.293089+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.4504+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.4504+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:57:04.374993+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:57:04.374993+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.270072+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.270072+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.72789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.72789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.131136+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.131136+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.737058+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.737058+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.451793+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.451793+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.315253+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.315253+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.747022+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.747022+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.62534+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.62534+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:07:49.762197+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:07:49.762197+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T02:21:53.734735+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-19T02:21:53.734735+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T04:10:32.373293+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T04:10:32.373293+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T07:55:47.329588+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T07:55:47.329588+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T08:34:27.200506+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T08:34:27.200506+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T07:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-02T08:17:18.142874+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T08:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-03T09:41:05.607347+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-08T00:36:00+00:00',
      departure: '2019-04-09T23:06:00+00:00',
      createdDate: '2019-02-26T03:49:23.803233+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'KRPUS', name: 'Busan' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-26T03:49:23.803233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T15:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-26T03:49:23.803233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T10:38:19.594089+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T10:38:19.594089+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T07:55:48.421049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T07:55:48.421049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T15:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.063976+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.063976+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-05T00:27:30.370718+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-05T00:27:30.370718+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-08T00:02:43.624032+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-08T00:02:43.624032+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T00:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-08T01:04:52.455935+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T15:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-08T23:41:29.815551+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T23:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-09T23:31:47.778106+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-08T16:01:00+00:00',
      departure: '2019-04-09T13:00:00+00:00',
      createdDate: '2018-11-06T16:31:47.574806+00:00',
      isOmitted: true,
      service: 'Far East Loop 2',
      port: { id: 'CNNBO', name: 'Ningbo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:47.574806+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:47.574806+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:10.12812+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:10.12812+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-09T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.097227+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-10T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.097227+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.497811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.497811+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T07:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.038971+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.038971+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-10T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T05:32:41.370546+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-09T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T05:32:41.370546+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.478896+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.478896+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:57:05.995852+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:57:05.995852+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.438406+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.438406+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.791555+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.791555+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.566097+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.566097+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.77797+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.77797+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.482522+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.482522+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.390108+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.390108+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.828759+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.828759+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.784015+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.784015+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.015869+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.015869+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:40:17.377231+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:40:17.377231+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-26T03:49:24.884391+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-11T10:54:00+00:00',
      departure: '2019-04-12T20:42:00+00:00',
      createdDate: '2018-11-06T16:31:45.788143+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNQIN', name: 'Qingdao' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:45.788143+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:45.788143+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.973109+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:08.973109+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.070321+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.070321+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.447682+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.447682+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.014439+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.014439+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T05:32:40.278061+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T05:32:40.278061+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.466961+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.466961+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:57:05.465765+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:57:05.465765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.33756+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.33756+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.759897+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.759897+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.361114+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.361114+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.754641+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.754641+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.466252+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.466252+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.352992+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.352992+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.811425+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.811425+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-03T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.753579+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.753579+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:51.996151+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:40:16.23945+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:40:16.23945+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T07:00:09.503897+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-03T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T07:00:09.503897+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T05:28:11.505794+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T05:28:11.505794+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.19362+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-10T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.19362+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-10T01:36:53.495546+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T01:36:53.495546+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T10:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T11:34:18.720718+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T20:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T21:08:39.795321+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-15T02:00:00+00:00',
      departure: '2019-04-16T05:30:00+00:00',
      createdDate: '2018-11-06T16:31:46.733532+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:46.733532+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:46.733532+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:09.532505+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:09.532505+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.080405+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.080405+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.469594+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.469594+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.027571+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.027571+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T05:32:40.822667+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T05:32:40.822667+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.473038+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.473038+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:57:05.989562+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:57:05.989562+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.373826+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.373826+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.773079+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.773079+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.467668+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.467668+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.766908+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.766908+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.475431+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.475431+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.368848+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.368848+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.822566+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.822566+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.770646+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.770646+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.008372+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.008372+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:40:16.821601+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:40:16.821601+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T07:00:09.627864+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-09T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T02:56:07.822846+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-10T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T02:56:07.822846+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T07:18:36.003113+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-10T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T02:21:25.201601+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T02:21:25.201601+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.207672+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.207672+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-10T09:13:39.431284+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T01:40:55.549207+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-14T01:40:55.549207+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T02:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T02:33:06.594846+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T03:35:38.428362+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T06:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T06:33:27.872968+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T07:39:42.294258+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T10:25:26.763766+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-18T14:48:00+00:00',
      departure: '2019-04-19T08:06:00+00:00',
      createdDate: '2019-02-18T01:01:52.023297+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-10T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.023297+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-09T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.023297+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:40:17.951616+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:40:17.951616+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T07:00:09.650336+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T05:28:11.648573+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T05:28:11.648573+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.214646+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.214646+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T14:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T15:22:58.757706+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T02:27:00.241305+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T08:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T08:43:48.805294+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-19T16:30:00+00:00',
      departure: '2019-04-20T19:30:00+00:00',
      createdDate: '2019-03-11T02:55:09.108523+00:00',
      isOmitted: true,
      service: 'Far East Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T02:55:09.108523+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T02:55:09.108523+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-11T03:26:07.235329+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-19T21:00:00+00:00',
      departure: '2019-04-20T18:48:00+00:00',
      createdDate: '2018-11-06T16:31:48.415714+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-06T16:31:48.415714+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-06T16:31:48.415714+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:16:10.915502+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:16:10.915502+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.11489+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T08:47:31.11489+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.52077+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T10:45:23.52077+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.054996+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T07:04:45.054996+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T05:32:41.902345+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T05:32:41.902345+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.484834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:23:32.484834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T09:57:06.001519+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T09:57:06.001519+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.450657+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T14:59:06.450657+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.801932+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T03:24:18.801932+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.624636+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T15:01:49.624636+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.786679+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T03:34:07.786679+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.489597+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T05:44:47.489597+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.402751+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-11T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T08:05:39.402751+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.844286+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-15T04:23:52.844286+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-11T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.813138+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-17T00:00:46.813138+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-11T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.028358+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-10T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.028358+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:40:18.51239+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:40:18.51239+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T07:00:09.66067+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T05:28:11.674864+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T05:28:11.674864+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.221278+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.221278+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-17T03:12:53.782441+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-19T21:47:35.760343+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T18:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T19:05:40.245869+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-24T15:42:00+00:00',
      departure: '2019-04-25T07:54:00+00:00',
      createdDate: '2019-02-18T01:01:52.035719+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.035719+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.035719+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T02:40:19.101221+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T02:40:19.101221+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T07:35:39.017453+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T07:35:39.017453+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-25T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.228365+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-24T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.228365+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-24T15:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T16:09:00.289777+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-25T07:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T08:23:26.350347+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-10T20:00:00+00:00',
      departure: '2019-05-11T20:00:00+00:00',
      createdDate: '2019-02-18T01:01:52.048248+00:00',
      isOmitted: true,
      service: 'Far East Loop 2',
      port: { id: 'FRLEH', name: 'Le Havre' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-10T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.048248+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-11T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.048248+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-20T11:18:12.197968+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-16T07:18:00+00:00',
      departure: '2019-05-18T07:42:00+00:00',
      createdDate: '2019-02-18T01:01:52.043147+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'GBSOU', name: 'Southampton' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-09T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.043147+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-07T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.043147+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T14:03:44.878197+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T14:03:44.878197+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-11T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-21T08:33:07.589418+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T23:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.239096+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.239096+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T07:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-16T07:40:54.278236+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T08:12:39.580145+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-17T06:10:07.973066+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T07:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-18T08:10:59.054742+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-20T18:24:00+00:00',
      departure: '2019-05-23T22:30:00+00:00',
      createdDate: '2019-02-18T01:01:52.052858+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'DEHAM', name: 'Hamburg' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-13T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.052858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.052858+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-13T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T14:03:44.889635+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T14:03:44.889635+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.247706+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-23T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.247706+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-16T08:12:39.589488+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T18:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-20T19:02:49.234481+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-23T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-23T23:01:13.705753+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-25T19:48:00+00:00',
      departure: '2019-05-29T00:36:00+00:00',
      createdDate: '2019-02-18T01:01:52.057353+00:00',
      isOmitted: false,
      service: 'Far East Loop 2',
      port: { id: 'NLRTM', name: 'Rotterdam' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.057353+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T22:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T01:01:52.057353+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T14:03:44.913311+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T14:03:44.913311+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.288351+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T03:26:07.288351+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-21T07:30:10.547852+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-28T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-21T07:30:10.547852+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-28T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-23T14:20:33.327498+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-23T14:20:33.327498+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-28T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-23T23:01:13.760797+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-23T23:01:13.760797+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-24T08:39:40.478686+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-28T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-24T08:39:40.478686+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-25T19:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-26T00:49:40.990849+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-29T00:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-29T01:12:08.467424+00:00',
        },
      ],
    },
  ],
}
export default vessel
