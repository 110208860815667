import { flip, identity, mergeDeepRight, path, reduceBy } from 'ramda'
import { VesselsActions } from '../actions'
import { VesselsState } from '../../interfaces/store/reducers'
import { VesselsTypes } from '../../interfaces/store/actions'

const initialState: VesselsState = {
  list: [],
  map: {},
}

export default (state = initialState, action: VesselsActions): VesselsState => {
  switch (action.type) {
    case VesselsTypes.UPDATE_LIST: {
      return mergeDeepRight(state, { list: action.payload }) as VesselsState
    }
    case VesselsTypes.UPDATE: {
      return mergeDeepRight(state, {
        map: reduceBy(
          flip(identity),
          {} as VesselsState['map'],
          path(['vessel', 'imo']),
          action.payload
        ),
      }) as VesselsState
    }
    default:
      return state
  }
}
