import { assoc, pipe, propEq } from 'ramda'
import { HydratedPortCall, LogEntry, PortCall, SimplifiedVessel } from '../interfaces/vessels'

const DAY = 1000 * 60 * 60 * 24

const calculateDelays = (
  arrivalLogEntries: Array<Record<'daysPriorToArrival', number> & LogEntry>,
  arrivalReference
) => {
  const firstReference = arrivalLogEntries?.[0]?.daysPriorToArrival
  let day = firstReference !== undefined && firstReference >= 0 ? firstReference : -1
  const delays = {}
  arrivalLogEntries.forEach((entry, idx) => {
    const nextLogPriorToArrival = arrivalLogEntries?.[idx + 1]?.daysPriorToArrival
    const nextDay =
      nextLogPriorToArrival !== undefined && nextLogPriorToArrival >= 0 ? nextLogPriorToArrival : -1
    const delay = Math.abs(arrivalReference - new Date(entry.arrival).getTime())
    while (day !== nextDay) {
      delays[day] = delay
      day--
    }
  })
  return delays
}

export default (vessel: SimplifiedVessel) => (portCall: PortCall): HydratedPortCall => {
  const { arrival } = portCall
  const arrivalReference = new Date(arrival).getTime()

  const hydratedLogEntries = portCall.logEntries.map(e =>
    assoc(
      'daysPriorToArrival',
      Math.floor((arrivalReference - new Date(e.createdDate).getTime()) / DAY),
      e
    )
  )
  const arrivalLogEntries = hydratedLogEntries
    .filter(propEq('updatedField', 'arrival'))
    .sort((a, b) => (new Date(b.createdDate) > new Date(a.createdDate) ? -1 : 1))

  return pipe(
    assoc('delaysPerDay', calculateDelays(arrivalLogEntries, arrivalReference)),
    assoc('vessel', vessel),
    assoc('duration', new Date(portCall.departure).getTime() - new Date(portCall.arrival).getTime())
  )(portCall as HydratedPortCall)
}
