import React, { FC } from 'react'
import { HydratedPortCall } from '../../interfaces/vessels'

const Table: FC<{ portCall: HydratedPortCall }> = ({ portCall }) => (
  <div className="pa4">
    <h1>Log Entries</h1>
    <div className="overflow-auto">
      <table className="f6 w-100 mw8 center" cellSpacing="0">
        <thead>
          <tr className="stripe-dark">
            <th className="fw6 tl pa3 bg-white tc">Created Date</th>
            <th className="fw6 tl pa3 bg-white tc">Updated Field</th>
            <th className="fw6 tl pa3 bg-white tc">Updated Value</th>
          </tr>
        </thead>
        <tbody className="lh-copy">
          {portCall.logEntries.map((v, idx) => (
            <tr key={idx} className="stripe-dark">
              <td className="pa3">{v.createdDate}</td>
              <td className="pa3">{v.updatedField}</td>
              <td className="pa3">{String(v[v.updatedField])}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)

export default Table
