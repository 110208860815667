import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { VesselList } from '../../interfaces/vessels'

const Table: FC<{ vesselList: VesselList }> = ({ vesselList }) => {
  const history = useHistory()
  const handleNavigateToVessel = vessel => () => history.push(`/vessels/${vessel.imo}`)
  return (
    <div className="pa4">
      <div className="overflow-auto">
        <table className="f6 w-100 mw8 center" cellSpacing="0">
          <thead>
            <tr className="stripe-dark">
              <th className="fw6 tl pa3 bg-white tc">IMO</th>
              <th className="fw6 tl pa3 bg-white tc">Name</th>
            </tr>
          </thead>
          <tbody className="lh-copy">
            {vesselList.map(v => (
              <tr key={v.imo} className="stripe-dark pointer" onClick={handleNavigateToVessel(v)}>
                <td className="pa3">{v.imo}</td>
                <td className="pa3">{v.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
