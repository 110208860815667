import React, { FC } from 'react'
import { PortList } from '../../interfaces/ports'
import { useHistory } from 'react-router-dom'

const Table: FC<{ portList: PortList }> = ({ portList }) => {
  const history = useHistory()
  const handleNavigateToPort = port => () => history.push(`/ports/${port.id}`)
  return (
    <div className="pa4">
      <div className="overflow-auto">
        <table className="f6 w-100 mw8 center" cellSpacing="0">
          <thead>
            <tr className="stripe-dark">
              <th className="fw6 tl pa3 bg-white tc">Name</th>
              <th className="fw6 tl pa3 bg-white tc">Number of Arrivals</th>
              <th className="fw6 tl pa3 bg-white tc">Omitted Portcalls</th>
              <th className="fw6 tl pa3 bg-white tc">Total Portcalls</th>
            </tr>
          </thead>
          <tbody className="lh-copy">
            {portList.map(v => (
              <tr key={v.id} className="stripe-dark pointer" onClick={handleNavigateToPort(v)}>
                <td className="pa3">{v.name}</td>
                <td className="pa3">{v.arrivalsAmount}</td>
                <td className="pa3">{v.omittedAmount}</td>
                <td className="pa3">{v.portCallsAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
