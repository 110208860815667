import { fetchVessel, fetchVesselList } from '../../api'
import { hydrateVessel, scrapPorts } from '../../utils'
import { PortsActions, VesselsActions } from './'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { RootState } from '../../interfaces/store/reducers'

export const init = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>
): Promise<void> => {
  const vesselList = await fetchVesselList()
  dispatch(VesselsActions.updateVesselList(vesselList))
  const vessels = (await Promise.all(vesselList.map(({ imo }) => fetchVessel(imo)))).filter(Boolean)
  const vesselsDetails = vessels.map(hydrateVessel)
  const ports = scrapPorts(vesselsDetails)
  dispatch(VesselsActions.updateVessels(vesselsDetails))
  dispatch(PortsActions.update(ports))
}
