import { connect, ConnectedProps } from 'react-redux'
import Controls, { SORTINGS } from '../ports/Controls'
import React, { Component } from 'react'
import Graph from '../ports/Graph'
import { Helmet } from 'react-helmet'
import { RootState } from '../../interfaces/store/reducers'
import Table from '../ports/Table'

const mapStateToProps = (state: RootState) => ({
  portList: state.ports.list,
})

const connector = connect(mapStateToProps, null, null, { pure: false })

type PortsProps = ConnectedProps<typeof connector>

class Ports extends Component<PortsProps> {
  state = {
    data: [],
    sorting: SORTINGS.ALPHA,
  }

  sortData = (sortingParam, sortingFn) => {
    const previousState = this.state.data
    this.setState({ data: [] }, () =>
      this.setState({
        data: sortingFn(previousState),
        sorting: sortingParam,
      })
    )
  }
  componentDidUpdate() {
    if (!this.state.data.length && this.props.portList.length) {
      this.setState({
        data: this.props.portList,
      })
    }
  }

  render() {
    const { data, sorting } = this.state
    return (
      <>
        <Helmet>
          <title>Port list</title>
        </Helmet>
        <h1 className="f4 bold center">Portcalls by port</h1>
        <Graph portList={data} />
        <Controls sortData={this.sortData} sorting={sorting} />
        <Table portList={data} />
      </>
    )
  }
}

export default connector(Ports)
