import React, { FC } from 'react'
import { HydratedVessel } from '../../interfaces/vessels'
import PercentileTable from './PercentileTable'
import PortCallsTable from '../PortCallsTable'

const Table: FC<{ vessel: HydratedVessel }> = ({ vessel }) => (
  <div className="pa4">
    <h1>Arrival Delay Percentiles</h1>
    <div className="overflow-auto">
      <PercentileTable days={14} vessel={vessel} />
      <PercentileTable days={7} vessel={vessel} />
      <PercentileTable days={2} vessel={vessel} />
    </div>

    <PortCallsTable portCalls={vessel.portCalls} viewFrom="vessel" />
  </div>
)

export default Table
