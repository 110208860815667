import { ActionsUnion, PortsTypes } from '../../interfaces/store/actions'
import { createAction } from './'
import { HydratedPortMap } from '../../interfaces/ports'

export const PortsActions = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  update: (ports: HydratedPortMap) => createAction(PortsTypes.UPDATE, ports),
}

export type PortsActions = ActionsUnion<typeof PortsActions>
