import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9757187, name: 'MILANO BRIDGE' },
  portCalls: [
    {
      arrival: '2018-12-30T08:00:00+00:00',
      departure: '2018-12-31T03:00:00+00:00',
      createdDate: '2018-11-15T14:58:44.813629+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-31T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:44.813629+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-30T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:44.813629+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:07:59.573723+00:00',
        },
      ],
    },
    {
      arrival: '2018-12-31T11:00:00+00:00',
      departure: '2019-01-01T11:00:00+00:00',
      createdDate: '2018-11-15T14:58:45.828247+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:45.828247+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:45.828247+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:00.172209+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-03T11:00:00+00:00',
      departure: '2019-01-05T04:00:00+00:00',
      createdDate: '2018-08-06T15:01:10.168676+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T15:01:10.168676+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T15:01:10.168676+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:23:58.301299+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-06T10:00:00+00:00',
      departure: '2019-01-07T08:00:00+00:00',
      createdDate: '2018-11-15T14:58:47.42016+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:47.42016+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:47.42016+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:01.388197+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-06T12:00:00+00:00',
      departure: '2019-01-07T17:00:00+00:00',
      createdDate: '2018-08-06T15:01:10.773301+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USORF', name: 'Norfolk' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T15:01:10.773301+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T15:01:10.773301+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:23:58.587179+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-10T14:00:00+00:00',
      departure: '2019-01-11T06:01:00+00:00',
      createdDate: '2018-08-06T15:01:11.377376+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USTSA', name: 'Savannah' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T06:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T15:01:11.377376+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T15:01:11.377376+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:23:58.873228+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-12T00:00:00+00:00',
      departure: '2019-01-12T15:00:00+00:00',
      createdDate: '2018-08-06T15:01:11.981922+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USC84', name: 'Charleston' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-06T15:01:11.981922+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-06T15:01:11.981922+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:23:59.159633+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-20T13:48:00+00:00',
      departure: '2019-01-22T09:48:00+00:00',
      createdDate: '2018-08-13T00:30:31.497247+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-13T00:30:31.497247+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-13T00:30:31.497247+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-13T10:52:11.030868+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-08-14T00:43:17.515524+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:23:59.445573+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:03.59605+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:03.88407+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-11-20T22:08:04.169016+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:43.505264+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:43.814464+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T20:05:59.049967+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T20:05:59.33823+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T13:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T17:00:34.672685+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T13:49:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T17:00:34.672685+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:54.975474+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T09:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T10:19:28.779751+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-25T07:00:00+00:00',
      departure: '2019-01-26T22:36:00+00:00',
      createdDate: '2018-11-20T22:08:04.792071+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USORF', name: 'Norfolk' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:04.792071+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:04.792071+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T20:05:59.942208+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T20:06:00.234326+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T17:54:58.966765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T17:54:58.966765+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:54.983462+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:54.983462+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:35.981632+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T05:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:35.981632+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.103568+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T20:01:16.487848+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T22:08:21.25265+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T22:08:21.25265+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T07:37:59.08311+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T17:58:55.352803+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T22:36:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-26T23:04:30.93522+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-25T19:00:00+00:00',
      departure: '2019-01-26T09:00:00+00:00',
      createdDate: '2018-09-18T14:56:37.073279+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:37.073279+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:37.073279+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-01T03:12:11.275349+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-01T03:12:11.564666+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-05T14:30:51.690793+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-05T14:30:51.97899+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:11.573396+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:11.887562+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-02T11:11:48.896644+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-02T11:11:49.207065+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-03T19:03:00.787319+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-03T19:03:01.064655+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:34.700593+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-29T05:30:00+00:00',
      departure: '2019-01-30T00:30:00+00:00',
      createdDate: '2018-09-18T14:56:37.627755+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:37.627755+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:37.627755+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-01T03:12:11.856188+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-01T03:12:12.146441+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-05T14:30:52.273449+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-05T14:30:52.566805+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:12.49554+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:12.861037+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-02T11:11:51.499553+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-02T11:11:51.8047+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:35.35007+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-30T05:30:00+00:00',
      departure: '2019-01-31T16:30:00+00:00',
      createdDate: '2018-09-18T14:56:38.186389+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:38.186389+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:38.186389+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-01T03:12:12.436934+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-01T03:12:12.726959+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-05T14:30:52.858843+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-05T14:30:53.14601+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:13.437296+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:13.722538+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-02T11:11:54.015292+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-02T11:11:54.323709+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:35.944096+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-31T22:00:00+00:00',
      departure: '2019-02-02T02:30:00+00:00',
      createdDate: '2018-11-20T22:08:05.698742+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USTSA', name: 'Savannah' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:05.698742+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T06:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:05.698742+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:44.494898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T20:06:00.867788+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T20:06:01.178733+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T06:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.624713+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.624713+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.645613+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.645613+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T22:08:21.956978+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.024944+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.024944+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T12:39:52.259219+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T20:29:55.299056+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T21:01:52.927354+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T21:01:52.927354+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T21:53:02.289713+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T22:08:48.245923+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-02T02:04:04.749868+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-03T01:06:00+00:00',
      departure: '2019-02-04T00:54:00+00:00',
      createdDate: '2018-11-20T22:08:06.593323+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USC84', name: 'Charleston' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:06.593323+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:06.593323+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:45.175167+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:45.48285+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T20:06:01.839608+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T20:06:02.134709+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.638465+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.638465+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.657341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.657341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T22:08:21.96771+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T22:08:21.96771+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.052381+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.052381+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T21:01:52.955849+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T21:01:52.955849+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T22:08:48.271083+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T22:08:48.271083+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T01:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-02T23:30:10.578038+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T00:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-03T23:24:21.146541+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-03T12:00:00+00:00',
      departure: '2019-02-04T17:00:00+00:00',
      createdDate: '2018-11-15T14:58:49.150217+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USORF', name: 'Norfolk' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:49.150217+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:49.150217+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:08.362081+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-05T15:36:00+00:00',
      departure: '2019-02-08T04:54:00+00:00',
      createdDate: '2018-11-15T14:58:48.287047+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:48.287047+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:48.287047+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:07.477653+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:07.764117+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-23T07:35:34.145557+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:46.126952+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:46.440072+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T20:06:02.819555+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T20:06:03.12147+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.646599+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.646599+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.667672+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.667672+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T16:48:48.708989+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T16:48:48.708989+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.059387+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.059387+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T21:01:52.970907+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T21:01:52.970907+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T22:08:48.281469+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T22:08:48.281469+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T15:19:18.469411+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T15:19:18.469411+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T15:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-05T16:22:39.653003+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T04:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T05:23:06.504643+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-05T17:00:00+00:00',
      departure: '2019-02-06T11:30:00+00:00',
      createdDate: '2018-09-18T14:56:39.078822+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:39.078822+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:39.078822+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-01T03:12:13.626233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-01T03:12:13.922771+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-05T14:30:54.026817+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-05T14:30:54.319098+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:15.37289+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:15.683652+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-02T11:11:59.056813+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-06T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-02T11:11:59.362152+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:37.152737+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-07T14:00:00+00:00',
      departure: '2019-02-08T06:01:00+00:00',
      createdDate: '2018-11-15T14:58:50.068541+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USTSA', name: 'Savannah' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T06:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:50.068541+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:50.068541+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:08.98253+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-09T00:00:00+00:00',
      departure: '2019-02-09T15:00:00+00:00',
      createdDate: '2018-11-15T14:58:51.048927+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USC84', name: 'Charleston' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:51.048927+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:51.048927+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:09.5801+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-10T21:00:00+00:00',
      departure: '2019-02-11T21:00:00+00:00',
      createdDate: '2018-08-13T00:30:32.045468+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-13T00:30:32.045468+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-13T00:30:32.045468+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-13T10:52:11.319179+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-08-14T00:43:17.783556+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:23:59.732493+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-11T11:00:00+00:00',
      departure: '2019-02-12T10:00:00+00:00',
      createdDate: '2018-11-15T14:58:51.961188+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:51.961188+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:51.961188+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:10.176363+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-15T19:00:00+00:00',
      departure: '2019-02-16T09:00:00+00:00',
      createdDate: '2018-08-13T00:30:32.589181+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-13T00:30:32.589181+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-13T00:30:32.589181+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-13T10:52:11.608135+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-08-14T00:43:18.055459+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:24:00.018184+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-17T08:00:00+00:00',
      departure: '2019-02-18T03:00:00+00:00',
      createdDate: '2018-08-13T00:30:33.139264+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-13T00:30:33.139264+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-13T00:30:33.139264+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-13T10:52:11.89759+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-08-14T00:43:18.326746+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:24:00.304246+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-18T11:00:00+00:00',
      departure: '2019-02-19T11:00:00+00:00',
      createdDate: '2018-08-13T00:30:33.682383+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-08-13T00:30:33.682383+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-08-13T00:30:33.682383+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-13T10:52:12.184908+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-08-14T00:43:18.598037+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-08-31T13:24:00.590038+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-28T11:00:00+00:00',
      departure: '2019-03-02T04:00:00+00:00',
      createdDate: '2018-09-18T14:56:39.631711+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:39.631711+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:39.631711+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:16.267457+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:16.553804+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:37.813728+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-03T12:00:00+00:00',
      departure: '2019-03-04T17:00:00+00:00',
      createdDate: '2018-09-18T14:56:40.180157+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USORF', name: 'Norfolk' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:40.180157+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:40.180157+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:17.140454+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:17.435829+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:38.404033+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-04T00:15:00+00:00',
      departure: '2019-03-05T03:30:00+00:00',
      createdDate: '2018-11-20T22:08:10.771667+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:10.771667+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:10.771667+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:47.386569+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:47.696864+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.654429+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:55.654429+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.213902+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.213902+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T10:19:28.801751+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T10:19:28.801751+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T16:00:32.534845+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T16:00:32.534845+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.675874+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:36.675874+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:52:52.351174+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:52:52.351174+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:24:26.038895+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:24:26.038895+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:56:21.971773+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:56:21.971773+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T16:48:49.399049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T16:48:49.399049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.244295+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.244295+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.777783+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.777783+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T22:08:21.975486+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T22:08:21.975486+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T06:03:19.755802+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T06:03:19.755802+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T07:37:59.748052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T07:37:59.748052+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T09:12:13.748394+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T09:12:13.748394+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.066983+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T17:58:56.066983+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:12:51.684602+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:12:51.684602+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T21:53:02.317758+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T21:53:02.317758+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T09:49:27.411543+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T09:49:27.411543+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:06:08.709856+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:06:08.709856+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T04:07:15.591571+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T04:07:15.591571+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T13:27:52.921535+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-20T13:27:52.921535+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T08:33:33.938889+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T08:33:33.938889+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T07:20:42.985336+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T07:20:42.985336+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T23:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-03T23:48:50.915811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T00:15:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T01:02:24.85915+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T01:02:24.85915+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-05T04:15:06.409373+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-07T14:00:00+00:00',
      departure: '2019-03-08T06:01:00+00:00',
      createdDate: '2018-09-18T14:56:40.73074+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USTSA', name: 'Savannah' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:40.73074+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T06:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:40.73074+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:18.00738+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T06:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:18.294886+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:39.030895+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-08T19:18:00+00:00',
      departure: '2019-03-09T09:24:00+00:00',
      createdDate: '2018-11-20T22:08:11.658375+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:11.658375+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:11.658375+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:48.355027+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:48.674825+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.277262+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.277262+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.898046+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.898046+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.465055+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.465055+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.21746+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.21746+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.322717+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.322717+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.020725+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.020725+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:24:27.231004+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:24:27.231004+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.639786+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.639786+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.075308+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.075308+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.934276+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.934276+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.78855+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.78855+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.235357+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.235357+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-08T12:58:14.842539+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-08T12:58:14.842539+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T19:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-08T19:39:30.619496+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T09:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-09T10:05:30.038533+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-09T00:00:00+00:00',
      departure: '2019-03-09T15:00:00+00:00',
      createdDate: '2018-09-18T14:56:41.27997+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USC84', name: 'Charleston' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:41.27997+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:41.27997+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:18.922528+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:19.209894+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:39.650941+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-10T17:12:00+00:00',
      departure: '2019-03-11T08:24:00+00:00',
      createdDate: '2018-11-20T22:08:12.532305+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:12.532305+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:12.532305+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:50.452711+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:50.762862+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.289224+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.289224+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.910014+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.910014+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.48917+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.48917+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.241332+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.241332+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.335042+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.335042+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.048947+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.048947+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:24:27.840439+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:24:27.840439+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.651131+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.651131+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.103268+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.103268+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.96471+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.96471+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.794522+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.794522+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.904149+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.904149+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T17:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-10T17:44:46.249374+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T01:16:58.064473+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T08:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:04:16.499052+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-11T10:00:00+00:00',
      departure: '2019-03-12T09:00:00+00:00',
      createdDate: '2018-09-18T14:56:41.823206+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:41.823206+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:41.823206+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:19.786001+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:20.074243+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:40.249897+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-11T19:54:00+00:00',
      departure: '2019-03-12T20:42:00+00:00',
      createdDate: '2018-11-20T22:08:13.399901+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:13.399901+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:13.399901+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:51.603293+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:51.91199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.296409+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.296409+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.92528+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.92528+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T07:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.505169+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.505169+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.257293+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.257293+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.342018+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.342018+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.069882+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.069882+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:24:28.376419+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:24:28.376419+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.666244+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.666244+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.12311+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.12311+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.976478+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.976478+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.800694+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.800694+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.914327+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.914327+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T01:38:25.738334+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T01:38:25.738334+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T19:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T20:05:42.996592+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T20:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T21:06:28.306223+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-15T09:24:00+00:00',
      departure: '2019-03-16T20:00:00+00:00',
      createdDate: '2018-11-20T22:08:14.307881+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'VNVUT', name: 'Vung Tau' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:14.307881+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:14.307881+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T16:33:52.620998+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T16:33:52.924224+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.303296+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.303296+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.943802+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.943802+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.52502+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.52502+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.281626+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.281626+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.349221+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.349221+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.094197+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.094197+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:24:28.907185+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:24:28.907185+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.675386+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.675386+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.141233+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.141233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.999401+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T07:54:27.999401+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.806471+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.806471+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.921057+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.921057+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T01:38:26.318895+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T01:38:26.318895+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T08:30:34.414813+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T08:30:34.414813+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:04:16.508691+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T09:04:16.508691+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T20:05:43.002314+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T20:05:43.002314+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T21:06:28.312704+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T21:06:28.312704+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T09:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-16T20:39:45.529821+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-16T20:39:45.529821+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-15T19:00:00+00:00',
      departure: '2019-03-16T09:00:00+00:00',
      createdDate: '2018-11-15T14:58:53.744072+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:53.744072+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:53.744072+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:15.948055+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-17T08:00:00+00:00',
      departure: '2019-03-18T03:00:00+00:00',
      createdDate: '2018-11-15T14:58:54.668674+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:54.668674+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:54.668674+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:16.569918+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-18T07:30:00+00:00',
      departure: '2019-03-19T02:12:00+00:00',
      createdDate: '2018-11-15T14:58:52.858745+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:52.858745+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:52.858745+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:15.040032+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:15.327921+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:10.136741+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T17:40:50.948567+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T17:40:51.259656+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-11-27T17:40:51.563932+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.31174+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T19:36:56.31174+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.951036+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T09:16:09.951036+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.539893+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T10:19:29.539893+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.293567+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T16:00:33.293567+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.357564+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T21:22:37.357564+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.113491+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T06:52:53.113491+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:24:29.440162+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:24:29.440162+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.695226+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:56:22.695226+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.169505+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-23T16:48:50.169505+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T07:54:28.014763+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T07:54:28.014763+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.812792+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T14:08:11.812792+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.928853+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.928853+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T10:28:42.153721+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T10:28:42.153721+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-03T23:48:51.865101+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-03T23:48:51.865101+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T01:38:26.87728+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T01:38:26.87728+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T08:30:34.427868+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T08:30:34.427868+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T09:04:16.522982+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T09:04:16.522982+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T20:05:43.00961+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T20:05:43.00961+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-12T21:06:28.319337+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-12T21:06:28.319337+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T06:45:28.093683+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T06:45:28.093683+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T02:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T02:35:08.560001+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-18T11:00:00+00:00',
      departure: '2019-03-19T11:00:00+00:00',
      createdDate: '2018-11-15T14:58:55.52709+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:55.52709+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:55.52709+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:17.158848+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-24T10:00:00+00:00',
      departure: '2019-03-25T08:00:00+00:00',
      createdDate: '2018-11-15T14:58:57.07385+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:57.07385+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:57.07385+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:18.363042+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-07T21:00:00+00:00',
      departure: '2019-04-08T21:00:00+00:00',
      createdDate: '2018-09-18T14:56:42.377074+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:42.377074+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:42.377074+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:20.651096+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:20.943555+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:40.866845+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-12T14:54:00+00:00',
      departure: '2019-04-13T21:12:00+00:00',
      createdDate: '2018-11-20T22:08:18.979265+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:18.979265+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:18.979265+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:10.75827+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T17:40:52.180384+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T17:40:52.48391+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-11-27T17:40:52.787286+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.935837+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.935837+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T19:50:58.984699+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T19:50:58.984699+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-31T04:44:12.534331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-31T04:44:12.534331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-05T20:41:44.908834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-05T20:41:44.908834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:25:04.737296+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T15:13:08.860323+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T14:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T15:13:08.860323+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T20:02:32.696634+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T21:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-13T21:31:03.75919+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-12T19:00:00+00:00',
      departure: '2019-04-13T09:00:00+00:00',
      createdDate: '2018-09-18T14:56:42.928448+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-05T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:42.928448+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-06T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:42.928448+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:21.5703+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:21.860282+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:41.445209+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-14T08:00:00+00:00',
      departure: '2019-04-15T03:00:00+00:00',
      createdDate: '2018-09-18T14:56:43.474583+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'HKHKG', name: 'Hong Kong' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:43.474583+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:43.474583+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:22.439466+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:22.727104+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:42.040126+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-14T17:48:00+00:00',
      departure: '2019-04-16T04:48:00+00:00',
      createdDate: '2018-11-20T22:08:19.844898+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USORF', name: 'Norfolk' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:19.844898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:19.844898+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:11.416285+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-11-27T17:40:53.397244+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.942212+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:16.942212+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T19:50:58.993094+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T19:50:58.993094+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-31T04:44:12.545478+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-31T04:44:12.545478+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-05T20:41:45.308696+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-05T20:41:45.308696+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T20:25:04.747383+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:25:04.747383+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T15:13:08.86753+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T15:13:08.86753+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T20:02:32.706545+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T20:02:32.706545+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T17:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-14T18:31:31.421428+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T07:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T16:07:26.807525+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T04:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T05:24:28.039932+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-15T11:00:00+00:00',
      departure: '2019-04-16T11:00:00+00:00',
      createdDate: '2018-09-18T14:56:44.0315+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-08T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-18T14:56:44.0315+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-09T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-18T14:56:44.0315+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-15T15:30:23.33161+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-15T15:30:23.627425+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-15T14:58:42.674016+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-20T16:18:00+00:00',
      departure: '2019-04-21T17:30:00+00:00',
      createdDate: '2018-11-20T22:08:20.745896+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USTSA', name: 'Savannah' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:20.745896+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T05:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:20.745896+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:12.095542+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T17:40:54.012889+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-11-27T17:40:54.316336+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T05:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.592168+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.592168+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T19:50:59.945574+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T19:50:59.945574+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-05T20:41:46.743106+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-05T20:41:46.743106+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:25:05.807375+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T20:25:05.807375+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:56:42.645439+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T20:56:42.645439+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T15:13:09.980972+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T15:13:09.980972+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T20:02:33.812107+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T20:02:33.812107+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-13T21:31:03.772125+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-13T21:31:03.772125+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T16:07:27.935358+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T16:07:27.935358+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T05:24:29.548134+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T05:24:29.548134+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T20:20:14.301599+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T20:20:14.301599+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.321222+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.321222+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T20:10:46.270023+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-19T20:10:46.270023+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T16:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.04947+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.04947+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-21T16:52:29.154662+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-21T11:00:00+00:00',
      departure: '2019-04-22T16:00:00+00:00',
      createdDate: '2018-11-15T14:58:58.851513+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USORF', name: 'Norfolk' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-22T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:58.851513+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-21T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:58.851513+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:23.423555+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-22T12:54:00+00:00',
      departure: '2019-04-23T12:00:00+00:00',
      createdDate: '2018-11-20T22:08:21.614132+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USC84', name: 'Charleston' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:21.614132+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:21.614132+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:12.79429+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-27T17:40:54.929007+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-27T17:40:55.236159+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-11-27T17:40:55.539424+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.603791+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.603791+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T19:50:59.958896+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T19:50:59.958896+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:25:05.835481+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T20:25:05.835481+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:56:43.070059+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T20:56:43.070059+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-21T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T15:13:10.013426+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-22T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T15:13:10.013426+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T20:02:33.863379+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T20:02:33.863379+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-13T21:31:04.924878+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-13T21:31:04.924878+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T16:07:27.999068+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T16:07:27.999068+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T05:24:29.609921+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T05:24:29.609921+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T20:20:14.333402+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T20:20:14.333402+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-23T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.37639+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.37639+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-21T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-19T20:10:46.305075+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-23T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.069409+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.069409+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T12:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-22T11:25:44.751906+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-23T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-23T10:44:44.965563+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-25T13:00:00+00:00',
      departure: '2019-04-26T05:01:00+00:00',
      createdDate: '2018-11-15T14:58:59.73159+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USTSA', name: 'Savannah' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-26T05:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:59.73159+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:59.73159+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:23.997099+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-25T20:06:00+00:00',
      departure: '2019-04-27T15:30:00+00:00',
      createdDate: '2018-11-15T14:58:57.987722+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-20T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:58:57.987722+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:58:57.987722+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:22.522669+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:22.807997+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:13.447136+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T13:50:27.987949+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T13:50:28.331322+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-06T13:50:28.65495+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.61019+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.61019+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T19:50:59.966682+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T19:50:59.966682+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-31T04:44:13.546389+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-31T04:44:13.546389+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-05T20:41:47.151477+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-05T20:41:47.151477+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:25:05.84458+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T20:25:05.84458+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T20:56:43.471407+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T20:56:43.471407+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T15:13:10.022915+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-25T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T15:13:10.022915+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-12T20:02:33.875889+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T20:02:33.875889+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-25T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-13T21:31:04.973354+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-13T21:31:04.973354+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T16:07:28.023755+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T16:07:28.023755+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-25T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T05:24:29.624252+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T05:24:29.624252+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-25T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T20:20:14.34576+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-23T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T20:20:14.34576+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-26T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.397224+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-24T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.397224+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-19T20:10:46.317135+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-19T20:10:46.317135+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-26T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.077163+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.077163+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-22T15:06:36.784585+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-22T15:06:36.784585+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T18:10:05.902795+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T18:10:05.902795+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T20:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T20:38:40.329931+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T16:08:37.907552+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-26T23:00:00+00:00',
      departure: '2019-04-27T14:00:00+00:00',
      createdDate: '2018-11-15T14:59:00.647321+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USC84', name: 'Charleston' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-27T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:59:00.647321+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-26T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:59:00.647321+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:24.592345+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-29T10:00:00+00:00',
      departure: '2019-04-30T09:00:00+00:00',
      createdDate: '2018-11-15T14:59:01.529404+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'USNYC', name: 'New York' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:59:01.529404+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:59:01.529404+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:25.188947+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-26T03:12:00+00:00',
      departure: '2019-05-27T01:06:00+00:00',
      createdDate: '2018-11-20T22:08:28.903743+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:28.903743+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:28.903743+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:14.086514+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T13:50:29.474976+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T13:50:29.78067+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-06T13:50:30.082797+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.616769+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-19T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:17.616769+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-22T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.427292+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-21T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-18T16:52:36.427292+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-21T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.084327+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-20T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-20T15:55:57.084327+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-22T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-22T15:06:36.798051+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-23T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-22T15:06:36.798051+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-21T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-23T10:44:44.974029+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-22T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-23T10:44:44.974029+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-24T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T18:10:05.911673+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-23T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T18:10:05.911673+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-22T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T20:38:40.34164+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-21T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T20:38:40.34164+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-25T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T11:00:37.362134+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T11:00:37.362134+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-26T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-02T09:51:56.202547+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-02T09:51:56.202547+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T08:28:40.233817+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-26T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T08:28:40.233817+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-24T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-12T04:12:16.8149+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-23T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-12T04:12:16.8149+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-24T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-12T13:17:44.203616+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-23T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-12T13:17:44.203616+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-26T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-16T08:40:51.239252+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T08:40:51.239252+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-23T03:47:55.622322+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-26T03:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-26T03:47:24.429104+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-27T01:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-27T01:30:39.016225+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-31T12:54:00+00:00',
      departure: '2019-06-01T07:06:00+00:00',
      createdDate: '2018-11-20T22:08:29.772742+00:00',
      isOmitted: false,
      service: 'East Coast Loop 4',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T22:08:29.772742+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T22:08:29.772742+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-27T15:19:14.761834+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T13:50:30.754396+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T13:50:31.044369+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-06T13:50:31.341536+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-25T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-24T15:13:18.259281+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-24T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T15:13:18.259281+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-31T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-11T14:02:28.078076+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-06-01T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-11T14:02:28.078076+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-06-01T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T01:28:53.186615+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-31T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-03T01:28:53.186615+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-06-01T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-27T06:00:20.351359+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-31T12:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-31T13:10:15.432834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-06-01T07:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-06-01T07:24:15.08225+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-31T19:00:00+00:00',
      departure: '2019-06-01T09:00:00+00:00',
      createdDate: '2018-11-15T14:59:03.350112+00:00',
      isOmitted: true,
      service: 'East Coast Loop 4',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-06-01T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-15T14:59:03.350112+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-31T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-15T14:59:03.350112+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-11-20T22:08:26.418852+00:00',
        },
      ],
    },
  ],
}
export default vessel
