import { connect, ConnectedProps } from 'react-redux'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { RootState } from '../../interfaces/store/reducers'
import Table from '../portCall/Table'

const mapStateToProps = (state: RootState, ownProps: { match: { params: { id: string } } }) => {
  const {
    match: {
      params: { id },
    },
  } = ownProps
  const portId = id.split('-')[0]

  return {
    portCall: state?.ports?.map?.[portId]?.portCalls?.find?.(({ id: pcid }) => pcid === id),
  }
}
const connector = connect(mapStateToProps)

type PortCallProps = ConnectedProps<typeof connector>

const PortCall: FC<PortCallProps> = ({ portCall }) => {
  if (!portCall) return null
  const {
    service,
    vessel: { name: vesselName, imo },
    port: { name: portName, id: portId },
  } = portCall
  return (
    <>
      <Helmet>
        <title>{`Portcall: ${vesselName} -> ${portName}`}</title>
      </Helmet>
      <h1 className="f4 bold center">
        <Link
          className="link dim dib ph2"
          to={{
            pathname: `/vessels/${imo}`,
          }}
        >
          {vesselName}
        </Link>
        {' >>> '}
        <Link
          className="link dim dib ph2"
          to={{
            pathname: `/ports/${portId}`,
          }}
        >
          {portName}
        </Link>
      </h1>
      <h1 className="f4 bold center">{service}</h1>
      <Table portCall={portCall} />
    </>
  )
}

export default connector(PortCall)
