import { connect, ConnectedProps } from 'react-redux'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { RootState } from '../../interfaces/store/reducers'
import Table from '../vessels/Table'

const mapStateToProps = (state: RootState) => ({
  vesselList: state.vessels.list,
})

const connector = connect(mapStateToProps)

type VesselsProps = ConnectedProps<typeof connector>

const Vessels: FC<VesselsProps> = ({ vesselList }) => (
  <>
    <Helmet>
      <title>Vessel list</title>
    </Helmet>
    <h1 className="f4 bold center">Vessel List</h1>
    <Table vesselList={vesselList} />
  </>
)

export default connector(Vessels)
