import { mergeDeepRight, values } from 'ramda'
import { PortsActions } from '../actions'
import { PortsState } from '../../interfaces/store/reducers'
import { PortsTypes } from '../../interfaces/store/actions'

const initialState: PortsState = {
  list: [],
  map: {},
}

export default (state = initialState, action: PortsActions): PortsState => {
  switch (action.type) {
    case PortsTypes.UPDATE: {
      return mergeDeepRight(state, {
        map: action.payload,
        list: values(action.payload)
          .map(({ name, id, portCalls }) => {
            const arrivalsAmount = portCalls.filter(({ isOmitted }) => !isOmitted).length
            const portCallsAmount = portCalls.length
            const omittedAmount = portCallsAmount - arrivalsAmount
            return {
              arrivalsAmount,
              id,
              name,
              omittedAmount,
              portCallsAmount,
            }
          })
          .sort((a, b) => (a.name < b.name ? -1 : 1)),
      }) as PortsState
    }
    default:
      return state
  }
}
