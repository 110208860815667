import { connect, ConnectedProps } from 'react-redux'
import React, { FC } from 'react'
import Graph from '../vessel/Graph'
import { Helmet } from 'react-helmet'
import { RootState } from '../../interfaces/store/reducers'
import Table from '../vessel/Table'

const mapStateToProps = (state: RootState, ownProps: { match: { params: { imo: number } } }) => {
  const {
    match: {
      params: { imo },
    },
  } = ownProps
  return {
    vessel: state?.vessels?.map?.[imo],
  }
}
const connector = connect(mapStateToProps)

type VesselProps = ConnectedProps<typeof connector>

const Vessel: FC<VesselProps> = ({ vessel }) => {
  if (!vessel) return null
  const {
    vessel: { name },
  } = vessel
  return (
    <>
      <Helmet>
        <title>{`Vessel: ${name}`}</title>
      </Helmet>
      <h1 className="f4 bold center">{`${name}`}</h1>
      <h3 className="f4 bold center">Average portcall delay</h3>
      <Graph vessel={vessel} />
      <Table vessel={vessel} />
    </>
  )
}

export default connector(Vessel)
