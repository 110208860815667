import { combineReducers } from 'redux'
import ports from './ports'
import vessels from './vessels'

const rootReducer = combineReducers({
  ports,
  vessels,
})

export default rootReducer
