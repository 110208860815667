import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './reducers'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default preloadedState => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({ immutableCheck: false, serializeCheck: false }),
    preloadedState: preloadedState,
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}
