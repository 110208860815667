import { ActionsUnion, VesselsTypes } from '../../interfaces/store/actions'
import { Vessel, VesselList } from '../../interfaces/vessels'
import { createAction } from './'

export const VesselsActions = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateVesselList: (vesselList: VesselList) => createAction(VesselsTypes.UPDATE_LIST, vesselList),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateVessels: (vessels: Array<Vessel>) => createAction(VesselsTypes.UPDATE, vessels),
}

export type VesselsActions = ActionsUnion<typeof VesselsActions>
