import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9388340, name: 'ONE COSMOS' },
  portCalls: [
    {
      arrival: '2018-12-31T21:00:00+00:00',
      departure: '2019-01-01T16:01:00+00:00',
      createdDate: '2018-09-06T00:39:31.680877+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-06T00:39:31.680877+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-06T00:39:31.680877+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-10-25T18:33:35.313627+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-03T15:00:00+00:00',
      departure: '2019-01-04T08:00:00+00:00',
      createdDate: '2018-09-06T00:39:32.264722+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'THLCH', name: 'Laem Chabang' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-06T00:39:32.264722+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-06T00:39:32.264722+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-10-25T18:33:35.589527+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-05T09:54:00+00:00',
      departure: '2019-01-05T18:55:00+00:00',
      createdDate: '2018-10-25T19:24:29.069964+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-30T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:29.069964+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-29T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:29.069964+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-01T22:27:00.770093+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-01T22:27:01.056708+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T11:59:29.663335+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T11:59:29.939373+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T14:24:27.975045+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T14:24:28.289967+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T14:20:07.18892+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T14:20:07.48038+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-17T15:58:23.699822+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-17T15:58:23.991865+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T08:17:51.080753+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T08:17:51.366406+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T19:04:13.065227+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T19:04:13.355876+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T20:23:31.946282+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T20:23:32.23624+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-22T03:37:44.773653+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-22T03:37:45.086009+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-02T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-23T22:29:40.438266+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-23T22:29:40.766731+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T05:30:00.595979+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T05:30:00.985188+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-02T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T10:32:13.913654+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T10:32:14.303419+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-25T23:52:57.798316+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-25T23:52:58.105929+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-26T09:34:10.442228+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-26T09:34:10.791013+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T04:54:48.932366+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T04:54:49.281721+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T09:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-05T10:24:24.903194+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T19:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-05T19:19:28.43775+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T09:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-07T00:33:37.594775+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T18:55:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T00:33:37.944087+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-06T22:36:00+00:00',
      departure: '2019-01-07T09:03:00+00:00',
      createdDate: '2018-10-25T19:24:29.680176+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:29.680176+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-31T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:29.680176+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-01T22:27:02.977866+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-01T22:27:03.267319+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T11:59:30.529986+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T11:59:30.801187+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T14:24:29.811342+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T15:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T14:24:30.125074+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-02T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T14:20:08.499278+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T14:20:08.797624+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-02T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-17T15:58:24.618824+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-17T15:58:24.920852+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T08:17:51.990572+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T08:17:52.276015+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T19:04:13.988835+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T19:04:14.276318+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-02T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T20:23:32.849537+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T20:23:33.137426+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-22T03:37:45.728789+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-22T03:37:46.038063+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-23T22:29:41.379414+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-23T22:29:41.707912+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T05:30:01.804103+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T05:30:02.172924+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T10:32:14.960737+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T10:32:15.309769+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-25T23:52:59.141432+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-25T23:52:59.461007+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-26T09:34:11.488349+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-26T09:34:11.837799+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T04:54:49.98018+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T04:54:50.329295+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T22:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T23:08:26.379326+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T09:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T10:14:06.468782+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T09:03:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-07T23:58:57.928834+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-09T03:00:00+00:00',
      departure: '2019-01-09T13:00:00+00:00',
      createdDate: '2018-10-05T00:45:43.777979+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-05T00:45:43.777979+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-05T00:45:43.777979+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-10-25T18:33:36.137286+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-10T05:42:00+00:00',
      departure: '2019-01-10T17:06:00+00:00',
      createdDate: '2018-10-25T19:24:30.293883+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:30.293883+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:30.293883+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-01T22:27:05.336754+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-01T22:27:05.622803+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T11:59:31.432787+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T11:59:31.718159+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-06T14:24:30.888127+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-06T14:24:31.214841+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-16T14:20:09.529734+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-16T14:20:09.826317+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-17T15:58:25.590691+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-17T15:58:25.883486+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T08:17:52.870032+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T08:17:53.155612+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T19:04:14.914526+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T07:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T19:04:15.201887+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T20:23:33.733938+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T20:23:34.020526+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-22T03:37:46.650934+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-22T03:37:46.953849+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-23T22:29:42.385254+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-23T22:29:42.734101+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T05:30:02.910974+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T05:30:03.300018+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T10:32:15.967098+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-07T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T10:32:16.316177+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-25T23:53:00.299161+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-25T23:53:00.614935+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-26T09:34:12.535635+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-26T09:34:12.884717+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-27T01:50:07.757608+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-27T01:50:08.029287+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T05:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T06:20:48.57366+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T06:20:48.863815+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T17:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T17:58:34.273885+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-10T10:00:00+00:00',
      departure: '2019-01-11T07:00:00+00:00',
      createdDate: '2018-09-06T00:39:33.200085+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-09-06T00:39:33.200085+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-09-06T00:39:33.200085+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-10-25T18:33:36.408948+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-14T23:24:00+00:00',
      departure: '2019-01-15T22:30:00+00:00',
      createdDate: '2018-10-25T19:24:30.906066+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:30.906066+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:30.906066+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:40:53.517907+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:40:53.823206+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T16:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-26T10:20:25.127949+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-26T10:20:25.431477+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T08:21:57.510989+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T08:21:57.781428+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T23:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T00:15:29.59835+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T06:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T07:26:18.482927+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T01:58:18.213076+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-18T23:30:00+00:00',
      departure: '2019-01-20T14:24:00+00:00',
      createdDate: '2018-10-25T19:24:31.537279+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'THLCH', name: 'Laem Chabang' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:31.537279+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:31.537279+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:40:54.523964+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:40:54.831447+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T03:34:29.233065+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T03:34:29.60673+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T00:29:39.419789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T00:29:39.419789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T23:51:31.33708+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T14:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-20T16:46:17.185694+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-22T04:36:00+00:00',
      departure: '2019-01-23T12:00:00+00:00',
      createdDate: '2018-10-25T19:24:32.145463+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'VNVUT', name: 'Vung Tau' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:32.145463+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:32.145463+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:40:55.467208+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:40:55.775416+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T03:34:30.377905+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T03:34:30.787752+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T08:57:50.338241+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T08:57:50.338241+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T00:08:32.530309+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T00:08:32.530309+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T02:50:54.78494+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T04:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-22T05:12:27.952333+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T12:21:38.75012+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-23T13:00:00+00:00',
      departure: '2019-01-26T00:00:00+00:00',
      createdDate: '2018-10-05T00:45:44.348603+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'USTIW', name: 'Tacoma' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-05T00:45:44.348603+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-05T00:45:44.348603+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-10-25T18:33:36.681482+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-25T16:18:00+00:00',
      departure: '2019-01-26T11:00:00+00:00',
      createdDate: '2019-01-21T04:01:10.276025+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T04:01:10.276025+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T04:01:10.276025+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T14:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-24T03:20:52.33237+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T16:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T16:34:43.238623+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-26T11:38:01.740657+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-26T16:00:00+00:00',
      departure: '2019-01-28T16:00:00+00:00',
      createdDate: '2018-10-05T00:45:44.919158+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'CAVAN', name: 'Vancouver' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-05T00:45:44.919158+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-05T00:45:44.919158+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-10-25T18:33:36.958309+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-26T18:00:00+00:00',
      departure: '2019-01-27T16:01:00+00:00',
      createdDate: '2018-10-25T19:24:33.159422+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:33.159422+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:33.159422+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:40:57.327869+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:40:57.631089+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T03:34:32.777086+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T03:34:33.191261+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T02:40:40.65353+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T02:40:40.956164+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T08:57:50.355811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T02:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T08:57:50.355811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T00:08:32.540324+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T00:08:32.540324+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-21T04:01:10.287425+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-27T06:36:00+00:00',
      departure: '2019-01-27T17:18:00+00:00',
      createdDate: '2018-10-25T19:24:32.530356+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:32.530356+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:32.530356+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:40:56.384441+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:40:56.689932+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T03:34:31.648127+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-24T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T03:34:32.058205+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T11:00:13.975146+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-24T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T11:00:14.262627+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-17T00:29:39.440119+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T00:29:39.440119+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-19T03:08:09.22166+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-19T03:08:09.22166+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T01:48:59.690671+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T01:48:59.690671+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T04:01:10.281377+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T04:01:10.281377+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T06:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.930331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T17:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T17:40:23.990978+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-15T13:36:00+00:00',
      departure: '2019-02-18T12:12:00+00:00',
      createdDate: '2018-10-25T19:24:33.777929+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'USTIW', name: 'Tacoma' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:33.777929+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:33.777929+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:40:58.300194+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:40:58.60599+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T03:34:33.970331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T01:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T03:34:34.379885+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T21:24:14.485903+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T21:24:14.7903+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T22:09:34.702172+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T22:09:35.079328+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T14:03:49.014285+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T14:03:49.298674+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T17:01:57.267687+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T17:01:57.267687+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.74001+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.74001+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T18:50:27.788097+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T18:50:27.788097+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T21:58:27.226357+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T13:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.822102+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T12:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T12:40:15.07306+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-24T13:48:00+00:00',
      departure: '2019-02-27T09:42:00+00:00',
      createdDate: '2018-10-25T19:24:34.401913+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'CAVAN', name: 'Vancouver' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-25T19:24:34.401913+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-25T19:24:34.401913+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:40:59.270875+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:40:59.579723+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-02T03:34:35.097878+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-02T03:34:35.434788+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T21:24:15.459416+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T21:24:15.781757+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T22:09:35.756931+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T22:09:36.065434+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T15:52:36.083258+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T15:52:36.358059+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-15T14:03:49.95023+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T14:03:50.23913+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:23:03.961252+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:23:03.961252+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.75784+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.75784+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.246868+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.246868+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T13:52:03.021568+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T13:52:03.021568+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.829462+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.829462+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.556022+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.556022+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T13:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T14:20:45.820581+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-26T13:34:37.320001+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T09:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T10:11:39.615874+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-09T09:42:00+00:00',
      departure: '2019-03-09T18:50:00+00:00',
      createdDate: '2018-10-29T00:39:06.037209+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-16T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-29T00:39:06.037209+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-29T00:39:06.037209+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:41:00.214069+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:41:00.519311+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.93645+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.93645+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.774382+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.774382+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.256174+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.256174+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T10:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T03:25:24.326497+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T00:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T03:25:24.326497+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.837352+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.837352+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.565775+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.565775+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T14:20:45.827798+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T14:20:45.827798+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-25T08:58:58.162222+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-25T08:58:58.162222+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T09:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-09T10:03:38.467523+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T18:50:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-10T01:20:53.570356+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-10T22:30:00+00:00',
      departure: '2019-03-11T06:55:00+00:00',
      createdDate: '2018-10-29T00:39:06.613335+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-29T00:39:06.613335+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-29T00:39:06.613335+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:41:01.145836+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:41:01.454041+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.94254+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.94254+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T02:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.790499+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.790499+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.262469+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.262469+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.320615+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.320615+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.845094+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.845094+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.830327+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.576793+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.576793+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T14:20:45.834465+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T14:20:45.834465+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-25T08:58:58.169032+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-25T08:58:58.169032+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T10:11:39.624468+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T10:11:39.624468+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T05:59:44.989727+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T05:59:44.989727+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T22:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-10T23:04:22.212637+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T07:29:20.798964+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T06:55:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T23:42:19.17819+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T23:42:19.17819+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-13T03:00:00+00:00',
      departure: '2019-03-13T13:00:00+00:00',
      createdDate: '2018-12-03T00:22:54.374607+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T00:22:54.374607+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T00:22:54.374607+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-01-21T09:27:25.062355+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-14T03:24:00+00:00',
      departure: '2019-03-14T16:18:00+00:00',
      createdDate: '2018-10-29T00:39:07.187976+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-29T00:39:07.187976+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-29T00:39:07.187976+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:41:02.093487+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:41:02.402189+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.949736+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.949736+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.805489+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.805489+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.268702+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.268702+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.367631+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.367631+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.855733+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.855733+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.837753+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.837753+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.590688+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T11:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.590688+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T14:20:45.840778+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T14:20:45.840778+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-25T08:58:58.182157+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-25T08:58:58.182157+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T05:59:44.997958+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T05:59:44.997958+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T15:59:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T13:20:43.734518+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T13:20:43.734518+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-08T12:50:15.434793+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-08T12:50:15.434793+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-11T04:00:46.914295+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-11T04:00:46.914295+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T03:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-14T03:42:20.6577+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T16:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-14T16:49:03.500796+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-18T15:48:00+00:00',
      departure: '2019-03-19T12:54:00+00:00',
      createdDate: '2018-10-29T00:39:07.763163+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-29T00:39:07.763163+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-29T00:39:07.763163+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:41:03.047934+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:41:03.36156+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.956013+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.956013+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-13T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.823049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-12T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.823049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.275546+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.275546+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.377929+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.377929+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.863411+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T04:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.863411+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.84473+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.84473+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-17T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.602167+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-18T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.602167+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.796784+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.796784+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T08:22:47.69184+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T06:43:38.003038+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T15:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T16:07:17.132241+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T08:08:20.63288+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T12:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T13:19:57.333836+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-21T22:36:00+00:00',
      departure: '2019-03-23T13:18:00+00:00',
      createdDate: '2018-10-29T00:39:08.340175+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'THLCH', name: 'Laem Chabang' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-29T00:39:08.340175+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-29T00:39:08.340175+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:41:04.058132+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:41:04.361808+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.961979+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.961979+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.611832+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.611832+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-17T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.83485+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.83485+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.282175+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.282175+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.389834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.389834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.870827+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.870827+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.851621+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-20T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.851621+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-20T09:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.611359+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-21T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.611359+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.808185+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.808185+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.230509+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.230509+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T05:05:42.680629+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T05:05:42.680629+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T22:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-21T23:06:34.72259+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T13:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-23T13:43:21.264531+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-25T03:12:00+00:00',
      departure: '2019-03-25T17:18:00+00:00',
      createdDate: '2018-10-29T00:39:08.921247+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'VNVUT', name: 'Vung Tau' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-29T00:39:08.921247+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-29T00:39:08.921247+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:41:05.043159+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:41:05.359051+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-11T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.968083+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-12T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.968083+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.619016+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.619016+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.850613+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T13:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.850613+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.289159+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.289159+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.929811+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T13:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T03:25:26.929811+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.877886+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T04:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.877886+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-24T22:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.858538+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.858538+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-24T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.618244+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.618244+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-24T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.825335+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.825335+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.237389+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.237389+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T05:05:42.687424+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T05:05:42.687424+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T08:29:48.548457+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T08:29:48.548457+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T13:19:57.349435+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-19T13:19:57.349435+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-22T10:22:05.407856+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T03:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-22T10:22:05.407856+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T03:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-25T03:53:18.643532+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T03:53:18.643532+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T17:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-25T17:44:11.491961+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-27T12:00:00+00:00',
      departure: '2019-03-29T23:00:00+00:00',
      createdDate: '2018-12-03T00:22:55.285119+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'USTIW', name: 'Tacoma' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T00:22:55.285119+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T00:22:55.285119+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-12T13:52:03.052871+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-28T05:30:00+00:00',
      departure: '2019-03-28T19:48:00+00:00',
      createdDate: '2019-03-06T14:23:10.546846+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T14:23:10.546846+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T14:23:10.546846+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.263024+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.263024+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T05:50:37.140917+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T19:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T20:17:58.364475+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-29T05:30:00+00:00',
      departure: '2019-03-29T21:30:00+00:00',
      createdDate: '2018-10-29T00:39:09.289248+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-10-29T00:39:09.289248+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-10-29T00:39:09.289248+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-20T19:41:06.003994+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-20T19:41:06.313414+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.974434+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.974434+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.625881+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-14T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.625881+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.86086+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-21T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.86086+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-24T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.296177+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-25T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.296177+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T03:25:27.502996+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T03:25:27.502996+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.885678+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T14:13:13.885678+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T01:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.866367+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T05:06:31.866367+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T12:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.625482+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T08:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T22:47:52.625482+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.842975+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.842975+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T05:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.25102+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.25102+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-06T14:23:11.730324+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-29T17:36:00+00:00',
      departure: '2019-03-30T08:00:00+00:00',
      createdDate: '2019-01-21T09:27:25.07406+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T09:27:25.07406+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T09:27:25.07406+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.980036+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T06:52:53.980036+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.632774+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-16T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-30T04:40:13.632774+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-23T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.869165+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-01T15:48:28.869165+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-26T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.30264+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T16:38:44.30264+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-12T13:52:03.043493+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-27T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.833787+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.833787+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-02-23T21:24:06.833787+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-28T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.244303+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-27T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.244303+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T14:23:11.172212+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T14:23:11.172212+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.812388+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.812388+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T02:09:29.451339+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T02:09:29.451339+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T10:40:04.145944+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T10:40:04.145944+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-29T17:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T17:55:32.522942+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-30T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-30T08:25:13.547303+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-30T15:00:00+00:00',
      departure: '2019-04-01T15:00:00+00:00',
      createdDate: '2018-12-03T00:22:56.138936+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'CAVAN', name: 'Vancouver' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T00:22:56.138936+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T00:22:56.138936+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-12T13:52:03.06117+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-12T12:00:00+00:00',
      departure: '2019-04-15T11:30:00+00:00',
      createdDate: '2019-02-23T21:24:06.879172+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'USTIW', name: 'Tacoma' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-10T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.879172+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.879172+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T15:52:22.902743+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T15:52:22.902743+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.257233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.257233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T03:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T14:23:12.299285+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T14:23:12.299285+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.851939+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.851939+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-10T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-08T12:50:15.442705+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-08T12:50:15.442705+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T13:32:37.996077+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T13:32:37.996077+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-12T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-21T23:06:34.730009+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-10T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-21T23:06:34.730009+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-11T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T02:09:29.458698+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T02:09:29.458698+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.134218+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.134218+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T18:35:50.424392+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T18:35:50.424392+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-12T12:19:33.25845+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-15T11:53:07.207875+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-13T23:00:00+00:00',
      departure: '2019-04-14T10:00:00+00:00',
      createdDate: '2018-12-21T00:05:20.319786+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-14T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:05:20.319786+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:05:20.319786+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-12T13:52:03.073191+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-15T10:00:00+00:00',
      departure: '2019-04-15T23:00:00+00:00',
      createdDate: '2018-12-21T00:05:21.285282+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:05:21.285282+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:05:21.285282+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-12T13:52:03.078506+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-15T20:54:00+00:00',
      departure: '2019-04-17T23:54:00+00:00',
      createdDate: '2019-02-23T21:24:06.893789+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'CAVAN', name: 'Vancouver' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.893789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.893789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T15:52:22.910967+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T15:52:22.910967+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-24T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.264646+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-21T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T02:38:38.264646+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-06T14:23:12.840329+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T14:23:12.840329+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.867945+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T13:20:44.867945+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-08T12:50:15.451049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-08T12:50:15.451049+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-16T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T13:32:38.007991+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-14T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T13:32:38.007991+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-15T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-21T23:06:34.738279+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-13T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-21T23:06:34.738279+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-28T02:09:29.465238+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-28T02:09:29.465238+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.144824+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.144824+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T18:35:50.43092+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T18:35:50.43092+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-03T15:04:00.726114+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-03T15:04:00.726114+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-15T20:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-15T21:37:24.292272+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T16:49:41.978875+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T23:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-18T00:06:28.943563+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-18T15:00:00+00:00',
      departure: '2019-04-19T04:00:00+00:00',
      createdDate: '2018-12-21T00:05:22.228756+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:05:22.228756+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-18T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:05:22.228756+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-12T13:52:03.083341+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-22T21:00:00+00:00',
      departure: '2019-04-23T16:01:00+00:00',
      createdDate: '2018-12-21T00:05:23.149638+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-23T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:05:23.149638+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:05:23.149638+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-23T21:24:06.853545+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-25T15:00:00+00:00',
      departure: '2019-04-26T08:00:00+00:00',
      createdDate: '2018-12-21T00:05:24.050847+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'THLCH', name: 'Laem Chabang' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-26T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:05:24.050847+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-25T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:05:24.050847+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-23T21:24:06.860013+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-27T20:00:00+00:00',
      departure: '2019-04-28T04:00:00+00:00',
      createdDate: '2018-12-21T00:05:24.955139+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'VNVUT', name: 'Vung Tau' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:05:24.955139+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:05:24.955139+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-23T21:24:06.869072+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-27T23:00:00+00:00',
      departure: '2019-04-28T09:50:00+00:00',
      createdDate: '2019-02-23T21:24:06.919409+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'JPTYO', name: 'Tokyo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.919409+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.919409+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.153281+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-30T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.153281+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T16:49:41.992672+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T16:49:41.992672+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T22:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-27T23:05:57.427625+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-27T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T04:51:20.485061+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-28T09:50:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T04:51:20.485061+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-29T08:30:00+00:00',
      departure: '2019-04-29T16:18:00+00:00',
      createdDate: '2019-02-23T21:24:06.931868+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'JPUKB', name: 'Kobe' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.931868+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.931868+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.163569+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.163569+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T16:49:42.00074+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T16:49:42.00074+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T08:12:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-29T08:34:26.389909+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T16:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-29T17:22:08.552487+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-29T08:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T07:07:52.994809+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-29T16:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T07:07:52.994809+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-02T10:00:00+00:00',
      departure: '2019-05-03T07:00:00+00:00',
      createdDate: '2018-12-21T00:05:25.655333+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:05:25.655333+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:05:25.655333+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-23T21:24:06.949797+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-02T12:20:00+00:00',
      departure: '2019-05-03T01:00:00+00:00',
      createdDate: '2019-04-16T13:17:01.004294+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.004294+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.004294+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-25T02:10:07.803789+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-25T02:10:07.803789+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-27T23:05:57.435995+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-27T23:05:57.435995+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-02T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T09:29:11.85534+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T09:29:11.85534+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T12:20:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-03T01:24:46.219752+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T01:24:46.219752+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-02T15:00:00+00:00',
      departure: '2019-05-03T04:00:00+00:00',
      createdDate: '2019-01-27T00:11:21.4062+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'TWKHH', name: 'Kaohsiung' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-01T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T00:11:21.4062+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-01T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T00:11:21.4062+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.937395+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.937395+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-05T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.172063+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-04T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-01T13:49:43.172063+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-25T02:10:07.810561+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-30T09:29:11.867306+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-03T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T09:29:11.867306+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-02T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T09:29:11.867306+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-05-03T01:24:46.637531+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-06T14:45:00+00:00',
      departure: '2019-05-07T20:25:00+00:00',
      createdDate: '2019-02-23T21:24:06.981544+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T16:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.981544+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.981544+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.010762+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T09:49:58.033762+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-03T09:00:58.656909+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T09:00:58.656909+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-03T09:19:52.117552+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-03T09:19:52.117552+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-06T09:34:21.298002+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-06T09:34:21.298002+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T14:45:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T01:07:08.925325+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T20:25:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-08T09:37:47.643885+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-06T21:00:00+00:00',
      departure: '2019-05-07T19:00:00+00:00',
      createdDate: '2019-04-30T07:07:53.003768+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'SGSIN', name: 'Singapore' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T07:07:53.003768+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T07:07:53.003768+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T09:29:11.881286+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-06T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-30T09:29:11.881286+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-07T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-30T09:49:58.043574+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-05-08T09:37:47.652144+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-09T17:01:00+00:00',
      departure: '2019-05-10T10:00:00+00:00',
      createdDate: '2019-04-24T11:57:01.397692+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'THLCH', name: 'Laem Chabang' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-24T11:57:01.397692+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-24T11:57:01.397692+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.331743+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.331743+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:40:26.721801+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T17:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-10T04:41:40.354475+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T04:41:40.354475+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-05-13T02:53:48.467177+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-09T18:24:00+00:00',
      departure: '2019-05-10T13:00:00+00:00',
      createdDate: '2019-02-23T21:24:06.987364+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'THLCH', name: 'Laem Chabang' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.987364+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.987364+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-13T17:30:02.366348+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.020973+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T17:01:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.020973+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-16T13:17:01.020973+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T04:41:40.361683+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-10T04:41:40.361683+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-09T18:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.261482+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-10T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.261482+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-11T06:00:00+00:00',
      departure: '2019-05-12T06:00:00+00:00',
      createdDate: '2019-03-01T16:30:54.497169+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 1',
      port: { id: 'CNQIN', name: 'Qingdao' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-11T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.497169+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.497169+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-11T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-20T07:53:49.168529+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-20T07:53:49.168529+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-16T12:54:12.90614+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-12T02:00:00+00:00',
      departure: '2019-05-12T19:00:00+00:00',
      createdDate: '2019-02-23T21:24:06.996389+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'VNVUT', name: 'Vung Tau' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.996389+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-11T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:06.996389+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-13T17:30:02.37165+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.028746+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.028746+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-16T13:17:01.028746+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.339258+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.339258+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T07:40:26.72851+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:40:26.72851+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-05-13T02:53:48.473426+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-12T05:48:00+00:00',
      departure: '2019-05-12T20:15:00+00:00',
      createdDate: '2019-05-08T04:44:52.490684+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'VNVUT', name: 'Vung Tau' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-13T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-08T04:44:52.490684+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-08T04:44:52.490684+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-09T10:17:34.485832+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-10T03:32:15.859505+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.269921+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.269921+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-12T05:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T04:20:17.074593+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-12T20:15:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-14T04:20:17.074593+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-14T05:00:00+00:00',
      departure: '2019-05-15T17:00:00+00:00',
      createdDate: '2019-03-01T16:30:54.502827+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 1',
      port: { id: 'CNSGH', name: 'Shanghai' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.502827+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.502827+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-04T14:47:12.013103+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-16T12:54:13.348316+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-14T18:00:00+00:00',
      departure: '2019-05-15T07:00:00+00:00',
      createdDate: '2019-04-16T13:17:01.038477+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'VNHPH', name: 'Haiphong' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.038477+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.038477+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.345249+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.345249+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:40:26.734565+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T07:40:26.734565+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-05-13T02:53:48.48144+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-14T21:30:00+00:00',
      departure: '2019-05-16T06:00:00+00:00',
      createdDate: '2019-05-10T03:12:12.401417+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'VNHPH', name: 'Haiphong' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-10T03:12:12.401417+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-10T03:12:12.401417+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.279116+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.279116+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-15T17:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-14T09:38:47.669651+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T09:38:47.669651+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-14T21:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T23:37:24.957461+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T05:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T01:21:50.486706+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-16T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T07:27:32.759085+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-15T12:00:00+00:00',
      departure: '2019-05-17T23:00:00+00:00',
      createdDate: '2019-01-27T00:11:21.415839+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'USTIW', name: 'Tacoma' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-15T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T00:11:21.415839+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T00:11:21.415839+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-23T21:24:06.961289+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-16T06:00:00+00:00',
      departure: '2019-05-17T12:00:00+00:00',
      createdDate: '2019-03-01T16:30:54.507557+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 1',
      port: { id: 'CNNBO', name: 'Ningbo' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.507557+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.507557+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-16T12:54:13.822668+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-17T14:00:00+00:00',
      departure: '2019-05-18T05:48:00+00:00',
      createdDate: '2019-02-23T21:24:07.00614+00:00',
      isOmitted: false,
      service: 'Pacific North Loop 2',
      port: { id: 'CNYTN', name: 'Yantian Pt' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T21:24:07.00614+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T21:24:07.00614+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-16T00:32:48.778003+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.045396+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-04-16T13:17:01.045396+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-04-16T13:17:01.045396+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.350827+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T07:01:13.350827+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-07T07:40:26.740691+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-07T07:40:26.740691+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T02:53:48.488119+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T07:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T02:53:48.488119+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.288837+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T03:13:20.288837+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-13T06:25:02.545064+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-13T06:25:02.545064+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-14T04:20:17.082785+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-16T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T04:20:17.082785+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-14T09:38:47.677191+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T06:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-14T09:38:47.677191+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-15T01:23:44.511595+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-17T19:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-15T01:23:44.511595+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-16T01:21:50.501657+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T01:21:50.501657+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T07:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T04:15:07.24115+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-16T04:15:07.24115+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T14:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-16T07:27:32.767552+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-16T07:27:32.767552+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-17T08:08:26.285427+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-17T08:08:26.285427+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-05-17T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-05-17T14:34:11.028233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-17T14:34:11.028233+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-18T05:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-05-18T06:21:45.742534+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-18T15:00:00+00:00',
      departure: '2019-05-20T15:00:00+00:00',
      createdDate: '2019-01-27T00:11:21.420863+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 2',
      port: { id: 'CAVAN', name: 'Vancouver' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-27T00:11:21.420863+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-20T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T00:11:21.420863+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-23T21:24:06.972279+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-18T23:00:00+00:00',
      departure: '2019-05-19T21:00:00+00:00',
      createdDate: '2019-03-01T16:30:54.536337+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 1',
      port: { id: 'KRPUS', name: 'Busan' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-18T23:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.536337+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-19T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.536337+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-16T12:54:13.859304+00:00',
        },
      ],
    },
    {
      arrival: '2019-05-29T08:00:00+00:00',
      departure: '2019-05-30T01:00:00+00:00',
      createdDate: '2019-03-01T16:30:54.540877+00:00',
      isOmitted: true,
      service: 'Pacific North Loop 1',
      port: { id: 'CAPRR', name: 'Prince Rupert' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-05-29T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.540877+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-05-30T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T16:30:54.540877+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-16T13:17:01.053553+00:00',
        },
      ],
    },
  ],
}
export default vessel
