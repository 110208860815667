import React, { FC } from 'react'
import { Link } from 'react-router-dom'

const Navbar: FC = () => (
  <header className="bg-black-90 fixed w-100 ph3 pv3 pv4-ns ph4-m ph5-l z-1">
    <nav className="f6 fw6 ttu tracked white">
      <Link
        className="link dim dib mr3 ph2"
        to={{
          pathname: '/vessels',
        }}
      >
        Vessels
      </Link>
      <Link
        className="link dim dib mr3 ph2"
        to={{
          pathname: '/ports',
        }}
      >
        Ports
      </Link>
    </nav>
  </header>
)

export default Navbar
