import React, { FC } from 'react'
import { humanReadableDuration } from '../utils'
import { HydratedPortCall } from '../interfaces/vessels'
import { useHistory } from 'react-router-dom'

export const PortCallsTable: FC<{
  portCalls: Array<HydratedPortCall>
  viewFrom: 'vessel' | 'port'
}> = ({ portCalls, viewFrom }) => {
  const history = useHistory()
  const handleNavigateToPortCall = portCalls => () => history.push(`/portcalls/${portCalls.id}`)
  return (
    <>
      <h1>Port calls</h1>
      <div className="overflow-auto">
        <table className="f6 w-100 mw8 center" cellSpacing="0">
          <thead>
            <tr className="stripe-dark">
              <th className="fw6 tl pa3 bg-white tc">ID</th>
              <th className="fw6 tl pa3 bg-white tc">Arrival</th>
              <th className="fw6 tl pa3 bg-white tc">Departure</th>
              <th className="fw6 tl pa3 bg-white tc">Duration</th>
              <th className="fw6 tl pa3 bg-white tc">is omitted?</th>
              <th className="fw6 tl pa3 bg-white tc">{viewFrom === 'port' ? 'Vessel' : 'Port'}</th>
            </tr>
          </thead>
          <tbody className="lh-copy">
            {portCalls.map(v => (
              <tr key={v.id} className="stripe-dark pointer" onClick={handleNavigateToPortCall(v)}>
                <td className="pa3">{v.id}</td>
                <td className="pa3">{v.arrival}</td>
                <td className="pa3">{v.departure}</td>
                <td className="pa3">{humanReadableDuration(v.duration)}</td>
                <td className="pa3">{String(v.isOmitted)}</td>
                <td className="pa3">{viewFrom === 'port' ? v.vessel.name : v.port.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default PortCallsTable
