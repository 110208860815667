import { Vessel } from '../interfaces/vessels'
const vessel: Vessel = {
  vessel: { imo: 9314935, name: 'AS CAROLINA' },
  portCalls: [
    {
      arrival: '2018-12-30T11:18:00+00:00',
      departure: '2018-12-31T04:54:00+00:00',
      createdDate: '2018-11-30T10:32:03.459874+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'ITSAL', name: 'Salerno' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2018-12-29T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:03.459874+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-30T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:03.459874+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-29T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-12T15:16:05.335022+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-30T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-12T15:16:05.643974+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T13:41:43.464734+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-31T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T13:41:43.814461+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-30T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T11:56:50.438216+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-31T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T11:56:50.787221+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2018-12-30T11:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-30T11:23:11.742869+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2018-12-31T04:54:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-31T05:04:08.757287+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-01T15:00:00+00:00',
      departure: '2019-01-02T18:00:00+00:00',
      createdDate: '2018-11-30T10:32:04.90451+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITCAG', name: 'Cagliari' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2018-12-31T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:04.90451+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-01T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:04.90451+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T13:41:44.491397+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T13:41:44.840204+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T11:56:51.48503+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T11:56:51.793635+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-28T13:03:37.90434+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-04T22:00:00+00:00',
      departure: '2019-01-05T17:55:00+00:00',
      createdDate: '2018-12-05T08:54:45.756886+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-01T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-05T08:54:45.756886+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-02T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-05T08:54:45.756886+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-12T15:16:06.31936+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-04T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-12T15:16:06.631864+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T13:41:45.493458+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T13:41:45.842365+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T11:56:52.413034+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T11:56:52.739306+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-02T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T13:03:38.578262+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-03T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T13:03:38.906799+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T10:07:18.318667+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T10:07:18.630299+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-03T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:15:23.185927+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:15:23.459328+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-04T11:12:23.879032+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-04T11:12:24.173214+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T21:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-04T22:28:28.315998+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T18:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-05T18:51:38.914742+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-04T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T17:31:41.747789+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-05T17:55:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T17:31:42.137295+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-09T18:48:00+00:00',
      departure: '2019-01-10T11:30:00+00:00',
      createdDate: '2018-11-30T10:32:05.96198+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'EGALY', name: 'Alexandria' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:05.96198+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:05.96198+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-12T15:16:07.315208+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-12T15:16:07.627996+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T18:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T13:41:46.522946+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-24T13:41:46.864199+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T11:56:53.396979+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T11:56:53.737801+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-05T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T13:03:39.607954+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-06T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T13:03:39.936759+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-07T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T11:11:37.420532+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T11:11:37.757713+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-08T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:15:24.08564+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-06T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:45:00.874049+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T08:50:38.021791+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-08T08:50:38.315267+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-09T09:06:36.141408+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T18:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T09:43:00.64941+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T09:43:00.953858+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T11:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T13:53:26.343287+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-11T07:05:00+00:00',
      departure: '2019-01-11T21:40:00+00:00',
      createdDate: '2018-11-30T10:32:07.066923+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'EGDAM', name: 'Dumyat (Damietta)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:07.066923+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:07.066923+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-24T13:41:47.52117+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T11:56:54.395149+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T11:56:54.744193+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T13:03:40.593906+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-28T13:03:40.92267+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T11:11:38.45473+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:15:24.683396+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:15:24.958155+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-08T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-06T10:26:32.520651+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-09T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-06T10:26:32.817825+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-09T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-08T08:50:38.950711+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T09:43:01.907115+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-10T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T09:43:02.210432+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T02:36:59.639264+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T02:36:59.915056+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T07:05:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:35.773047+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T21:40:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-12T05:14:32.796085+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-12T15:30:00+00:00',
      departure: '2019-01-15T00:30:00+00:00',
      createdDate: '2018-11-30T11:49:05.66514+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'LBBEY', name: 'Beirut' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-11T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T11:49:05.66514+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T11:49:05.66514+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-10T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-28T11:56:55.422216+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:15:25.548982+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-12T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:15:25.818937+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T09:43:02.877928+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T09:43:03.183736+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:36.681911+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:36.970592+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-11T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T10:38:44.490471+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T15:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T14:14:16.587359+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T22:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T14:14:16.910741+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-15T00:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-15T01:26:39.226813+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-15T16:30:00+00:00',
      departure: '2019-01-16T08:00:00+00:00',
      createdDate: '2018-11-30T10:32:08.06102+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'TRISK', name: 'Iskenderun' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:08.06102+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:08.06102+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-12T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:15:26.418549+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:15:26.693673+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T09:43:03.820249+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-13T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-10T09:43:04.122982+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:37.576707+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:37.865417+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T11:43:10.950533+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T11:43:11.241586+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T16:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T06:27:58.296102+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T06:27:58.598365+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-17T20:06:00+00:00',
      departure: '2019-01-18T10:18:00+00:00',
      createdDate: '2018-11-30T10:32:08.918736+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'TRMER', name: 'Mersin' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:08.918736+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:08.918736+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-13T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:15:27.321015+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-14T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:15:27.592091+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-14T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-10T09:43:04.794855+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-15T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:38.471128+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-16T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:38.759022+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T11:43:11.894711+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T11:43:12.188363+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T14:54:27.779042+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T14:54:27.779042+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T20:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-17T14:29:57.170688+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T20:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-18T05:13:17.483656+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T10:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-18T10:53:15.955246+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-20T18:54:00+00:00',
      departure: '2019-01-21T07:12:00+00:00',
      createdDate: '2018-11-30T10:32:09.932571+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'TRALI', name: 'Aliaga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-16T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:09.932571+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:09.932571+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-17T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T12:15:28.229358+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-17T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:39.421515+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-18T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:39.710567+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T11:43:12.789354+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T11:43:13.083638+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.505779+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.505779+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T18:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-20T17:12:11.517459+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T07:12:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T05:00:05.852704+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-22T14:10:00+00:00',
      departure: '2019-01-23T06:20:00+00:00',
      createdDate: '2018-11-30T10:32:10.868482+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-19T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-11-30T10:32:10.868482+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-11-30T10:32:10.868482+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-18T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-03T12:15:28.820333+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-19T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:40.301022+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-20T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:40.589448+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-20T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T10:38:45.134118+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-21T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T10:38:45.437447+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-21T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.513636+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-22T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.513636+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T11:17:54.392347+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T15:28:35.129112+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T06:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-23T07:35:29.010841+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T14:10:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T02:12:05.287717+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T06:20:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T02:12:05.287717+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-26T15:54:00+00:00',
      departure: '2019-01-27T13:48:00+00:00',
      createdDate: '2018-12-03T00:16:46.231547+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'ITGOA', name: 'Genova' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T00:16:46.231547+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T00:16:46.231547+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-22T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:41.195949+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-23T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:41.485257+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T10:38:46.078766+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-24T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T10:38:46.383942+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.538005+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.538005+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:40.674025+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:40.674025+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T15:54:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-26T16:32:48.403584+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T15:55:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-26T16:32:48.403584+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T13:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-27T17:14:41.44879+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-29T06:18:00+00:00',
      departure: '2019-01-31T08:54:00+00:00',
      createdDate: '2018-12-03T00:16:47.131452+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'ITLIV', name: 'Livorno' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-24T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T00:16:47.131452+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-23T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T00:16:47.131452+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:42.101727+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-24T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T10:38:47.003533+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-14T11:43:13.725222+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-26T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-14T11:43:14.014465+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.548334+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.548334+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T17:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:41.250982+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:41.250982+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T18:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T13:45:42.82807+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T13:45:42.82807+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.221166+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T06:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T06:43:16.999192+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T08:54:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.917481+00:00',
        },
      ],
    },
    {
      arrival: '2019-01-31T21:06:00+00:00',
      departure: '2019-02-01T15:00:00+00:00',
      createdDate: '2018-12-03T00:16:48.081445+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'ITCVV', name: 'Civitavecchia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-25T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T00:16:48.081445+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-25T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T00:16:48.081445+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:42.704474+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:42.992646+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.559501+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-16T15:26:45.559501+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-27T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:41.806881+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:41.806881+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-28T20:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T13:45:42.834246+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T19:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T13:45:42.834246+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.48525+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T09:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.48525+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.228012+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.228012+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.107976+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.107976+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:57:22.858744+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:57:22.858744+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T21:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T21:31:21.795675+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-02T05:18:00+00:00',
      departure: '2019-02-02T21:18:00+00:00',
      createdDate: '2018-12-03T00:16:49.025319+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'ITSAL', name: 'Salerno' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-27T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-03T00:16:49.025319+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-26T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-03T00:16:49.025319+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:43.581258+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:43.870029+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:42.354277+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:42.354277+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.49232+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.49232+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.236097+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.236097+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.115152+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.115152+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:57:23.405991+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:57:23.405991+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T05:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-02T05:00:15.987604+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T21:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-02T20:56:01.659789+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-03T17:24:00+00:00',
      departure: '2019-02-04T11:06:00+00:00',
      createdDate: '2018-12-13T14:00:25.249738+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'ITCAG', name: 'Cagliari' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-29T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-13T14:00:25.249738+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-28T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-13T14:00:25.249738+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-31T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:44.483713+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-02T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:44.77161+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-01-30T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:42.89109+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-31T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:42.89109+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-01T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.499277+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-01T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.499277+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.242251+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.242251+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.122258+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.122258+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:57:23.97209+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:57:23.97209+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-03T17:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-03T18:05:17.98+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-04T11:06:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T11:40:04.125789+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-08T14:00:00+00:00',
      departure: '2019-02-09T12:30:00+00:00',
      createdDate: '2018-12-09T00:01:29.335623+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-01-29T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-09T00:01:29.335623+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-01-30T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T00:01:29.335623+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:45.393442+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:45.681888+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:43.43712+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:43.43712+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.506184+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.506184+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.248365+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.248365+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T11:06:22.837524+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T22:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T11:06:22.837524+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.924925+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.924925+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-06T14:00:13.241683+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-06T14:00:13.241683+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.760905+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.760905+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T13:41:50.188666+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T13:41:50.188666+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T13:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T14:00:25.409757+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T12:42:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-09T16:04:36.826047+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T23:00:04.377544+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-09T12:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T23:00:04.377544+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-12T10:30:00+00:00',
      departure: '2019-02-12T23:00:00+00:00',
      createdDate: '2018-12-09T00:01:30.275124+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'EGALY', name: 'Alexandria' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-02T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-09T00:01:30.275124+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-03T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T00:01:30.275124+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:46.274121+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:46.837267+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-05T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:43.969875+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-04T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:43.969875+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.512871+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.512871+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.254777+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.254777+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.129675+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.129675+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.932051+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T10:54:46.098872+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T10:54:46.098872+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T10:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T12:23:32.073565+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-13T00:51:14.979645+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-13T19:42:00+00:00',
      departure: '2019-02-14T12:40:00+00:00',
      createdDate: '2018-12-09T00:01:31.14351+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'EGDAM', name: 'Dumyat (Damietta)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-05T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-09T00:01:31.14351+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T00:01:31.14351+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:47.449516+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:47.740066+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-06T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:44.508048+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-07T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:44.508048+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.519321+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-08T19:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.519321+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.260916+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.260916+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.136797+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.136797+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T10:54:46.112493+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T21:58:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T10:54:46.112493+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T08:35:51.024457+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-12T08:35:51.024457+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T19:42:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T05:01:23.77498+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T05:01:23.77498+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T12:40:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T13:09:57.200691+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-15T05:00:00+00:00',
      departure: '2019-02-16T21:00:00+00:00',
      createdDate: '2018-12-09T00:01:32.10164+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'LBBEY', name: 'Beirut' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-09T00:01:32.10164+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T00:01:32.10164+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-11T01:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:48.353391+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:48.643429+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-07T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:45.067213+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-08T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:45.067213+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.527126+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:16.527126+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.267138+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:43.267138+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.143483+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:11.143483+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T10:54:46.122849+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:52:04.993566+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:52:04.993566+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-12T08:35:51.031529+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T13:42:18.478472+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T13:42:18.478472+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-16T21:25:23.169518+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-16T22:00:00+00:00',
      departure: '2019-02-17T13:00:00+00:00',
      createdDate: '2018-12-09T00:01:32.993172+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRISK', name: 'Iskenderun' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T00:01:32.993172+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-09T00:01:32.993172+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:49.244029+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-12T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:49.532507+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-09T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:47.283544+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-10T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:47.283544+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-13T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.231371+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-12T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.231371+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.015203+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.015203+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.894781+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-14T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.894781+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-16T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.002716+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.002716+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-11T12:51:16.633964+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-17T18:00:00+00:00',
      departure: '2019-02-18T18:00:00+00:00',
      createdDate: '2018-12-09T00:01:33.654098+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRMER', name: 'Mersin' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-09T00:01:33.654098+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T00:01:33.654098+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:50.131243+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:50.419869+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-10T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:47.869495+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-11T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:47.869495+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.261091+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.261091+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.026542+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.026542+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.905765+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.905765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.010357+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T18:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.010357+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-11T12:51:16.639309+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-19T19:40:00+00:00',
      departure: '2019-02-20T17:40:00+00:00',
      createdDate: '2018-12-18T00:14:34.53479+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T00:14:34.53479+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T00:14:34.53479+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-16T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:51.923999+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:52.212839+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:54:07.119268+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-16T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:54:07.119268+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.275898+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.275898+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T14:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.040854+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.040854+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T22:01:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.919764+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.919764+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.946243+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.946243+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.024666+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.024666+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-13T09:37:24.716127+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-02-13T10:45:08.528735+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T14:14:56.775923+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T14:14:56.775923+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T08:59:06.286229+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T08:59:06.286229+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T16:18:18.123603+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T17:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-19T16:18:18.123603+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T19:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-19T19:42:57.784588+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T19:42:57.784588+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T07:37:26.534843+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T09:15:43.093834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T17:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-20T18:36:51.025955+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T19:40:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-24T14:49:53.870866+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T17:40:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T14:49:53.870866+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-20T03:00:00+00:00',
      departure: '2019-02-25T15:00:00+00:00',
      createdDate: '2019-02-11T12:51:16.651204+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T12:51:16.651204+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T12:51:16.651204+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-14T14:14:54.604155+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-14T14:14:54.604155+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-18T08:59:06.228306+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-20T11:00:00+00:00',
      departure: '2019-02-21T12:00:00+00:00',
      createdDate: '2018-12-09T00:01:34.534644+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRALI', name: 'Aliaga' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-09T00:01:34.534644+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-09T00:01:34.534644+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-15T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-11T08:50:51.045476+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-15T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:51.333527+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-13T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T13:22:48.424486+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-14T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T13:22:48.424486+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-16T02:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.268337+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-17T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.268337+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-18T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.034005+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-17T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-28T13:19:45.034005+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-19T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.912632+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-29T10:33:12.912632+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-18T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.938875+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.017662+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.017662+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-11T12:51:16.644495+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-23T11:24:00+00:00',
      departure: '2019-02-24T19:00:00+00:00',
      createdDate: '2019-02-08T10:00:51.700024+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'EGALY', name: 'Alexandria' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.700024+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.700024+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T03:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-18T08:59:06.368966+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-18T08:59:06.368966+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-19T16:18:18.200213+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-19T16:18:18.200213+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-21T09:14:51.213156+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.638331+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T16:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-23T18:10:45.147539+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T11:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-23T18:10:45.147539+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-24T23:15:19.308323+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-24T05:00:00+00:00',
      departure: '2019-02-24T19:00:00+00:00',
      createdDate: '2018-12-18T00:14:35.44656+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITGOA', name: 'Genova' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-20T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T00:14:35.44656+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-19T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T00:14:35.44656+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-21T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.283512+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.283512+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.953781+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T19:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.953781+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-08T10:00:51.694594+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-24T23:30:00+00:00',
      departure: '2019-02-25T09:00:00+00:00',
      createdDate: '2018-12-18T00:14:36.398659+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITLIV', name: 'Livorno' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-21T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T00:14:36.398659+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-20T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T00:14:36.398659+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.291368+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.291368+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T23:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.960606+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.960606+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T11:29:09.769693+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-25T14:00:00+00:00',
      departure: '2019-02-26T00:00:00+00:00',
      createdDate: '2018-12-18T00:14:37.365888+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITCVV', name: 'Civitavecchia' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-22T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T00:14:37.365888+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-22T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T00:14:37.365888+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.298839+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-23T23:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.298839+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T14:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.967496+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.967496+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T11:29:09.778431+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-25T19:40:00+00:00',
      departure: '2019-03-01T14:25:00+00:00',
      createdDate: '2019-02-08T10:00:51.704859+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'LBBEY', name: 'Beirut' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.704859+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.704859+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T09:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.033059+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T22:30:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.033059+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T12:51:16.656201+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T12:51:16.656201+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.660203+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.660203+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-25T08:01:44.186448+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-26T07:44:01.141218+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T08:57:05.660141+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T19:40:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T08:57:05.660141+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.821469+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.566485+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T21:30:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-01T08:09:35.990192+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T14:25:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T08:45:33.315802+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-26T07:00:00+00:00',
      departure: '2019-02-26T23:00:00+00:00',
      createdDate: '2018-12-18T00:14:38.276198+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITSAL', name: 'Salerno' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T00:14:38.276198+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-23T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T00:14:38.276198+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-24T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.306566+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-24T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T14:27:18.306566+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.974425+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.974425+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T11:29:09.786081+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-27T06:00:00+00:00',
      departure: '2019-02-27T21:00:00+00:00',
      createdDate: '2018-12-18T00:14:39.150143+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITCAG', name: 'Cagliari' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-25T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-18T00:14:39.150143+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-18T00:14:39.150143+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-25T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-03T13:56:32.307825+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-26T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-11T08:50:52.840459+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.989894+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-31T09:24:38.989894+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T11:29:09.79356+00:00',
        },
      ],
    },
    {
      arrival: '2019-02-28T04:00:00+00:00',
      departure: '2019-03-01T13:00:00+00:00',
      createdDate: '2018-12-21T00:14:09.929656+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-02-26T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:14:09.929656+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-27T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:14:09.929656+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-21T17:41:06.035306+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-22T00:47:37.347651+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-25T13:45:42.841303+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-25T13:45:42.841303+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T11:29:09.801399+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-02T04:00:00+00:00',
      departure: '2019-03-03T11:00:00+00:00',
      createdDate: '2018-12-21T00:14:10.874223+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'EGALY', name: 'Alexandria' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:14:10.874223+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:14:10.874223+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-21T17:41:06.711851+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-22T00:47:38.087197+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T12:02:53.722022+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-02T10:06:00+00:00',
      departure: '2019-03-02T22:24:00+00:00',
      createdDate: '2019-02-08T10:00:51.709267+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'TRISK', name: 'Iskenderun' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.709267+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.709267+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T09:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.041095+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T08:52:05.041095+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-11T12:51:16.663015+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-11T12:51:16.663015+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-02-28T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.687284+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-27T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.687284+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T00:15:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.703881+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.703881+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T08:57:05.677034+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T16:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.301552+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.301552+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.852765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.852765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.576088+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T23:59:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.576088+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T06:12:35.584224+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-01T08:09:36.03077+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T10:06:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-02T07:28:45.303887+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T22:24:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-02T20:01:43.537118+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-04T04:48:00+00:00',
      departure: '2019-03-04T21:48:00+00:00',
      createdDate: '2019-02-08T10:00:51.71341+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'TRMER', name: 'Mersin' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.71341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.71341+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-01T08:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.700594+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-02-28T08:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.700594+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.716489+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.716489+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-03T12:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.313679+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-02T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.313679+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-02T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.867394+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-01T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.867394+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.585206+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.585206+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T04:48:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T05:38:34.724865+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T21:48:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T22:38:49.536316+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-05T20:00:00+00:00',
      departure: '2019-03-07T02:00:00+00:00',
      createdDate: '2018-12-21T00:14:11.801325+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'EGDAM', name: 'Dumyat (Damietta)' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:14:11.801325+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:14:11.801325+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-21T17:41:07.379842+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-22T00:47:38.745368+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T12:02:53.733965+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-06T12:36:00+00:00',
      departure: '2019-03-07T06:00:00+00:00',
      createdDate: '2018-12-21T00:14:15.236075+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'TRALI', name: 'Aliaga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-13T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:14:15.236075+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-14T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:14:15.236075+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-21T17:41:10.255088+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-22T00:47:41.282045+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.717731+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.717731+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-04T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.718664+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-03T07:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.718664+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.728941+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.728941+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.319886+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.319886+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-05T01:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.876324+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-04T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.876324+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.595624+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T00:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.595624+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-06T12:36:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-06T09:59:20.512537+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T03:35:21.806461+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-07T21:00:00+00:00',
      departure: '2019-03-08T23:00:00+00:00',
      createdDate: '2018-12-21T00:14:12.703722+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'LBBEY', name: 'Beirut' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T23:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:14:12.703722+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:14:12.703722+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-21T17:41:08.03194+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-22T00:47:39.403595+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T12:02:53.754308+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-09T15:00:00+00:00',
      departure: '2019-03-10T06:00:00+00:00',
      createdDate: '2018-12-21T00:14:13.615819+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRISK', name: 'Iskenderun' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:14:13.615819+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:14:13.615819+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-21T17:41:08.742447+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-22T00:47:40.034941+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T12:02:55.73186+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-09T17:18:00+00:00',
      departure: '2019-03-10T05:00:00+00:00',
      createdDate: '2019-01-21T00:15:04.415607+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-15T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.415607+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-16T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.415607+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.72408+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-08T10:00:51.72408+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.735385+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-22T13:27:55.735385+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.743514+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T08:21:10.743514+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-07T18:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.326357+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T00:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T11:25:17.326357+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-06T17:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.885802+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-05T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T15:05:15.885802+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-09T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.604489+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-08T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-28T08:08:53.604489+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-07T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T10:58:12.72264+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-08T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T10:58:12.72264+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-07T14:41:23.440932+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-10T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-07T14:41:23.440932+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-09T17:18:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-09T18:23:14.146841+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-10T11:00:00+00:00',
      departure: '2019-03-11T11:00:00+00:00',
      createdDate: '2018-12-21T00:14:14.29139+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRMER', name: 'Mersin' },
      logEntries: [
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-11T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2018-12-21T00:14:14.29139+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-10T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2018-12-21T00:14:14.29139+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2018-12-21T17:41:09.525633+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2018-12-22T00:47:40.673325+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-07T12:02:55.753873+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-18T06:24:00+00:00',
      departure: '2019-03-19T13:18:00+00:00',
      createdDate: '2019-02-04T00:07:51.574834+00:00',
      isOmitted: false,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-28T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.574834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-29T04:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.574834+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-27T12:02:53.327139+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-27T12:02:53.327139+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-28T09:25:20.208715+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: false,
          createdDate: '2019-03-04T13:25:55.687453+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T13:25:55.687453+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T16:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T13:25:55.687453+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-18T06:24:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-18T07:02:41.126503+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-18T15:19:45.32021+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T13:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T09:23:12.941589+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-19T13:18:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-19T13:39:58.386238+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-19T05:00:00+00:00',
      departure: '2019-03-20T03:00:00+00:00',
      createdDate: '2019-01-21T00:15:04.420426+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITGOA', name: 'Genova' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-19T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.420426+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-20T03:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.420426+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-08T10:00:51.729923+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-20T22:00:00+00:00',
      departure: '2019-03-21T22:00:00+00:00',
      createdDate: '2019-01-21T00:15:04.425024+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITLIV', name: 'Livorno' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-20T22:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.425024+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-21T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.425024+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-08T10:00:51.734636+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-22T12:00:00+00:00',
      departure: '2019-03-22T22:00:00+00:00',
      createdDate: '2019-01-21T00:15:04.429612+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITCVV', name: 'Civitavecchia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-22T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.429612+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-22T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.429612+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-08T10:00:51.739316+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-23T13:00:00+00:00',
      departure: '2019-03-24T11:00:00+00:00',
      createdDate: '2019-01-21T00:15:04.433769+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITSAL', name: 'Salerno' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-23T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.433769+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-24T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.433769+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-08T10:00:51.745124+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-25T06:00:00+00:00',
      departure: '2019-03-26T10:00:00+00:00',
      createdDate: '2019-01-21T00:15:04.437941+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITCAG', name: 'Cagliari' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-25T06:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.437941+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-26T10:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-01-21T00:15:04.437941+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T12:02:50.484821+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-30T04:00:00+00:00',
      departure: '2019-03-31T11:00:00+00:00',
      createdDate: '2019-02-04T00:07:51.583109+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'EGALY', name: 'Alexandria' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-03-30T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.583109+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-03-31T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.583109+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T12:02:53.36241+00:00',
        },
      ],
    },
    {
      arrival: '2019-03-31T12:00:00+00:00',
      departure: '2019-04-01T20:00:00+00:00',
      createdDate: '2019-02-07T11:29:09.810176+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'GRPIR', name: 'Piraeus' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-12T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.810176+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-13T05:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.810176+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-04-01T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-04T13:25:55.830241+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-02T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-04T13:25:55.830241+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-26T13:33:25.178149+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T15:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-26T13:33:25.178149+00:00',
        },
        {
          updatedField: 'arrival',
          arrival: '2019-03-31T12:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-03-29T09:29:49.647305+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-01T20:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-03-29T09:29:49.647305+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-01T00:07:12.898006+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-02T20:00:00+00:00',
      departure: '2019-04-04T02:00:00+00:00',
      createdDate: '2019-02-04T00:07:51.590409+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'EGDAM', name: 'Dumyat (Damietta)' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-02T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.590409+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-04T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.590409+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T12:02:53.388763+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-04T20:00:00+00:00',
      departure: '2019-04-05T22:00:00+00:00',
      createdDate: '2019-02-04T00:07:51.597714+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'LBBEY', name: 'Beirut' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-04T20:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.597714+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-05T22:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.597714+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-02-27T12:02:53.40695+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-06T15:00:00+00:00',
      departure: '2019-04-07T06:00:00+00:00',
      createdDate: '2019-02-04T00:07:51.603978+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRISK', name: 'Iskenderun' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-06T15:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.603978+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-07T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.603978+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-04T13:25:55.767334+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-07T11:00:00+00:00',
      departure: '2019-04-08T11:00:00+00:00',
      createdDate: '2019-02-04T00:07:51.608765+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRMER', name: 'Mersin' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-07T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.608765+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-08T11:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.608765+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-04T13:25:55.789554+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-10T10:00:00+00:00',
      departure: '2019-04-11T06:00:00+00:00',
      createdDate: '2019-02-04T00:07:51.614131+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'TRALI', name: 'Aliaga' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-10T10:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.614131+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-11T06:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-04T00:07:51.614131+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-04T13:25:55.81849+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-16T04:00:00+00:00',
      departure: '2019-04-17T02:00:00+00:00',
      createdDate: '2019-02-07T11:29:09.8162+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITGOA', name: 'Genova' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-16T04:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.8162+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-17T02:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.8162+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-03-04T13:25:55.843933+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-17T21:00:00+00:00',
      departure: '2019-04-18T21:00:00+00:00',
      createdDate: '2019-02-07T11:29:09.821912+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITLIV', name: 'Livorno' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-17T21:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.821912+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-18T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.821912+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-01T00:07:12.898006+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-19T11:00:00+00:00',
      departure: '2019-04-19T21:00:00+00:00',
      createdDate: '2019-02-07T11:29:09.828297+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITCVV', name: 'Civitavecchia' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-19T11:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.828297+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-19T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.828297+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-01T00:07:12.898006+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-20T13:00:00+00:00',
      departure: '2019-04-21T07:00:00+00:00',
      createdDate: '2019-02-07T11:29:09.833602+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITSAL', name: 'Salerno' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-20T13:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.833602+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-21T07:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.833602+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-01T00:07:12.898006+00:00',
        },
      ],
    },
    {
      arrival: '2019-04-22T05:00:00+00:00',
      departure: '2019-04-22T21:00:00+00:00',
      createdDate: '2019-02-07T11:29:09.838903+00:00',
      isOmitted: true,
      service: 'Levante Express',
      port: { id: 'ITCAG', name: 'Cagliari' },
      logEntries: [
        {
          updatedField: 'arrival',
          arrival: '2019-04-22T05:00:00+00:00',
          departure: null,
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.838903+00:00',
        },
        {
          updatedField: 'departure',
          arrival: null,
          departure: '2019-04-22T21:00:00+00:00',
          isOmitted: null,
          createdDate: '2019-02-07T11:29:09.838903+00:00',
        },
        {
          updatedField: 'isOmitted',
          arrival: null,
          departure: null,
          isOmitted: true,
          createdDate: '2019-04-01T00:07:12.898006+00:00',
        },
      ],
    },
  ],
}
export default vessel
