type FunctionType = (...args: any[]) => any
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType }
export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>

export interface Action<T extends string> {
  type: T
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P
}

export enum PortsTypes {
  UPDATE = 'store.ports.update',
}

export enum VesselsTypes {
  UPDATE = 'store.vessels.update',
  UPDATE_LIST = 'store.vessels.update_list',
}
